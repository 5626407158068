import { ToolbarFilter } from '@/ui'
import i18next from 'i18next'
import { FilterType } from '../../../../api/dashboard'

import { useTranslation } from 'react-i18next'

export function DateFilterButton(props: {
  value: string | null
  showNoFilter?: boolean
  onChange: (value: string) => void
}) {
  const { t } = useTranslation()

  const items = [
    { label: i18next.t('filter.12hours'), value: '12hours' },
    { label: i18next.t('filter.24hours'), value: '24hours' },
    { label: i18next.t('filter.today'), value: FilterType.Today },
    { label: i18next.t('filter.yesterday'), value: FilterType.Yesterday },
    { label: i18next.t('filter.7days'), value: '7' },
    { label: i18next.t('filter.14days'), value: '14' },
    { label: i18next.t('filter.30days'), value: '30' },
  ]

  if (props.showNoFilter) {
    items.push({ label: t('filter.all'), value: '-1' })
  }

  return (
    <ToolbarFilter
      label={t('filter.date.label')}
      items={items}
      value={props.value}
      onChange={v => props.onChange(v as string)}
    />
  )
}
