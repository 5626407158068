import { useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { LastDateFilter, OperatorType, SelectorValueType, TokenValue } from './types'
import i18next from 'i18next'
import AttrSelector from './AttrSelector'
import OperatorSelector from './OperatorSelector'
import SettingsHeader from '../SettingsHeader'
import TextIconButton from '../../../components/buttons/TextIconButton'
import { AttrType, ConditionNode, Node, Token, TokenType } from '@/api/dashboard'
import { CampaignContext, ICampaignContext } from '../Context'
import { isMoneyAttribute } from '../blocks/common'
import { BlockError } from '../types'
import { ButtonIcon, FieldValidationMessage, Input, Select } from '@/ui'
import styled from '@emotion/styled'
import { getAttrType } from './getAttrType'
import { AttrValueEditor } from './AttrValueEditor'
import { findAttr } from './findAttr'
import { validateConditionTokens } from '../validation'
import { getTokenGroups } from '../util'
import { CloseOutline, PlusContained } from '@/icons'

const TokenGroupRow = styled.div`
  display: flex;
  height: 48px;
  align-items: flex-start;
  justify-content: flex-start;
`

export default function ConditionEditor(props: {
  node: ConditionNode
  error?: BlockError | null
  setNode: (node: ConditionNode) => void
  onClose?: () => void
  className?: string
  mode?: 'save' | 'onchange'
  errTokens?: Token[]
  errMessage?: string
}) {
  const theme = useTheme()
  const context = useContext(CampaignContext) as ICampaignContext

  const [tokenGroups, setTokenGroups] = useState<Token[][]>([])
  const [errorTokens, setErrorTokens] = useState<Token[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    setTokenGroups(getTokenGroups(props.node.expr.tokens || []))
  }, [props.node.id])

  useEffect(() => {
    const newTokens = tokenGroups.flat()

    if (props.mode == 'onchange') {
      props.setNode({
        ...props.node,
        expr: {
          ...props.node.expr,
          tokens: newTokens,
        },
      })
    }
  }, [tokenGroups])

  useEffect(() => {
    if (props.errTokens?.length) {
      setErrorTokens(props.errTokens)
      setErrorMessage(props.errMessage || '')
    }
  }, [props.errTokens])

  const onSaveClick = () => {
    let { error, errorTokens: errTokens } = validateConditionTokens(tokenGroups, context)

    setErrorTokens(errTokens || [])

    setErrorMessage(error)
    if (error) {
      return
    }
    props.setNode({
      ...props.node,
      expr: {
        ...props.node.expr,
        tokens: tokenGroups.flat(),
      },
    })
    onClose()
  }

  const onClose = () => {
    props.onClose?.()
  }

  const attrTypeToToken = (attrType: AttrType | undefined): TokenType => {
    switch (attrType) {
      case AttrType.String:
        return TokenType.String
      case AttrType.Number:
        return TokenType.Number
      case AttrType.Boolean:
        return TokenType.Boolean
      case AttrType.Date:
        return TokenType.Date
      default:
        return TokenType.String
    }
  }

  const onTokenValueChanged = (groupIndex: number, tokenIndex: number, value: TokenValue, type?: TokenType) => {
    const newTokenGroups = [...tokenGroups]
    const currentToken = newTokenGroups[groupIndex][tokenIndex]
    currentToken.value = value
    if (type) {
      currentToken.type = type
    }
    setTokenGroups(newTokenGroups)
  }

  const onAttrTokenValueChanged = (
    groupIndex: number,
    tokenIndex: number,
    value: TokenValue,
    extAttrType: TokenType,
  ) => {
    let newTokenGroups = [...tokenGroups]
    let currentToken = newTokenGroups[groupIndex][tokenIndex]
    let group = newTokenGroups[groupIndex]
    let attr = group[tokenIndex]

    let newAttrType = getAttrType(
      {
        value: value,
        type: extAttrType,
      },
      context,
    )

    if (!attr?.value || getAttrType(attr, context) != newAttrType) {
      group[1].value = ''
      group[2].value = undefined
      group[2].type = attrTypeToToken(newAttrType)
    }
    currentToken.type = extAttrType
    currentToken.value = value
    setTokenGroups(newTokenGroups)
  }

  const renderValueEditor = (groupIndex: number, leftOperand: Token, operator: Token, rightOperand: Token) => {
    let attrType = getAttrType(leftOperand, context)

    if (!attrType) {
      return <Input value={''} disabled style={{ width: '100%' }} />
    }

    if (operator.value == OperatorType.is_none || operator.value == OperatorType.is_not_none) {
      return <></>
    }

    let attr = findAttr(leftOperand.value) || context.playerCustomAttributes?.find(it => it.name == leftOperand.value)

    return (
      <AttrValueEditor
        operator={operator.value as OperatorType}
        attr={attr!}
        isMoney={isMoneyAttribute(leftOperand.value)}
        onChange={v => onTokenValueChanged(groupIndex, 2, v as string)}
        value={rightOperand.value}
      />
    )
  }

  const renderTokenGroupRow = (groupIndex: number, tokens: Token[]) => {
    if (tokens.length == 1) {
      return (
        <TokenGroupRow key={groupIndex}>
          <Select
            style={{ width: '80px' }}
            value={tokens[0].value}
            onChange={v => onTokenValueChanged(groupIndex, 0, v as TokenValue)}
            options={[
              { value: OperatorType.or, children: i18next.t('campaign.operator.or') },
              { value: OperatorType.and, children: i18next.t('campaign.operator.and') },
            ]}
          />
        </TokenGroupRow>
      )
    }

    let s1 = { maxWidth: '70%' }
    let s2 = { maxWidth: '30%' }
    let s3 = { maxWidth: '40%' }
    let type = getAttrType(tokens[0], context)
    if (type == AttrType.Number || type == AttrType.Boolean) {
      s1 = { maxWidth: '50%' }
      s2 = { maxWidth: '30%' }
      s3 = { maxWidth: '30%' }
    }

    if (tokens[1].value == OperatorType.is_not_none || tokens[1].value == OperatorType.is_none) {
      s1 = { maxWidth: '70%' }
      // @ts-ignore
      s2 = { maxWidth: '30%', width: '30%' }
    }

    if (tokens[1].value == OperatorType.in_range || tokens[1].value == OperatorType.not_in_range) {
      // @ts-ignore
      s3 = { maxWidth: '40%', width: '40%' }
    }

    let attr = findAttr(tokens[0].value)

    if (
      attr?.selectorValueType == SelectorValueType.sku_no_price ||
      attr?.selectorValueType == SelectorValueType.sku ||
      attr?.selectorValueType == SelectorValueType.segment ||
      attr?.selectorValueType == SelectorValueType.country
    ) {
      // @ts-ignore
      s3 = { maxWidth: '50%', width: '50%' }
    }

    return (
      <div className="flex h-[48px] w-full items-start justify-start" key={groupIndex}>
        <div className="flex gap-2" style={{ maxWidth: 'calc(100% - 32px)', flexGrow: 1 }}>
          <div style={s1}>
            <AttrSelector
              token={tokens[0]}
              error={errorTokens.includes(tokens[0])}
              onChange={(v, attrType) => {
                onAttrTokenValueChanged(groupIndex, 0, v, attrType)
                if (!tokens[1].value) {
                  onTokenValueChanged(groupIndex, 1, OperatorType['=='])
                }

                let newAttrType = getAttrType(
                  {
                    value: v,
                    type: attrType,
                  },
                  context,
                )

                switch (newAttrType) {
                  case AttrType.Number:
                    if (tokens[2].value == undefined) {
                      onTokenValueChanged(groupIndex, 2, 0)
                    }
                    break
                  case AttrType.Boolean:
                    onTokenValueChanged(groupIndex, 2, true)
                    break
                  case AttrType.Date:
                    onTokenValueChanged(groupIndex, 1, OperatorType['>'])
                    onTokenValueChanged(groupIndex, 2, LastDateFilter['1.day.ago'])
                    break
                }
              }}
            />
          </div>

          <div style={s2}>
            <OperatorSelector
              value={tokens[1].value}
              error={errorTokens.includes(tokens[1])}
              attrToken={tokens[0]}
              onChange={v => {
                let prevValue = tokens[1].value
                onTokenValueChanged(groupIndex, 1, v)

                switch (v) {
                  case OperatorType.is_none:
                  case OperatorType.is_not_none:
                    onTokenValueChanged(groupIndex, 2, undefined)
                    break
                  case OperatorType.in_range:
                  case OperatorType.not_in_range:
                    if (prevValue != OperatorType.in_range && prevValue != OperatorType.not_in_range) {
                      onTokenValueChanged(groupIndex, 2, [0, 10], TokenType.List)
                    }
                    break
                  case OperatorType.in_list:
                  case OperatorType.not_in_list:
                    if (prevValue != OperatorType.in_list && prevValue != OperatorType.not_in_list) {
                      onTokenValueChanged(groupIndex, 2, [], TokenType.List)
                    }
                    break
                  default:
                    if (
                      prevValue == OperatorType.in_range ||
                      prevValue == OperatorType.not_in_range ||
                      prevValue == OperatorType.in_list ||
                      prevValue == OperatorType.not_in_list
                    ) {
                      let attr = findAttr(tokens[0].value)
                      onTokenValueChanged(
                        groupIndex,
                        2,
                        attr?.type == AttrType.Number ? 0 : '',
                        attrTypeToToken(attr?.type),
                      )
                    }
                    break
                }
              }}
            />
          </div>

          <div style={s3}>{renderValueEditor(groupIndex, tokens[0], tokens[1], tokens[2])}</div>
        </div>

        <div className="ml-auto flex h-full items-center justify-center">
          <ButtonIcon variant="secondary-gray" size="sm" onClick={() => onGroupRemoveClick(groupIndex)}>
            <CloseOutline size={16} />
          </ButtonIcon>
        </div>
      </div>
    )
  }

  const onGroupRemoveClick = (groupIndex: number) => {
    const newTokenGroups = [...tokenGroups]

    if (errorTokens.length) {
      newTokenGroups[groupIndex].forEach(it => {
        errorTokens.splice(errorTokens.indexOf(it), 1)
      })
    }

    if (groupIndex > 0 && groupIndex == newTokenGroups.length - 1) {
      newTokenGroups.pop() //remove condition
      newTokenGroups.pop() //remove and/or
    } else if (groupIndex == 0) {
      newTokenGroups.shift() //remove condition
      if (newTokenGroups.length > 0) {
        newTokenGroups.shift()
      } //remove and/or
    } else {
      newTokenGroups.splice(groupIndex, 2)
    }

    setTokenGroups(newTokenGroups)
  }

  const addConditionClick = () => {
    const newTokenGroups = [...tokenGroups]

    if (tokenGroups.length > 0) {
      newTokenGroups.push([{ type: TokenType.Operator, value: OperatorType.and } as Token])
    }

    newTokenGroups.push([
      { type: TokenType.Attribute, value: '' } as Token,
      { type: TokenType.Operator, value: '' } as Token,
      { type: TokenType.Number, value: '' } as Token,
    ])

    setTokenGroups(newTokenGroups)
    setErrorMessage('')
  }

  return (
    <div>
      {props.onClose && <SettingsHeader node={props.node as Node} onSave={onSaveClick} onClose={onClose} />}

      <div className={props.className}>
        <div className="flex flex-col gap-1 rounded-md border border-border-primary-hover p-4">
          {tokenGroups.map((group, i) => renderTokenGroupRow(i, group))}

          <TextIconButton
            onClick={addConditionClick}
            style={{
              marginTop: tokenGroups.length ? theme.spacing(1.5) : 0,
            }}
            text={i18next.t('campaign.condition.add')}
          >
            <PlusContained />
          </TextIconButton>
        </div>
        {errorMessage && <FieldValidationMessage> {errorMessage} </FieldValidationMessage>}
      </div>
    </div>
  )
}
