import { useContext, useState } from 'react'
import { Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import LeaderBoardDialog from './LeaderBoardDialog'
import i18next from 'i18next'
import { canEdit } from '../../security'
import { LeaderboardRead } from '../../api/dashboard'
import {
  Button,
  ConfirmDialog,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableZeroState,
  usePagination,
} from '@/ui'
import { useLeaderboardQuery } from './api'
import { EditIcon } from '../../icons/Icons'
import { Plus, Trash } from '@/icons'
import { useLeaderboardDeleteMutate } from './api/useLeaderboardDeleteMutate'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { AuthContext, IAuthContext } from '@/Context'

export default function LeaderBoardTable() {
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)
  const [editItem, setEditItem] = useState<LeaderboardRead | null>(null)
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const { page, query, onPageChange } = usePagination(20)
  const { data: items = [], isLoading } = useLeaderboardQuery(companyId, gameId, query)
  const { mutateAsync: deleteMutateAsync } = useLeaderboardDeleteMutate(companyId, gameId)
  const [confirmDeleteItem, setConfirmDeleteItem] = useState<LeaderboardRead | null>(null)

  const onDeleteGameItemClick = async () => {
    if (confirmDeleteItem) {
      setConfirmDeleteItem(null)
      await deleteMutateAsync({ id: confirmDeleteItem.id })
    }
  }

  return (
    <div className="flex h-full flex-col">
      {editItem && (
        <LeaderBoardDialog
          item={editItem}
          onClose={() => {
            setEditItem(null)
          }}
        />
      )}

      <PageHeader
        extra={
          canUserEdit &&
          !!items.length && (
            <Button variant="primary" type="button" onClick={() => setEditItem({} as LeaderboardRead)}>
              {i18next.t('leaderboard.add-button')}
            </Button>
          )
        }
      >
        {i18next.t('sidebar.leaderboard')}
      </PageHeader>

      {confirmDeleteItem && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          subMessage={i18next.t('confirm.text', { name: confirmDeleteItem.name })}
          onCancel={() => setConfirmDeleteItem(null)}
          onConfirm={onDeleteGameItemClick}
        />
      )}

      {isLoading ? (
        new Array(query.limit).fill('').map((_, index) => (
          <TableRow key={index}>
            <TableCell width="200%">
              <Skeleton variant="rounded" width="100%" height={24} style={{ margin: '7px 0' }} />
            </TableCell>
            <TableCell width="100%">
              <Skeleton variant="rounded" width="100%" height={24} />
            </TableCell>
            <TableCell width="50%">
              <Skeleton variant="rounded" width="100%" height={24} />
            </TableCell>
            <TableCell width="10%">
              <Skeleton variant="rounded" width="100%" height={24} />
            </TableCell>
          </TableRow>
        ))
      ) : items.length === 0 ? (
        <TableZeroState
          title={i18next.t('leaderboard.zero.title')}
          message={i18next.t('leaderboard.zero.message')}
          buttons={
            <>
              {canUserEdit && (
                <Button variant="primary" onClick={() => setEditItem({} as LeaderboardRead)}>
                  <Plus size={14} />
                  <span>{i18next.t('leaderboard.zero.button-add')}</span>
                </Button>
              )}
              <a href="https://docs.aghanim.com/advanced/leaderboards/overview" target="_blank">
                <Button variant="tertiary-gray">{i18next.t('leaderboard.zero.learn-more')}</Button>
              </a>
            </>
          }
        />
      ) : (
        <>
          <Table>
            <TableRow variant="header">
              <TableCell width="200%">{i18next.t('leaderboard.name')}</TableCell>
              <TableCell width="100%">{i18next.t('leaderboard.description')}</TableCell>
              <TableCell width="50%">{i18next.t('leaderboard.ranked_at')}</TableCell>
              <TableCell width="10%">
                <div style={{ width: '33px' }} />
              </TableCell>
            </TableRow>

            {items.map((it, idx) => (
              <TableRow key={idx}>
                <TableCell width="200%">{it.name}</TableCell>
                <TableCell width="100%">{it.description}</TableCell>
                <TableCell width="50%">
                  <DateTimeValue value={it.ranked_at} />
                </TableCell>
                <TableCell width="10%">
                  <div className="text-right">
                    {canUserEdit && (
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              setEditItem(it)
                              break
                            case 'remove':
                              setConfirmDeleteItem(it)
                              break
                          }
                        }}
                        options={[
                          {
                            icon: <EditIcon />,
                            children: i18next.t('grid.edit'),
                            value: 'edit',
                          },
                          {
                            icon: <Trash className="text-text-error-primary" />,
                            children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                            value: 'remove',
                          },
                        ]}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Table>

          {!isLoading && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
