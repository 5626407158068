import i18next from 'i18next'
import { Slider, styled } from '@mui/material'
import { Node, SplitNode } from '../../../api/dashboard'
import { BlockError, EventActionNodeType } from '../types'
import { FieldGroup, Input } from '@/ui'
import { Settings } from '../../../components/ui/Settings'
import { Textarea } from '../../../components/ui/Textarea'
import SettingsHeader from '../SettingsHeader'
import { useState } from 'react'

const PrettoSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.purple.A100,
  height: 8,
  opacity: 1,
  '& .MuiSlider-track': {
    border: 'none',
    color: theme.palette.primary.yellow,
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: `2px solid ${theme.palette.primary.main}`,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}))

export const SplitNodeEditor = (props: {
  error?: BlockError | null
  node: SplitNode
  setNode: (node: Node) => void
  onClose: () => void
}) => {
  const [node, setNode] = useState<SplitNode>({
    ...props.node,
  })

  const onChange = (index: number, value: number) => {
    if (value >= 100 || value <= 0) {
      return
    }
    let newDistribution = [...node.distribution]
    if (index == 0) {
      newDistribution[0].value = value
      newDistribution[1].value = 100
    } else if (index == 1) {
      newDistribution[0].value = 100 - value
      newDistribution[1].value = 100
    }

    setNode({
      ...node,
      distribution: newDistribution,
      model_type: EventActionNodeType.SplitNode,
    })
  }

  const onSaveClick = () => {
    props.setNode(node as Node)
    props.onClose()
  }

  const onSliderChange = (_: Event, newValue: number | number[]) => {
    onChange(0, newValue as number)
  }

  return (
    <div>
      <SettingsHeader node={node as Node} onSave={onSaveClick} onClose={props.onClose} />
      <div className="p-6">
        <Settings text={i18next.t('coupon.general')}>
          <PrettoSlider
            onChange={onSliderChange}
            sx={{ width: '320px', zIndex: 10 }}
            valueLabelDisplay="auto"
            defaultValue={50}
            value={node.distribution[0].value}
          />
          <FieldGroup label={i18next.t('campaign.block.SplitNode.left_percent')}>
            <Input
              value={node.distribution[0].value}
              type={'number'}
              onChange={e => {
                let v = parseInt(e.target.value)
                onChange(0, v)
              }}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('campaign.block.SplitNode.right_percent')}>
            <Input
              value={100 - node.distribution[0].value}
              type={'number'}
              onChange={e => {
                let v = parseInt(e.target.value)
                onChange(1, v)
              }}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('campaign.block.SplitNode.description')}>
            <Textarea
              value={node.description}
              onChange={e => {
                setNode({
                  ...node,
                  description: e.target.value,
                  model_type: EventActionNodeType.SplitNode,
                })
              }}
            />
          </FieldGroup>
        </Settings>
      </div>
    </div>
  )
}
