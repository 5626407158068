import i18next from 'i18next'

import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import {
  PageRead,
  SectionObjectType,
  SectionType,
  SectionType as SectionTypeGen,
  WebsiteSection,
} from '../../api/dashboard'
import { Dialog, InputLabel, Typography } from '@mui/material'
import { Button, Select, SelectIcon } from '@/ui'
import DialogHeader from '../../components/ui/Dialog/DialogHeader'

const MENU_ICONS_URL = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/hub/menu-icons`

const AVAILABLE_ICONS = [
  'home',
  'about',
  'achievement',
  'chat',
  'page',
  'events',
  'forum',
  'leaderboard',
  'merch',
  'mini_games',
  'news',
  'store',
  'updates',
  'watch',
  'play',
  'redeem_code',
]

const ChangeSectionDialog = (props: {
  available: PageRead[]
  section?: WebsiteSection
  onClose: (section?: WebsiteSection) => void
}) => {
  const theme = useTheme()

  const [section, setSection] = useState({
    visible: props.section?.visible || true,
    name: props.section?.name || (SectionTypeGen.Page as unknown as SectionType),
    object_type: props.section?.object_type || SectionObjectType.Page,
    object_id: props.section?.object_id,
    icon: props.section?.icon || 'page',
  } as WebsiteSection)

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="sm"
      sx={{ '& .MuiDialog-paper': { padding: '40px', borderRadius: '24px', margin: 0 } }}
      onClose={() => props.onClose()}
    >
      <DialogHeader
        title={
          props.section
            ? i18next.t('hub-settings.sections.change-dialog.title')
            : i18next.t('hub-settings.sections.add-dialog.title')
        }
      />

      <div className="mt-10 flex w-full gap-3">
        {props.available.length || props.section ? (
          <div className="flex w-full items-start gap-3">
            <div className="shrink-0">
              <InputLabel sx={{ mb: theme.spacing(0.5) }}>
                {i18next.t('hub-settings.sections.add-dialog.icon')}
              </InputLabel>
              <SelectIcon
                options={AVAILABLE_ICONS.map(p => ({
                  icon: () => <img src={`${MENU_ICONS_URL}/${p}.svg`} />,
                  value: p,
                }))}
                value={section.icon}
                onChange={v =>
                  setSection({
                    ...section,
                    icon: v as string,
                  })
                }
              />
            </div>

            <div className="w-full">
              <InputLabel sx={{ mb: theme.spacing(0.5) }}>
                {i18next.t('game-item-page.choose-page-or-category')}
              </InputLabel>
              <Select
                value={section.object_id}
                options={props.available.map(p => ({ children: p.title, value: p.id }))}
                onChange={v =>
                  setSection({
                    ...section,
                    object_id: v as string,
                  })
                }
              />
            </div>
          </div>
        ) : (
          <Typography>{i18next.t('hub-settings.sections.add-dialog.no-sections')}</Typography>
        )}
      </div>

      <div className="mt-10 flex justify-end gap-4">
        <Button variant="outline" size="lg" onClick={() => props.onClose()}>
          {i18next.t('hub-settings.sections.add-dialog.cancel')}
        </Button>
        <Button variant="primary" size="lg" disabled={!section.object_id} onClick={() => props.onClose(section)}>
          {props.section ? i18next.t('Save') : i18next.t('Add')}
        </Button>
      </div>
    </Dialog>
  )
}

export default ChangeSectionDialog
