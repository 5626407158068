import { cn } from '../../../libs/cn'

export type PayMethodViewVariant = 'default' | 'two-lines'

export default function PayMethodView({
  paymentMethodName,
  logoUrl,
  className,
  card_last_4_digits,
  variant = 'default',
}: {
  paymentMethodName: string | undefined
  logoUrl?: string
  className?: string
  card_last_4_digits?: string
  variant?: PayMethodViewVariant
}) {
  const default_image_url = `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/placeholder.png`

  const renderImg = () => {
    return (
      <img
        src={logoUrl || default_image_url}
        alt={paymentMethodName}
        onError={e => {
          ;(e.target as HTMLImageElement).src = default_image_url || ''
        }}
        className="h-[22px]"
      />
    )
  }

  if (variant == 'two-lines') {
    return (
      <div className={className} data-testid={`pay-method-${paymentMethodName}`}>
        <div className="flex items-center gap-3">
          {renderImg()}
          <div>
            {card_last_4_digits && <div>{card_last_4_digits}</div>}
            <div>{paymentMethodName}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={cn('flex items-center gap-3', className)} data-testid={`pay-method-${paymentMethodName}`}>
      {renderImg()}
      {card_last_4_digits && <span>{card_last_4_digits}</span>}
      <span>{paymentMethodName}</span>
    </div>
  )
}
