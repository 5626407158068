import { PaymentRead } from '../../api/dashboard'
import PayMethodView, { PayMethodViewVariant } from '../payment-settings/components/PayMethodView'
import { usePaymentMethods } from '../../api/usePaymentMethods'
import { useParams } from 'react-router-dom'
import { REWARD_POINTS_PAYMENT_METHOD_ID } from '../../pay-types'
import { cn } from '../../libs/cn'

export default function PayCardView({ payment, variant }: { payment: PaymentRead; variant?: PayMethodViewVariant }) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data } = usePaymentMethods(companyId, gameId)

  const paymentMethod = data?.find(it => it.id === payment.payment_method_id)

  if (payment.payment_method_id == REWARD_POINTS_PAYMENT_METHOD_ID) {
    return (
      <div className={cn('flex items-center gap-3')} data-testid={'pay-method-rp'}>
        <img src={'/icons/aghanim-reward-points.svg'} alt={'Reward Points'} className="h-[22px]" />
        Reward Points
      </div>
    )
  }

  return (
    <PayMethodView
      variant={variant}
      paymentMethodName={paymentMethod?.caption || ''}
      card_last_4_digits={payment.card_last_4_digits}
      logoUrl={paymentMethod?.logo_url}
    />
  )
}
