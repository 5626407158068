import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Locale, LocaleRead } from '@/api/dashboard'
import { useCompanyEventsSocket, useGenerateCompanyPath } from '@/libs'
import { WSEventType } from '@/types'
import { localeQueryOptions, localesQueryOptions } from '../api'

export interface LocaleTranslateMessageProgress {
  type: WSEventType.translation_progress
  payload: {
    locale_id: string
    total: number
    done: number
  }
}

export interface LocaleTranslateMessageComplete {
  type: WSEventType.translation_complete
  payload: void
}

export interface LocaleTranslateMessageSyncComplete {
  type: WSEventType.translation_sync_complete
  payload: {
    locale: Locale
  }
}

export interface LocaleTranslateMessageStatusChange {
  type: WSEventType.locale_status_change
  payload: {
    locale: Locale
  }
}

export type LocaleTranslateAllMessage =
  | LocaleTranslateMessageProgress
  | LocaleTranslateMessageComplete
  | LocaleTranslateMessageSyncComplete
  | LocaleTranslateMessageStatusChange

const updateLocaleStatsFn = (locale: LocaleRead, stats: { total: number; done: number }) => {
  return { ...locale, stats: { ...locale.stats, ...stats } }
}

export const useLocaleTranslateAllListener = () => {
  const ql = useQueryClient()
  const { companyId, gameId } = useGenerateCompanyPath()

  const handleTranslationProgress = useCallback(
    (message: LocaleTranslateMessageProgress) => {
      ql.setQueryData<LocaleRead[]>(localesQueryOptions(companyId, gameId).queryKey, (locales = []) => {
        return locales.map(locale => {
          return locale.id === message.payload.locale_id
            ? updateLocaleStatsFn(locale, {
                total: message.payload.total,
                done: message.payload.done,
              })
            : locale
        })
      })

      ql.setQueryData(localeQueryOptions(companyId, gameId, message.payload.locale_id).queryKey, locale =>
        locale ? updateLocaleStatsFn(locale, { total: message.payload.total, done: message.payload.done }) : locale,
      )
    },
    [ql, companyId, gameId],
  )

  const onMessage = useCallback(
    (message: LocaleTranslateAllMessage) => {
      switch (message.type) {
        case WSEventType.translation_progress:
          handleTranslationProgress(message)
          break
        case WSEventType.translation_complete:
        case WSEventType.translation_sync_complete:
        case WSEventType.locale_status_change:
          ql.refetchQueries({ queryKey: [gameId, 'locales'] })
          break
      }
    },
    [companyId, gameId, handleTranslationProgress],
  )

  useCompanyEventsSocket<LocaleTranslateAllMessage>({
    onMessage,
  })
}
