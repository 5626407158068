import { Settings } from '@/components/ui/Settings'
import i18next, { TFunction } from 'i18next'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import dayjs from 'dayjs'
import StyledDatePicker from '@/components/StyledDatePicker'
import DurationSelectorDHMS from '@/components/DurationSelectorDHMS'

export const formatDateTimeUTC = (value: number, addTime = false) => {
  let d = new Date(value * 1000)

  let ds = dayjs(d)
  ds = ds.add(d.getTimezoneOffset(), 'minute')

  if (addTime) {
    return ds.format('MM/DD/YYYY hh:mmA')
  }

  return ds.format('YYYY-MM-DD')
}

const getUtcStartDate = () => {
  return new Date(dayjs().startOf('day').format('YYYY-MM-DD'))
}

const getUTCSeconds = (value: string) => {
  let d = new Date(value)
  return d.getTime() / 1000
}

export const validateInterval = (start_at: number, end_at: number, t: TFunction) => {
  if (start_at >= end_at) {
    return t('validation.start_date')
  }

  if (start_at <= 0 || end_at <= 0) {
    return t('validation.invalid_date')
  }

  if (new Date(start_at * 1000).getFullYear() > 2037 || new Date(end_at * 1000).getFullYear() > 2037) {
    return t('validation.invalid_date')
  }
}

export const ItemTimelimit = ({
  start_at,
  end_at,
  duration,
  setValue,
  children,
  showDuration = true,
  useSettingsContainer = true,
}: {
  useSettingsContainer?: boolean
  showDuration?: boolean
  start_at?: number
  end_at?: number
  duration?: number
  children?: React.ReactNode
  setValue: (prop: 'duration' | 'start_at' | 'end_at', value?: number) => void
}) => {
  const renderEditor = () => {
    return (
      <>
        <CheckBoxContainer
          checked={start_at != undefined && end_at != undefined}
          onChange={v => {
            if (v.target.checked) {
              setValue('duration', null as unknown as undefined)
              setValue('start_at', getUtcStartDate().getTime() / 1000)
              setValue('end_at', getUtcStartDate().getTime() / 1000 + 86399 * 30)
            } else {
              setValue('start_at', null as unknown as undefined)
              setValue('end_at', null as unknown as undefined)
            }
          }}
          label={i18next.t('campaign.virtual-sku-editor.limit-by-dates')}
        >
          <div className="flex gap-4">
            {start_at && (
              <StyledDatePicker
                label={i18next.t('campaign.virtual-sku-editor.start-date')}
                value={formatDateTimeUTC(start_at)}
                onChange={(value: string) => setValue('start_at', getUTCSeconds(value))}
              />
            )}

            {end_at && (
              <StyledDatePicker
                label={i18next.t('campaign.virtual-sku-editor.end-date')}
                value={formatDateTimeUTC(end_at)}
                onChange={(value: string) => setValue('end_at', getUTCSeconds(value) + 86399)}
              />
            )}
          </div>
        </CheckBoxContainer>

        {showDuration && (
          <CheckBoxContainer
            className="mt-4"
            checked={duration != undefined}
            onChange={v => {
              setValue('duration', v.target.checked ? 3600 : undefined)
              if (v.target.checked) {
                setValue('start_at', null as unknown as undefined)
                setValue('end_at', null as unknown as undefined)
              }
            }}
            label={i18next.t('campaign.virtual-sku-editor.limit-by-timer')}
          >
            <DurationSelectorDHMS
              duration={duration || 0}
              size="small"
              onChange={v => {
                setValue('duration', v)
              }}
            />
          </CheckBoxContainer>
        )}
      </>
    )
  }

  if (useSettingsContainer) {
    return (
      <Settings text={i18next.t('campaign.settings.limit-limitations')}>
        {renderEditor()}
        {children}
      </Settings>
    )
  }

  return renderEditor()
}
