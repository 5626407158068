import { ReactNode, forwardRef } from 'react'
import { HTMLMotionProps, motion } from 'framer-motion'
import { cn } from '@/libs'

interface MenuProps extends HTMLMotionProps<'ul'> {
  children: ReactNode
}

export const Menu_v2 = forwardRef<HTMLUListElement, MenuProps>(function Menu({ children, ...rest }, ref) {
  return (
    <motion.ul
      {...rest}
      ref={ref}
      className={cn(
        'group/menu',
        'box-content max-h-[50vh] w-full min-w-44 max-w-96 rounded-xl border border-border-primary bg-fg-primary py-[calc(theme(spacing[2.5])-1px)] shadow-lg outline-none',
        'overflow-x-hidden',
        'overflow-y-auto',
        rest.className,
      )}
      role="listbox"
      onClick={e => e.stopPropagation()}
    >
      {children}
    </motion.ul>
  )
})
