import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ItemRead } from '@/api/dashboard'
import { Badge, Button, ButtonIcon, Checkbox, Input, Modal, ModalProps } from '@/ui'
import { Search, XClose } from '@/icons'
import { EngagementItemInline } from '@/layouts/engagement/component/index'

interface ItemsPickerProps extends ModalProps {
  title: string
  loading?: boolean
  limit?: number
  items: ItemRead[]
  picked?: ItemRead[]
  onClose?: () => void
  onSubmit?: (picked: ItemRead[]) => void
}

export const SelectSkuModal = ({
  title,
  loading = false,
  limit,
  items,
  picked: existed = [],
  onClose = () => {},
  onSubmit,
}: ItemsPickerProps) => {
  const { t } = useTranslation()
  const [selection, setSelection] = useState<ItemRead[]>(items)
  const [picked, setPicked] = useState<ItemRead[]>(existed || [])

  return (
    <Modal className="flex h-2/3 flex-col gap-3 p-0 px-6 pt-6" isLoading={loading} onClose={onClose}>
      <div className="inline-flex items-center justify-between">
        <div className="text-title-t4 text-text-secondary">{title}</div>
        <div className="inline-flex gap-3">
          {onSubmit && (
            <Button variant="primary" onClick={() => onSubmit(picked)}>
              {t('Save')}
            </Button>
          )}
          <ButtonIcon size="sm" variant="secondary-gray" onClick={onClose}>
            <XClose />
          </ButtonIcon>
        </div>
      </div>

      <div className="h-fit">
        <Input
          size="sm"
          extraLeft={<Search className="mr-1.5 size-3.5" />}
          placeholder={t('search')}
          onChange={({ target: { value: sub } }) =>
            setSelection(
              items.filter(it => {
                if (!sub) {
                  return true
                }
                sub = sub.toLowerCase()
                return it.name.toLowerCase().includes(sub) || it.sku.toLowerCase().includes(sub)
              }),
            )
          }
        />
      </div>

      <div className="flex flex-col gap-3 overflow-y-auto">
        {selection.map((selectedItem, index) => {
          const checked = !!picked.find(pickedItem => pickedItem.id === selectedItem.id)
          const disabled = !checked && !!(limit && picked.length >= limit)

          return (
            <div key={index} className="inline-flex items-center">
              <Checkbox
                size="sm"
                checked={checked}
                disabled={disabled}
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    const found: ItemRead | undefined = items.find(item => item.id === selectedItem.id)
                    found && setPicked([...picked, found])
                  } else {
                    const index = picked.findIndex(pickedItem => pickedItem.id === selectedItem.id)
                    setPicked([...picked.slice(0, index), ...picked.slice(index + 1)])
                  }
                }}
              />
              <EngagementItemInline
                {...selectedItem}
                state={
                  <Badge variant={selectedItem.archived_at ? 'gray-primary' : 'lime-secondary'}>
                    {selectedItem.archived_at
                      ? t('daily-rewards.reward.item.state.archived')
                      : t('daily-rewards.reward.item.state.active')}
                  </Badge>
                }
              />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
