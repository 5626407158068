import 'highlight.js/styles/idea.css'

import { useEffect, useState } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import Highlight from 'react-highlight'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import i18next from 'i18next'
import { SendStatus, WebhookLogRead } from '@/api/dashboard'
import { dashboardClient, getErrorText } from '@/api'
import {
  Badge,
  Button,
  Callout,
  ModalConfirm,
  Skeleton,
  Tab,
  Table,
  Tabs,
  ToastSeverity,
  useModal,
  useToast,
} from '@/ui'
import { AlertHexagon, Send03 } from '@/icons'
import { GAME_WEBHOOKS_DETAIL_PATH, cn } from '@/libs'
import { refetchWebhookLogQuery, webhooksQuery } from '@/layouts/webhooks'
import { WebhookLogRow } from '../components'
import { refetchWebhookStatQuery, refetchWebhookTotalStatQuery } from '@/layouts/webhooks/api/webhooksStatQuery'

interface WebhookLogsInspectorProps {
  items: WebhookLogRead[]
  className?: string
}

export const WebhookLogsInspector = ({ items, ...rest }: WebhookLogsInspectorProps) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: webhooks = [] } = useQuery(webhooksQuery(companyId, gameId))
  const [selectedItem, setSelectedItem] = useState<WebhookLogRead | null>(null)
  const [activeTab, setActiveTab] = useState<'request' | 'response'>('request')
  const showToast = useToast()
  const ql = useQueryClient()

  const getBody = () => {
    if (!selectedItem) {
      return ''
    }

    let text = activeTab == 'request' ? selectedItem.request_body : selectedItem.response_body

    if (!text) {
      return ''
    }

    try {
      let body = JSON.parse(text)
      return JSON.stringify(body, null, 2)
    } catch (e) {
      return text
    }
  }

  useEffect(() => {
    if (items?.length) {
      setSelectedItem(items[0])
    }
  }, [items])

  const isError = (item: WebhookLogRead) => {
    return item.status == SendStatus.Error || !item.status_code || item.status_code >= 400
  }

  const resendConfirm = async (item: WebhookLogRead) => {
    try {
      await dashboardClient.v1.resendWebhook(item.id, companyId, gameId, item.webhook_id)
      await new Promise(r => setTimeout(r, 4000))

      await Promise.all([
        refetchWebhookLogQuery(ql, companyId, gameId, item.webhook_id),
        refetchWebhookStatQuery(ql, companyId, gameId, item.webhook_id),
        refetchWebhookTotalStatQuery(ql, companyId, gameId, item.webhook_id),
      ])
    } catch (e) {
      showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
    }
  }

  const openResendModal = useModal<{ item: WebhookLogRead }>(props => (
    <ModalConfirm
      color={'error'}
      subMessage={i18next.t('webhook.resend.confirm', { name: props.item.event_type })}
      confirmButtonText={i18next.t('webhook.resend')}
      onConfirm={async () => await resendConfirm(props.item)}
      {...props}
    />
  ))

  const renderItem = (item: WebhookLogRead) => {
    return (
      <div className="sticky left-0 top-0 self-start overflow-hidden py-2">
        <Tabs>
          <Tab isActive={activeTab == 'request'} onClick={() => setActiveTab('request')}>
            {i18next.t('webhook.request')}
          </Tab>
          <Tab isActive={activeTab == 'response'} onClick={() => setActiveTab('response')}>
            {i18next.t('webhook.response')}
          </Tab>
        </Tabs>

        <div className="mb-3 flex items-center justify-between gap-3">
          <Badge
            variant="outline-secondary"
            className="block h-8 w-full truncate"
            style={{
              lineHeight: '32px',
            }}
          >
            <Link
              to={generatePath(GAME_WEBHOOKS_DETAIL_PATH, {
                companyId: companyId,
                gameId: gameId,
                webhookId: item.webhook_id,
              })}
            >
              {webhooks.find(w => w.id == item.webhook_id)?.url || 'Unknown webhook'}
            </Link>
          </Badge>
          <Button onClick={() => openResendModal({ item: item })} className="font-medium">
            {i18next.t('webhook.resend')}
            <Send03 size={14} />
          </Button>
        </div>

        <Badge
          className="flex w-full items-center rounded-b-none"
          variant={isError(item) ? 'red-tertiary' : 'green-tertiary'}
        >
          <div>HTTP status code</div>
          <div className="ml-auto">
            {item.status_code} ({isError(item) ? 'Error' : 'OK'})
          </div>
        </Badge>
        <div className="rounded-md rounded-t-none border border-border-secondary bg-fg-secondary">
          <Highlight className="h-[492px] overflow-y-auto text-wrap">{getBody()}</Highlight>
        </div>

        {item.status == SendStatus.Error && item.detail && (
          <pre className="mt-3">
            <Callout icon={AlertHexagon} variant="error">
              {item.detail}
            </Callout>
          </pre>
        )}
      </div>
    )
  }

  return (
    <div className={cn('grid grid-cols-2 gap-2.5', rest.className)}>
      <div className="h-full overflow-auto border-r border-border-secondary py-2">
        <Table className="mr-2">
          {items.map(it => (
            <WebhookLogRow
              wl={it}
              id={it.id}
              key={it.id}
              selected={selectedItem?.id == it.id}
              onClick={() => setSelectedItem(it)}
            />
          ))}
        </Table>
      </div>
      {selectedItem && renderItem(selectedItem)}
    </div>
  )
}

export const WebhookLogsInspectorSkeleton = (props: { className?: string }) => {
  return (
    <div className={cn('grid grid-cols-2 gap-2.5', props.className)}>
      <div className="border-r border-border-secondary p-2">
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
        <Skeleton className="mb-0.5 h-[44px] rounded-md" />
      </div>
      <div className="py-2">
        <div className="mb-5 flex gap-1.5 p-0.5">
          <Skeleton className="h-9 rounded-md" />
          <Skeleton className="h-9 rounded-md" />
        </div>

        <div className="mb-3 flex gap-3">
          <Skeleton className="h-8 rounded-md" />
          <Skeleton className="h-8 w-[108px] rounded-md" />
        </div>

        <Skeleton className="h-[492px] rounded-md" />
      </div>
    </div>
  )
}
