import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import Dialog from '@mui/material/Dialog'
import { Button, Checkbox, SpinnerAghanim } from '@/ui'
import { useForm } from 'react-hook-form'
import { OutdatedAgreements } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { postLogin } from '@/api/postLogin'
import { useTheme } from '@mui/material/styles'
import { myLeadQueryOptions } from './api'
import { goToLogout } from '@/util'

export default function AcceptForm(props: { onClose: () => void }) {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState<OutdatedAgreements>({
    privacy: false,
    terms: false,
  })
  const { t } = useTranslation()
  const { register, handleSubmit, watch } = useForm<OutdatedAgreements>()
  const { data: myLead, isLoading } = useQuery(myLeadQueryOptions())

  const onDecline = () => {
    goToLogout()
  }

  const onAccept = async (_: OutdatedAgreements) => {
    if (loading) {
      return
    }
    setLoading(true)
    await dashboardClient.v1.acceptAgreements()
    await postLogin()
    props.onClose()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await dashboardClient.v1.getOutdatedAgreements()
      setInitialValues({
        privacy: data.privacy,
        terms: data.terms,
      })
    }
    fetchData()
  }, [])

  const [privacy, terms] = watch(['privacy', 'terms'])

  let enableAccept = true

  if (initialValues.privacy && !privacy) {
    enableAccept = false
  }

  if (initialValues.terms && !terms) {
    enableAccept = false
  }

  if (!myLead || isLoading) {
    return (
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            width: '690px',
            maxWidth: '690px',
            borderRadius: '24px',
            overflow: 'hidden',
            padding: '48px',
            gap: '48px',
            '& a': {
              color: theme.palette.primary.main,
            },
          },
        }}
        open={true}
      >
        <div className="flex h-80 items-center justify-center">
          <SpinnerAghanim />
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '690px',
          maxWidth: '690px',
          borderRadius: '24px',
          overflow: 'hidden',
          padding: '48px',
          gap: '48px',
          '& a': {
            color: theme.palette.primary.main,
          },
        },
      }}
      open={true}
    >
      <div>
        <h2 className="text-[24px] font-semibold">
          {myLead.completed ? t('terms-dialog.update-title') : t('terms-dialog.first-title')}
        </h2>
        <div className="mt-3 text-[18px] text-text-secondary">
          {myLead.completed ? t('terms-dialog.update-subtitle') : t('terms-dialog.first-subtitle')}
        </div>
      </div>

      <div className="flex flex-col gap-[24px]">
        <div
          className="text-[16px] text-text-secondary"
          dangerouslySetInnerHTML={{ __html: t('terms-dialog.subtitle2') }}
        />
        <div className="flex flex-col gap-18">
          {initialValues.privacy && (
            <Checkbox {...register('privacy', { required: true })}>{t('terms-dialog.pp')}</Checkbox>
          )}

          {initialValues.terms && (
            <Checkbox {...register('terms', { required: true })}>{t('terms-dialog.dt')}</Checkbox>
          )}
        </div>
      </div>
      <div className="flex w-full gap-3">
        <Button onClick={onDecline} color="secondary" size={'lg'} style={{ width: '50%' }}>
          {t('terms-dialog.decline')}
        </Button>
        <Button
          onClick={handleSubmit(onAccept)}
          disabled={!enableAccept}
          variant="primary"
          size={'lg'}
          style={{ width: '50%' }}
        >
          {t('terms-dialog.accept')}
        </Button>
      </div>
    </Dialog>
  )
}
