import { ReactNode } from 'react'

import ImageCellNoText from '@/components/ImageCellNoText'

interface EngagementInlineItemProps {
  name: string
  sku: string
  image_url?: string
  state: ReactNode
}

export const EngagementItemInline = ({ name, sku, image_url, state }: EngagementInlineItemProps) => (
  <div className="inline-flex grow items-center gap-3 p-3">
    <div className="h-full max-h-12 rounded-md bg-fg-primary-alt px-3.5">
      <ImageCellNoText
        image_url={image_url}
        image_size="2.5rem"
        style={{
          objectPosition: 'center',
          objectFit: 'cover',
        }}
      />
    </div>
    <div className="flex grow flex-col gap-1">
      <span className="text-caption-md text-text-secondary">{name}</span>
      <span className="text-caption-sm text-text-tertiary">{sku}</span>
    </div>
    <div className="shrink-0">{state}</div>
  </div>
)
