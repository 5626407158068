import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Locale, LocaleCreate } from '@/api/dashboard'
import { Button, FieldGroup, Modal, ModalFooter, ModalProps, ModalTitle, Select, SelectOption } from '@/ui'
import { LOCALIZATION_LOCALE_PATH, useGenerateCompanyPath } from '@/libs'
import { localeCreateMutation, localesQueryOptions } from '../api'
import { LOCALE_FLAGS } from '@/util'

interface L10nLocaleModalAddProps extends ModalProps {}

export const L10nLocaleModalAdd = ({ ...rest }: L10nLocaleModalAddProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const { data: locales = [], isLoading } = useQuery(localesQueryOptions(companyId, gameId))
  const { mutateAsync } = localeCreateMutation()

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = useForm<LocaleCreate>({
    defaultValues: {
      locale: undefined,
    },
  })

  const availableLocaleOptions: SelectOption[] = useMemo(
    () =>
      Object.values(Locale)
        .sort((a, b) => a.localeCompare(b))
        .filter(locale => !locales.map(v => v.locale).includes(locale) && locale !== Locale.En)
        .map(locale => ({
          value: locale,
          children: t(`locales.${locale}`),
          icon: <img className="w-5 rounded-sm" src={`${LOCALE_FLAGS}/${locale.toUpperCase()}.svg`} alt={locale} />,
        })),
    [locales],
  )

  const onSubmit = handleSubmit(async data => {
    const locale = await mutateAsync({ data })
    navigate(generatePath(LOCALIZATION_LOCALE_PATH, { localeId: locale.id }))
  })

  return (
    <Modal {...rest} isLoading={isLoading}>
      <ModalTitle>{t('localization.locale.add-modal.title')}</ModalTitle>

      <form onSubmit={onSubmit}>
        <FieldGroup label={t('localization.locale.add-modal.label')}>
          <Controller
            control={control}
            name="locale"
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder={
                  availableLocaleOptions.length
                    ? t('localization.locale.add-modal.placeholder')
                    : t('localization.locale.add-modal.no-available')
                }
                filter={(opt, search) =>
                  opt.value?.toString().toLowerCase().includes(search.toLowerCase()) ||
                  opt.children?.toString().toLowerCase().includes(search.toLowerCase()) ||
                  false
                }
                options={availableLocaleOptions}
                disabled={!availableLocaleOptions.length}
                errors={errors}
              />
            )}
          />
        </FieldGroup>

        <ModalFooter>
          <Button type="button" size="lg" onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" size="lg" isLoading={isSubmitting}>
            {t('localization.locale.add-modal.confirm')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
