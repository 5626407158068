import { PaymentStatus } from '@/pay-types'
import PageBlock from '../../game-items/components/PageBlock'
import i18next from 'i18next'
import { PADDING, getMinPaymentByStatus } from '../util'
import { PaymentRead } from '@/api/dashboard'

import PaymentStatusChip, {
  getStatusClassBackgroundColorAsText,
  getStatusClassTextColor,
} from '../components/PaymentStatusChip'
import { cn } from '@/libs'
import { getDateTimeFormatParams } from '@/libs/dates'
import { useTranslation } from 'react-i18next'

export const TransactionTimeline = (props: { payments: PaymentRead[]; sourcePayments: PaymentRead[] }) => {
  const { payments, sourcePayments } = props
  const { t } = useTranslation()
  const hasRefunded = payments.find(it => it.status === PaymentStatus.refunded)
  let items = [...payments].reverse()

  if (hasRefunded) {
    items = items.filter(it => it.status != PaymentStatus.refund_requested)
  }

  const getPaymentDate = (p: PaymentRead) => {
    p = getMinPaymentByStatus(sourcePayments, p.status as PaymentStatus)
    if (!p) {
      return <></>
    }

    return t('intl.DateTime', getDateTimeFormatParams(p.payment_date!))
  }

  const renderArrow = (p: PaymentRead) => {
    return (
      <div className="flex items-center" style={{ flexGrow: 1 }}>
        <svg
          width="226"
          height="16"
          viewBox="0 0 226 16"
          fill="none"
          className={cn(getStatusClassBackgroundColorAsText(p.status as PaymentStatus))}
        >
          <path
            d="M1 7C0.447715 7 -2.41411e-08 7.44772 0 8C2.41411e-08 8.55228 0.447715 9 1 9L1 7ZM225.707 8.7071C226.098 8.31657 226.098 7.68341 225.707 7.29288L219.343 0.928923C218.953 0.538398 218.319 0.538398 217.929 0.928923C217.538 1.31945 217.538 1.95261 217.929 2.34314L223.586 7.99999L217.929 13.6568C217.538 14.0474 217.538 14.6805 217.929 15.0711C218.319 15.4616 218.953 15.4616 219.343 15.0711L225.707 8.7071ZM1 9L225 8.99999L225 6.99999L1 7L1 9Z"
            fill="currentColor"
          />
        </svg>
      </div>
    )
  }

  return (
    <PageBlock title={i18next.t('transaction-details.timeline')} padding={PADDING}>
      <div className="flex gap-3 overflow-hidden" role="timeline">
        {items.map((p, idx) => (
          <div key={p.status}>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-3">
                <PaymentStatusChip payment={[p]} showText={false} />
                {items.length - 1 != idx && renderArrow(p)}
              </div>

              <div className="flex flex-col gap-1">
                <div
                  className={cn(getStatusClassTextColor(p.status as PaymentStatus), 'text-caption-md font-semibold')}
                >
                  {i18next.t(`transactions-table.status.${p.status}`)}
                </div>
                <div className="text-caption-xs font-normal text-text-tertiary">{getPaymentDate(p)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </PageBlock>
  )
}
