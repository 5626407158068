import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'
import { WebhookRedacted } from '../../../api/dashboard'
import { ModalConfirm, ModalProps } from '@/ui'
import { useGenerateCompanyPath } from '../../../libs/hooks'
import { GAME_WEBHOOKS_PATH } from '../../../libs/routerPaths'
import { webhooksQuery } from '../api'

interface WebhookDeleteModalProps extends ModalProps {
  webhook: WebhookRedacted
}

export const WebhookDeleteModal = ({ webhook, ...rest }: WebhookDeleteModalProps) => {
  const ql = useQueryClient()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()

  const onClickConfirm = async () => {
    await dashboardClient.v1.deleteWebhook(companyId, gameId, webhook.id)
    await ql.refetchQueries({ queryKey: webhooksQuery(companyId, gameId).queryKey })
    navigate(generatePath(GAME_WEBHOOKS_PATH))
  }

  return (
    <ModalConfirm
      {...rest}
      message={t('webhook.delete-modal.title')}
      confirmButtonText={t('webhook.delete-modal.delete-button')}
      color="error"
      onConfirm={onClickConfirm}
    >
      <p className="text-paragraph-md text-text-secondary">{t('webhook.delete-modal.message')}</p>
    </ModalConfirm>
  )
}
