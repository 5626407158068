import i18next from 'i18next'
import { generatePath, useParams } from 'react-router-dom'
import { getTransactionTotal } from '../util'
import { PaymentRead } from '../../../api/dashboard'
import PaymentStatusChip from './PaymentStatusChip'
import PayCardView from '../PayCardView'
import styled from '@emotion/styled'
import { Table, TableCell, TableRow, TableRowSkeleton, TableZeroState } from '@/ui'
import { TRANSACTIONS_DETAILS_PATH } from '../../../libs/routerPaths'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '../../../api/useGameItems'
import { getItemName } from '../../../api/getItemName'
import { usePaymentMethods } from '../../../api/usePaymentMethods'
import { cn } from '../../../libs/cn'
import { DateTimeValue } from '@/components/ui/DateTimeValue'

const Cell = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const TransactionsTableView = (props: {
  payments: PaymentRead[]
  width?: string
  isLoading: boolean
  search?: boolean
  error?: boolean
  showUserCol?: boolean
}) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const { data: items = [], isLoading: itemsLoading } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
  })

  const { isLoading: methodsLoading } = usePaymentMethods(companyId, gameId)

  if (!props.isLoading && !props.payments.length) {
    let msg = !props.showUserCol ? 'transaction-details.no-transactions-of-user' : 'no-transactions'
    if (props.error) {
      msg = 'SomethingWentWrong'
    } else if (props.search) {
      msg = 'no-transactions-found'
    }
    return (
      <TableZeroState
        className={cn('h-full', props.showUserCol === false ? 'h-[147px]' : '')}
        title={props.error ? i18next.t('service-unavailable') : i18next.t('transaction-details.no-transactions')}
        message={i18next.t(msg)}
      />
    )
  }

  if (props.isLoading || methodsLoading || itemsLoading) {
    return <TableRowSkeleton rowCount={10} columnCount={7} />
  }

  return (
    <Table role="transaction-table">
      <TableRow variant="header">
        <TableCell width={150}>{i18next.t('transactions-table.date')}</TableCell>
        <TableCell width={150}>{i18next.t('transactions-table.amount')}</TableCell>
        <TableCell width={150}>{i18next.t('transactions-table.status')}</TableCell>
        <TableCell className="min-w-[150px]">
          <Cell>{i18next.t('transactions-table.method')} </Cell>
        </TableCell>
        <TableCell width={props.showUserCol === false ? 300 : 200}>
          <Cell>{i18next.t('transactions-table.item')} </Cell>
        </TableCell>
        {props.showUserCol !== false && (
          <TableCell width={200}>
            <Cell>{i18next.t('transactions-table.user')} </Cell>
          </TableCell>
        )}
        <TableCell>
          <Cell>{i18next.t('transactions-table.id')} </Cell>
        </TableCell>
      </TableRow>
      {props.payments.map(it => (
        <TableRow
          id={it.payment_number as string}
          key={it.payment_number}
          to={generatePath(TRANSACTIONS_DETAILS_PATH, {
            companyId,
            gameId,
            paymentNumber: it.payment_number as string,
          })}
        >
          <TableCell width={150} data-testid={'transaction-date-value'}>
            <DateTimeValue value={it.created_at! / 1_000_000} />
          </TableCell>
          <TableCell width={150} data-testid={'transaction-total'} className="ph-no-capture">
            {getTransactionTotal(it)}
          </TableCell>

          <TableCell width={150} data-testid={'transaction-status'}>
            <PaymentStatusChip payment={[it]} />
          </TableCell>
          <TableCell className="min-w-[150px]">
            <Cell>
              <PayCardView payment={it} variant="two-lines" />
            </Cell>
          </TableCell>

          <TableCell width={props.showUserCol === false ? 300 : 200} data-testid={'transaction-item'}>
            <Cell>{getItemName(items.find(item => item.id == it.item_id)) || it.item_name}</Cell>
          </TableCell>
          {props.showUserCol !== false && (
            <TableCell width={200} data-testid={'transaction-user'}>
              <Cell>{it.user_name || it.player_name || it.player_id}</Cell>
            </TableCell>
          )}

          <TableCell data-testid={'transaction-payment-number'}>
            <Cell>{it.payment_number}</Cell>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

export default TransactionsTableView
