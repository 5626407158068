import styled from '@emotion/styled'
import i18next from 'i18next'
import Divider from '../../../components/Divider'
import { Settings } from '../../../components/ui/Settings'
import { Checkbox, FieldGroup, Input, InputExtraMark, Select } from '@/ui'
import { useContext, useEffect, useState } from 'react'
import { CouponType, CreateCouponNode, Node } from '../../../api/dashboard'
import { SelectSkuItem } from '../../campaigns/components/SelectSkuItem/SelectSkuItem'
import { SelectPaymentMethod } from '../../campaigns/components/SelectPaymentMethod/SelectPaymentMethod'
import DurationSelectorDHMS from '../../../components/DurationSelectorDHMS'
import { NODE_VALIDATORS } from '../../campaigns/validation'
import { CampaignContext, ICampaignContext } from '../../campaigns/Context'
import { BlockError, ErrorInfo } from '../../campaigns/types'
import SettingsHeader from '../../campaigns/SettingsHeader'
import { CheckBoxContainer } from '../../../components/ui/CheckBoxContainer'
import { CONTEXT_ITEM, getContextItemText } from '../../campaigns/editors/common'

const Root = styled.div``

const Content = styled.div`
  padding: 24px;
`

interface CouponSettingsProps {
  onClose: () => void
  onSave: (node: Node) => void
  node: CreateCouponNode
  error?: BlockError | null
}

enum MaskType {
  PreDetermined = 'Pre-determined',
  Generated = 'Generated',
}

export default function CouponSettings(props: CouponSettingsProps) {
  const context = useContext(CampaignContext) as ICampaignContext
  const [maskType, setMaskType] = useState<MaskType>(props.node.code_mask ? MaskType.PreDetermined : MaskType.Generated)
  const [errorFields, setErrorFields] = useState<ErrorInfo[]>([])
  const [node, setNode] = useState<CreateCouponNode>({
    ...props.node,
  } as CreateCouponNode)

  const onSaveClick = () => {
    let errors = NODE_VALIDATORS[node.model_type as string](node)
    if (maskType == MaskType.PreDetermined) {
      if (!node.code_mask) {
        errors.push({
          field: 'code_mask',
          message: i18next.t('campaign.field-required'),
        })
      }
    }
    setErrorFields(errors)
    if (errors.length == 0) {
      props.onSave(node as Node)
    }
  }

  const getError = (field: string) => {
    return errorFields.find(f => f.field == field)?.message
  }

  useEffect(() => {
    setMaskType(props.node.code_mask ? MaskType.PreDetermined : MaskType.Generated)
    setNode({ ...props.node })
  }, [props.node])

  useEffect(() => {
    if (errorFields.length) {
      let currentErrors = NODE_VALIDATORS[node.model_type as string](node)

      setErrorFields(errorFields.filter(it => currentErrors.find(e => e.field == it.field)))
    }
  }, [node])

  useEffect(() => {
    if (props.error) {
      setErrorFields(props.error.errors)
    }
  }, [props.error])

  const renderGeneral = () => {
    return (
      <Settings text={i18next.t('coupon.general')}>
        <FieldGroup label={i18next.t('coupon.type')}>
          <Select
            value={node.type}
            onChange={v => {
              switch (v) {
                case CouponType.FreeItem:
                  setNode({
                    ...node,
                    applicable_item_ids: undefined,
                    payment_method_ids: undefined,
                    bonus_percent: undefined,
                    discount_percent: undefined,
                    min_amount: undefined,
                    is_stackable_with_bonus_coupons: undefined,
                    stackable_bonus_limit_percent: undefined,
                    is_stackable_with_discount_coupons: undefined,
                    stackable_discount_limit_percent: undefined,
                    type: CouponType.FreeItem,
                  })
                  break
                case CouponType.Bonus:
                  setNode({
                    ...node,
                    item_id: undefined,
                    discount_percent: undefined,
                    is_stackable_with_discount_coupons: undefined,
                    stackable_discount_limit_percent: undefined,
                    type: v as CouponType,
                  })
                  break
                case CouponType.Discount:
                  setNode({
                    ...node,
                    item_id: undefined,
                    bonus_percent: undefined,
                    is_stackable_with_bonus_coupons: undefined,
                    stackable_bonus_limit_percent: undefined,
                    type: v as CouponType,
                  })
                  break
                default:
                  console.log('Unknown coupon type')
                  break
              }
            }}
            options={[
              {
                value: CouponType.Bonus,
                children: i18next.t('coupon.type.bonus'),
              },
              {
                value: CouponType.Discount,
                children: i18next.t('coupon.type.discount'),
              },
              {
                value: CouponType.FreeItem,
                children: i18next.t('coupon.type.free_item'),
              },
            ]}
          />
        </FieldGroup>
      </Settings>
    )
  }

  const renderCodeMask = () => {
    return (
      <Settings text={i18next.t('coupon.code-mask')}>
        <FieldGroup label={i18next.t('coupon.code-mask-type')}>
          <div style={{ gap: '40px', display: 'flex' }}>
            <Checkbox
              type="radio"
              checked={maskType == MaskType.Generated}
              onChange={v => {
                if (v) {
                  setMaskType(MaskType.Generated)
                  setNode({
                    ...node,
                    code_mask: '',
                  })
                }
              }}
            >
              {i18next.t('coupon.code-mask-type.generated')}
            </Checkbox>
            <Checkbox
              type="radio"
              checked={maskType == MaskType.PreDetermined}
              onChange={v => {
                if (v) {
                  setMaskType(MaskType.PreDetermined)
                  setNode({
                    ...node,
                    code_mask: 'EXAMPLE*',
                  })
                }
              }}
            >
              {i18next.t('coupon.code-mask-type.pre-determined')}
            </Checkbox>
          </div>
        </FieldGroup>

        {maskType == 'Pre-determined' && (
          <div className="mt-4">
            <FieldGroup
              caption={i18next.t('coupon.code-mask.desc')}
              label={i18next.t('coupon.code-mask')}
              error={getError('code_mask')}
            >
              <Input
                color={getError('code_mask') ? 'error' : 'default'}
                value={node.code_mask || ''}
                onChange={e => {
                  let input = e.target.value.toUpperCase()
                  setNode({
                    ...node,
                    code_mask: input.replace(/[^*A-Z0-9]/gi, ''),
                  })
                }}
              />
            </FieldGroup>
          </div>
        )}
      </Settings>
    )
  }

  const renderGeneralLimitations = () => {
    return (
      <Settings text={i18next.t('coupon.general-limitations')}>
        <CheckBoxContainer
          caption={i18next.t('coupon.reusable-desc')}
          label={i18next.t('coupon.reusable')}
          checked={node.max_redemptions != undefined}
          onChange={v => {
            setNode({
              ...node,
              max_redemptions: v.target.checked ? 0 : undefined,
            })
          }}
        >
          <Input
            type="number"
            color={getError('max_redemptions') ? 'error' : 'default'}
            value={node.max_redemptions}
            onChange={e => {
              let v = parseFloat(e.target.value)
              setNode({
                ...node,
                max_redemptions: v,
              })
            }}
          />
        </CheckBoxContainer>

        <Divider />

        <Checkbox
          caption={i18next.t('coupon.player-bond-desc')}
          checked={node.player_bond}
          onChange={v => {
            setNode({
              ...node,
              player_bond: v.target.checked,
            })
          }}
        >
          {i18next.t('coupon.player-bond')}
        </Checkbox>

        {node.type != CouponType.FreeItem && (
          <>
            <Divider />
            <CheckBoxContainer
              checked={!!node.applicable_item_ids}
              onChange={v => {
                setNode({
                  ...node,
                  applicable_item_ids: v.target.checked ? [] : undefined,
                })
              }}
              label={i18next.t('coupon.sku-bound')}
              caption={i18next.t('coupon.sku-bound-desc')}
            >
              <SelectSkuItem
                error={getError('applicable_item_ids')}
                color={getError('applicable_item_ids') ? 'error' : 'default'}
                contextItemText={getContextItemText(context.campaign) || undefined}
                multiselect
                value={node.applicable_item_ids || []}
                onChange={v => {
                  setNode({
                    ...node,
                    applicable_item_ids: v as unknown as string[],
                  })
                }}
              />
            </CheckBoxContainer>

            <Divider />

            <CheckBoxContainer
              checked={!!node.payment_method_ids}
              onChange={v => {
                setNode({
                  ...node,
                  payment_method_ids: v.target.checked ? [] : undefined,
                })
              }}
              label={i18next.t('coupon.payment-bound')}
              caption={i18next.t('coupon.payment-bound-desc')}
            >
              <SelectPaymentMethod
                //error={getError('payment_method_ids')}
                color={getError('payment_method_ids') ? 'error' : 'default'}
                value={(node.payment_method_ids || []) as string[]}
                onChange={v => {
                  setNode({
                    ...node,
                    payment_method_ids: v as unknown as string[],
                  })
                }}
              />
            </CheckBoxContainer>

            <Divider />
            <CheckBoxContainer
              checked={node.min_amount != undefined}
              onChange={v => {
                setNode({
                  ...node,
                  min_amount: v.target.checked ? 100 : undefined,
                })
              }}
              label={i18next.t('coupon.min_amount')}
            >
              <Input
                type="number"
                color={getError('min_amount') ? 'error' : 'default'}
                value={isNaN(node.min_amount!) ? '' : node.min_amount! / 100}
                extraLeft={<InputExtraMark>$</InputExtraMark>}
                onChange={e => {
                  let v = parseFloat(e.target.value)
                  setNode({
                    ...node,
                    min_amount: Math.max(1, v * 100),
                  })
                }}
              />
            </CheckBoxContainer>
          </>
        )}
      </Settings>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <Settings text={i18next.t('coupon.limit-limitations')}>
        <CheckBoxContainer
          checked={node.duration != undefined}
          onChange={v => {
            setNode({
              ...node,
              duration: v.target.checked ? 3600 : undefined,
            })
          }}
          label={i18next.t('campaign.block.ItemDiscountOfferActionNode.limit-timer')}
        >
          <DurationSelectorDHMS
            duration={node.duration || 0}
            size="small"
            onChange={v => {
              setNode({
                ...node,
                duration: v,
              })
            }}
          />
        </CheckBoxContainer>
      </Settings>
    )
  }

  const renderBenefitsCompatibility = () => {
    return (
      <Settings text={i18next.t('coupon.benefits-compatibility')}>
        {node.type == CouponType.Bonus && (
          <CheckBoxContainer
            checked={!!node.is_stackable_with_bonus_coupons}
            onChange={v => {
              setNode({
                ...node,
                is_stackable_with_bonus_coupons: v.target.checked,
              })
            }}
            label={i18next.t('coupon.benefits.stackable')}
          >
            <FieldGroup
              error={getError('stackable_bonus_limit_percent')}
              caption={i18next.t('coupon.stackable-limit-desc')}
            >
              <Input
                color={getError('stackable_bonus_limit_percent') ? 'error' : 'default'}
                extraLeft={<InputExtraMark>%</InputExtraMark>}
                type="number"
                value={isNaN(node.stackable_bonus_limit_percent as number) ? '' : node.stackable_bonus_limit_percent}
                onChange={e => {
                  setNode({
                    ...node,
                    stackable_bonus_limit_percent: parseInt(e.target.value),
                  })
                }}
              />
            </FieldGroup>
          </CheckBoxContainer>
        )}

        {node.type == CouponType.Discount && (
          <CheckBoxContainer
            checked={!!node.is_stackable_with_discount_coupons}
            onChange={v => {
              setNode({
                ...node,
                is_stackable_with_discount_coupons: v.target.checked,
              })
            }}
            label={i18next.t('coupon.stackable-discount-code')}
          >
            <FieldGroup
              error={getError('stackable_discount_limit_percent')}
              caption={i18next.t('coupon.stackable-discount-limit-desc')}
            >
              <Input
                color={getError('stackable_discount_limit_percent') ? 'error' : 'default'}
                extraLeft={<InputExtraMark>%</InputExtraMark>}
                value={
                  isNaN(node.stackable_discount_limit_percent as number) ? '' : node.stackable_discount_limit_percent
                }
                onChange={e => {
                  setNode({
                    ...node,
                    stackable_discount_limit_percent: parseInt(e.target.value),
                  })
                }}
              />
            </FieldGroup>
          </CheckBoxContainer>
        )}
      </Settings>
    )
  }

  const renderBenefits = () => {
    if (node.type == CouponType.FreeItem) {
      return (
        <Settings text={i18next.t('coupon.benefits')}>
          <FieldGroup error={getError('item_id')} label={i18next.t('coupon.select-free-item')}>
            <SelectSkuItem
              color={getError('item_id') ? 'error' : 'default'}
              value={node.use_event_item ? CONTEXT_ITEM : node.item_id}
              contextItemText={getContextItemText(context.campaign) || undefined}
              onChange={v => {
                setNode({
                  ...node,
                  use_event_item: v == CONTEXT_ITEM,
                  item_id: v == CONTEXT_ITEM ? undefined : (v as string),
                })
              }}
            />
          </FieldGroup>
        </Settings>
      )
    }

    return (
      <Settings text={i18next.t('coupon.benefits')}>
        {node.type == CouponType.Discount ? (
          <FieldGroup
            caption={i18next.t('coupon.value-desc')}
            error={getError('discount_percent')}
            label={i18next.t('coupon.discount')}
          >
            <Input
              type="number"
              color={getError('discount_percent') ? 'error' : 'default'}
              value={isNaN(node.discount_percent as number) ? '' : node.discount_percent}
              onChange={e => {
                setNode({
                  ...node,
                  discount_percent: parseInt(e.target.value),
                })
              }}
              extraLeft={<InputExtraMark>%</InputExtraMark>}
            />
          </FieldGroup>
        ) : (
          <FieldGroup
            error={getError('bonus_percent')}
            caption={i18next.t('coupon.value-desc')}
            label={i18next.t('coupon.bonus')}
          >
            <Input
              type="number"
              color={getError('bonus_percent') ? 'error' : 'default'}
              value={isNaN(node.bonus_percent as number) ? '' : node.bonus_percent}
              onChange={e => {
                setNode({
                  ...node,
                  bonus_percent: parseInt(e.target.value),
                })
              }}
              extraLeft={<InputExtraMark>%</InputExtraMark>}
            />
          </FieldGroup>
        )}
      </Settings>
    )
  }

  return (
    <Root>
      <SettingsHeader onSave={onSaveClick} onClose={props.onClose} text={i18next.t('coupon.settings')} />

      <Content>
        {renderGeneral()}
        <Divider />
        {renderCodeMask()}

        <Divider />

        {renderBenefits()}

        {node.type != CouponType.FreeItem && (
          <>
            <Divider />

            {renderBenefitsCompatibility()}
          </>
        )}

        <Divider />

        {renderGeneralLimitations()}

        <Divider />

        {renderTimeLimitations()}
      </Content>
    </Root>
  )
}
