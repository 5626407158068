import AnimatedDots from '../../../../components/animated-dots/AnimatedDots'
import { DataGranularity, MeasureValue, PointValue } from '../../../../api/dashboard'
import { formatMoney, formatNumber } from '../../../../util'
import { useTheme } from '@mui/material/styles'
import { ArrowsUpIcon } from '../../../dashboard/icons/ArrowsUpIcon'
import { ArrowsDownIcon } from '../../../dashboard/icons/ArrowsDownIcon'
import SparkLine from './SparkLine'
import { TooltipAlertCircle } from '@/ui'

export enum SingleValueType {
  value,
  money,
  percent,
}

export default function SVSparkLine(props: {
  value: MeasureValue | undefined
  sparklineData: PointValue[]
  label: string
  tooltip?: string
  type?: SingleValueType
  granularity?: DataGranularity
}) {
  const theme = useTheme()
  let percentChange = 0
  if (props.value && props.value.prev_value) {
    if (props.value.prev_value) {
      percentChange = (100 * (props.value.value - props.value.prev_value)) / props.value.prev_value
    } else {
      percentChange = 100
    }
  }

  let valueStr: JSX.Element | string = <AnimatedDots />
  if (props.value) {
    switch (props.type) {
      case SingleValueType.money:
        valueStr = formatMoney(props.value.value) as string
        break
      case SingleValueType.percent:
        valueStr = props.value.value.toFixed(2) + '%'
        break
      case SingleValueType.value:
      default:
        valueStr = formatNumber(props.value.value)
        break
    }
  }

  return (
    <div
      style={{ width: '279px', boxShadow: '0px 1px 2px 0px #1018280D' }}
      className="flex flex-col gap-2  rounded-xl border border-border-secondary p-3"
    >
      <div className="text-sm font-medium text-text-tertiary">
        {props.label}
        {props.tooltip && <TooltipAlertCircle message={props.tooltip} />}
      </div>
      <div className="flex">
        <div className="flex flex-col">
          <span className="text-xl font-medium text-text-primary">{valueStr}</span>
          {props.value?.prev_value != undefined && (
            <div className="mt-auto flex">
              {percentChange >= 0 ? (
                <ArrowsUpIcon style={{ color: theme.palette.success.main }} />
              ) : (
                <ArrowsDownIcon style={{ color: theme.palette.error.main }} />
              )}

              <div
                className="text-sm font-medium"
                style={{
                  color: percentChange >= 0 ? theme.palette.success.main : theme.palette.error.main,
                }}
              >
                {percentChange.toFixed(2) + '%'}
              </div>
            </div>
          )}
        </div>
        <div className="ml-auto">
          <SparkLine
            data={props.sparklineData}
            isRed={percentChange < 0}
            type={props.type}
            granularity={props.granularity}
          />
        </div>
      </div>
    </div>
  )
}
