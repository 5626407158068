import DashboardCard from '../../../../components/shared/DashboardCard'
import i18next from 'i18next'
import { LoaderContainer } from '../Loader/LoaderContainer'
import Loader from '../Loader/Loader'
import { Fragment, HTMLAttributes, useContext, useState } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { useGameItemsQuery } from '../../../../api/useGameItems'
import ZeroState from '../ZeroState/ZeroState'
import { SkuImageName } from '../../../game-items/components/SkuImageName'
import { SKU_ITEMS_DETAILS_PATH } from '../../../../libs/routerPaths'
import { ItemClaimedType, ResourceState } from '../../../../api/dashboard'

import { AuthContext, IAuthContext } from '@/Context'
import { canEdit } from '@/security'
import { useTopClaimedQuery } from '@/layouts/dashboard/api/useTopClaimedQuery'
import { AnalyticsDataContext, IAnalyticsDataContext } from '@/layouts/dashboard/Context'
import { getUrlParamsObject } from '@/layouts/dashboard/util'
import { formatNumber } from '@/util'
import { Trans, useTranslation } from 'react-i18next'
import { itemValues30, itemValues7 } from '@/layouts/dashboard/demo_data/createSankeyData'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import LabelFilter from '@/layouts/dashboard/components/TopCharts/LabelFilter'

const Separator = styled.div`
  width: calc(100% - 38px - 12px);
  height: 1px;
  background-color: ${p => p.theme.palette.background.fgskeleton};
  margin-left: auto;
`

interface TopSkuItemsProps extends HTMLAttributes<HTMLDivElement> {}

export default function TopClaimedSkuItems(props: TopSkuItemsProps) {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const authContext = useContext(AuthContext) as IAuthContext
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)
  const { t } = useTranslation()
  const [selectedType, setSelectedType] = useState<ItemClaimedType | undefined>(undefined)
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  let { data = [], isLoading } = useTopClaimedQuery(companyId, gameId, {
    ...getUrlParamsObject(context.selectedFilter),
    item_type: selectedType ? selectedType : undefined,
    limit: 5,
  })

  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    ids: data.map(it => it.category).join(','),
    state: ResourceState.Active,
  })

  if (import.meta.env.VITE_DEMO_GAME_ID == gameId) {
    let itemValues = context.selectedFilter == '7' ? itemValues7 : itemValues30

    data = items.slice(5, 10).map((it, idx) => {
      let value = itemValues[idx % itemValues.length]
      return { category: it.id, value }
    })
  }

  const getItemCells = (id: string) => {
    const item = items.find(it => it.id === id)
    if (!item) {
      return <></>
    }

    if (!canEdit(authContext.customer)) {
      return (
        <div className="flex w-full items-center gap-3">
          <SkuImageName item={item} />
        </div>
      )
    }

    return (
      <Link
        className="flex w-full items-center gap-3"
        to={generatePath(SKU_ITEMS_DETAILS_PATH, { companyId, gameId, itemId: item.id || '' })}
      >
        <SkuImageName item={item} />
      </Link>
    )
  }

  const getTitle = () => {
    if (!feature_flags?.daily_rewards_enabled && !feature_flags?.loyalty_program_enabled) {
      return i18next.t('dashboard.sales.top-5-free-claimed-items')
    }

    return (
      <Trans
        i18nKey="dashboard.sales.top-5-claimed-items"
        components={{
          filter: (
            <LabelFilter
              items={[
                {
                  label: t('claimed.type.all'),
                  value: undefined as unknown as null,
                },
                {
                  label: t('claimed.type.store_item'),
                  value: ItemClaimedType.StoreItem,
                },
                feature_flags?.daily_rewards_enabled && {
                  label: t('claimed.type.daily_reward'),
                  value: ItemClaimedType.DailyReward,
                },
                feature_flags?.loyalty_program_enabled && {
                  label: t('claimed.type.loyalty_program'),
                  value: ItemClaimedType.LoyaltyProgram,
                },
              ].filter(it => !!it)}
              value={selectedType as string}
              onChange={v => setSelectedType(v as ItemClaimedType)}
            />
          ),
        }}
      />
    )
  }

  const total = data?.length ? data.filter(it => !!it.value).reduce((acc, item) => acc + item.value!, 0) : 0
  const feature_flags = gameSettings?.feature_flags
  return (
    <DashboardCard
      title={getTitle()}
      data-testid="dashboard/chart/top5-claimed-sku"
      style={{
        ...props.style,
        gap: '8px',
      }}
    >
      {isLoading ? (
        <LoaderContainer style={{ height: '460px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            data-testid="dashboard/chart/top5-claimed-sku/total"
            className="flex h-[52px] items-center text-title-t3 font-semibold text-text-secondary"
          >
            {t('dashboard.total')} {formatNumber(total)}
          </div>
          {data.length > 0 ? (
            <div style={{ maxHeight: '384px' }}>
              {data.map((item, index) => (
                <Fragment key={item.category}>
                  <div className="flex h-[66px] items-center">
                    <div className="w-full">{getItemCells(item.category)}</div>
                    <div className="text-right">
                      <div
                        className="text-text-primary"
                        data-testid={`dashboard/chart/top5-claimed-sku/${item.category}/count`}
                      >
                        {formatNumber(item.value!)}
                      </div>
                    </div>
                  </div>
                  {index < data.length - 1 && <Separator />}
                </Fragment>
              ))}
            </div>
          ) : (
            <ZeroState style={{ height: '384px' }} />
          )}
        </>
      )}
    </DashboardCard>
  )
}
