import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import { canEdit } from '../../security'
import {
  Button,
  ModalConfirm,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  ToastSeverity,
  useModal,
  usePagination,
  useToast,
} from '@/ui'
import { EditIcon } from '../../icons/Icons'
import { DotsGridSix, Plus, Trash } from '@/icons'
import { useItemCategoriesQuery, useItemCategoryDeleteMutate } from './api'
import { ItemCategoryRead } from '../../api/dashboard'
import { dashboardClient } from '../../api'
import { useBulkSelect } from '../../libs/hooks/useBulkSelect'
import EditItemCategoryDialog from './EditItemCategoryDialog'
import { ReactSortable } from 'react-sortablejs'
import { AuthContext, IAuthContext } from '@/Context'

export default function ItemCategoryPage() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const [isDirty, setIsDirty] = useState(false)
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)
  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const { data: items = [], isLoading, refetch } = useItemCategoriesQuery(companyId, gameId)
  const { mutateAsync: deleteMutateAsync } = useItemCategoryDeleteMutate(companyId, gameId)
  const { selected, onSelect, onReset } = useBulkSelect()
  const [orderItems, setOrderItems] = useState<ItemCategoryRead[]>([])
  const showToast = useToast()

  useEffect(() => {
    setOrderItems(items.map(it => ({ ...it })))
  }, [items, isLoading])

  const openBulkDeleteModal = useModal<{ subMessage: string }>(props => (
    <ModalConfirm
      color={'error'}
      confirmButtonText={i18next.t('remove')}
      message={i18next.t('item-category.remove-n-title')}
      onConfirm={() => onConfirmDeleteItems()}
      {...props}
    />
  ))

  const openDeleteModal = useModal<{ item: ItemCategoryRead }>(props => (
    <ModalConfirm
      color={'error'}
      subMessage={i18next.t('confirm.text', { name: props.item.name })}
      confirmButtonText={i18next.t('remove')}
      onConfirm={() => onDeleteGameItemClick(props.item)}
      {...props}
    />
  ))

  const openEditModal = useModal<{ item: ItemCategoryRead }>(props => <EditItemCategoryDialog {...props} />)

  const onClickBulkDelete = useCallback(() => {
    openBulkDeleteModal({ subMessage: i18next.t('item-category.remove-n-items', { N: selected.size }) })
  }, [items, selected, openBulkDeleteModal])

  const onDeleteGameItemClick = async (item: ItemCategoryRead) => {
    await deleteMutateAsync({ id: item.id })
  }

  const onConfirmDeleteItems = async () => {
    await dashboardClient.v1.bulkDeleteItemCategories(
      companyId,
      gameId,
      Array.from(selected).map(id => ({ id })),
    )

    onReset([])

    refetch()
  }

  const onDiscardClick = () => {
    setOrderItems(items.map(it => ({ ...it })))
    setIsDirty(false)
  }

  const saveOrder = async () => {
    await dashboardClient.v1.bulkUpdateItemCategories(
      companyId,
      gameId,
      orderItems.map((it, idx) => ({
        id: it.id,
        position: idx,
      })),
    )
    refetch()
    setIsDirty(false)
    showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        {items.length > 0 && canUserEdit && (
          <TableCellBulkCheckbox
            selected={selected.size > 0 && selected.size == items.length}
            disabled={items.length === 0}
            onChange={() => onReset(selected.size == items.length ? [] : items.map(i => i.id))}
          />
        )}
        <TableCell width="200%">{i18next.t('item-category.name')}</TableCell>
        <TableCell width="200%">{i18next.t('item-category.slug')}</TableCell>
        <TableCell width="200%">{i18next.t('item-category.description')}</TableCell>
        <TableCell width="5%" />
        <TableCell width="20%" />
      </TableRow>
    )
  }

  const renderBulkSelectionHeader = () => (
    <TableRow variant="header">
      <TableCellBulkCheckbox
        selected={selected.size > 0 && selected.size == items.length}
        disabled={items.length === 0}
        onChange={() => onReset(selected.size == items.length ? [] : items.map(i => i.id))}
      />
      <TableCellBulkToolbar selected={selected} onReset={() => onReset([])}>
        <Button variant="tertiary-destructive" onClick={onClickBulkDelete}>
          <Trash />
          <span>{i18next.t('remove')}</span>
        </Button>
      </TableCellBulkToolbar>
    </TableRow>
  )

  const onMainBtnClick = () => {
    if (isDirty) {
      saveOrder()
    } else {
      openEditModal({ item: { name: '', description: '', position: items.length } as ItemCategoryRead })
    }
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canUserEdit &&
          !!items.length && (
            <>
              {isDirty && <Button onClick={onDiscardClick}>{i18next.t('Discard')}</Button>}
              <Button variant="primary" type="button" onClick={onMainBtnClick}>
                {i18next.t(isDirty ? 'Save2' : 'item-category.create')}
              </Button>
            </>
          )
        }
      >
        {i18next.t('sidebar.categories')}
      </PageHeader>

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : items.length === 0 ? (
        <TableZeroState
          title={i18next.t(
            canUserEdit ? 'item-category.empty-table.title' : 'item-category.empty-table.title.read-only',
          )}
          message={i18next.t(canUserEdit ? 'item-category.empty-table' : 'item-category.empty-table.read-only')}
          buttons={
            canUserEdit && (
              <Button
                variant="primary"
                onClick={() =>
                  openEditModal({
                    item: { name: '', description: '', position: 0 } as ItemCategoryRead,
                  })
                }
              >
                <Plus size={14} />
                <span>{i18next.t('item-category.create')}</span>
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table>
            {selected.size == 0 ? renderDefaultHeader() : renderBulkSelectionHeader()}
            <ReactSortable
              animation={200}
              delay={2}
              disabled={!canUserEdit}
              filter=".non-drag"
              list={orderItems || []}
              setList={v => {
                let changed = false
                for (let i = 0; i < v.length; i++) {
                  if (v[i].id != orderItems[i].id) {
                    changed = true
                    break
                  }
                }
                setOrderItems(v)
                if (changed) {
                  setIsDirty(true)
                }
              }}
            >
              {orderItems.map(it => (
                <TableRow key={it.name}>
                  {canUserEdit && (
                    <TableCellBulkCheckbox selected={selected.has(it.id)} onChange={() => onSelect(it.id)} />
                  )}

                  <TableCell width="200%">{it.name}</TableCell>
                  <TableCell width="200%">{it.slug}</TableCell>
                  <TableCell width="200%">{it.description}</TableCell>
                  <TableCell width="5%">{canUserEdit && <DotsGridSix className="cursor-move" />}</TableCell>
                  <TableCell width="20%">
                    <div className="text-right">
                      {canUserEdit && (
                        <TableRowEditButton
                          onChange={v => {
                            switch (v) {
                              case 'edit':
                                openEditModal({ item: it })
                                break
                              case 'remove':
                                openDeleteModal({ item: it })
                                break
                            }
                          }}
                          options={[
                            {
                              icon: <EditIcon />,
                              children: i18next.t('grid.edit'),
                              value: 'edit',
                            },
                            {
                              icon: <Trash className="text-text-error-primary" />,
                              children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                              value: 'remove',
                            },
                          ]}
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </ReactSortable>
          </Table>
          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
