import { useContext, useEffect } from 'react'
import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import { AuthContext, IAuthContext } from '../../Context'
import { isAdmin } from '../../security'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { dashboardClient, getErrorText } from '../../api'
import { Button, FieldGroup, FieldValidationMessage, ImageUploader, Input, PageHeader } from '@/ui'
import { DomainListEditor } from './DomainListEditor'
import { addCompanyToCache, useCompaniesQuery } from '../../api/useCompaniesQuery'
import { useQueryClient } from '@tanstack/react-query'
import { CompanyGame } from '../../api/dashboard'
import DescBlock from '../game-settings/components/DescBlock'
import Block from '../game-settings/components/Block'
import { Trans } from 'react-i18next'
import { useToast } from '../../components/ui/Toast/useToast'
import { ToastSeverity } from '../../components/ui/Toast/types'
import { urlRegexp } from '../../libs/string'
import { ErrorMessage } from '@hookform/error-message'
import { S3Bucket } from '../../types'
import { ImageSize } from '../../components/ui/ImageUploader/ValueContainer'

const CompanySettingsPage = () => {
  const { companyId } = useParams() as { companyId: string }
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = isAdmin(context.customer)
  const queryClient = useQueryClient()
  const showToast = useToast()
  const { data: dataCompanies, isLoading } = useCompaniesQuery()

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
    watch,
    setValue,
  } = useForm<CompanyGame>({})

  useEffect(() => {
    if (!isLoading && !dataCompanies?.data) {
      return
    }

    const c = (dataCompanies?.data as CompanyGame[]).find(c => c.id === companyId) || ({} as CompanyGame)
    if (!c.domains) {
      c.domains = []
    }

    if (!c.viewer_domains) {
      c.viewer_domains = []
    }
    reset(c)
  }, [isLoading])

  const domains = watch('domains')
  const viewer_domains = watch('viewer_domains')

  const onSubmit: SubmitHandler<CompanyGame> = async data => {
    try {
      await dashboardClient.v1.updateCompany(companyId, data)
      addCompanyToCache(queryClient, {
        ...data,
      })
      showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
      reset(data)
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  }

  const renderGeneral = () => {
    return (
      <DescBlock
        title={i18next.t('company-settings-page.general')}
        description={<Trans i18nKey="company-settings-page.general.desc" />}
      >
        <Block>
          <FieldGroup label={i18next.t('company-settings-page.name')}>
            <Input
              disabled={!canUserEdit}
              {...register('name', {
                required: i18next.t('validation.required'),
                maxLength: 300,
              })}
            />
            <ErrorMessage
              name="name"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('company-settings-page.url')}>
            <Input
              disabled={!canUserEdit}
              placeholder="https://example.com"
              {...register('url', {
                required: i18next.t('validation.required'),
                pattern: { value: urlRegexp, message: i18next.t('validation.invalid_url') },
              })}
            />
            <ErrorMessage
              name="url"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('company-settings-page.logo_url')}>
            <Controller
              name="logo_url"
              control={control}
              render={({ field }) => (
                <ImageUploader
                  imageSize={ImageSize.contain}
                  {...field}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                    'image/gif': [],
                  }}
                  bucket={S3Bucket.dashboard}
                />
              )}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderDomains = () => {
    return (
      <DescBlock
        title={i18next.t('company-settings-page.domains')}
        description={<Trans i18nKey="company-settings-page.domains.desc" components={{ b: <b /> }} />}
      >
        <Block>
          <DomainListEditor
            canUserEdit={canUserEdit}
            domains={domains || []}
            onChange={(value: string[]) => {
              setValue('domains', value, { shouldDirty: true })
            }}
          />
        </Block>
      </DescBlock>
    )
  }

  const renderViewerDomains = () => {
    return (
      <DescBlock
        title={i18next.t('company-settings-page.viewer_domains')}
        description={<Trans i18nKey="company-settings-page.viewer_domains.desc" components={{ b: <b /> }} />}
      >
        <Block>
          <DomainListEditor
            canUserEdit={canUserEdit}
            domains={viewer_domains || []}
            onChange={(value: string[]) => {
              setValue('viewer_domains', value, { shouldDirty: true })
            }}
          />
        </Block>
      </DescBlock>
    )
  }

  const onDiscardClick = () => {
    reset()
  }

  return (
    <div>
      <PageHeader
        variant="bordered"
        sticky={true}
        extra={
          <>
            {isDirty && (
              <Button type="button" onClick={onDiscardClick}>
                {i18next.t('Discard')}
              </Button>
            )}
            <Button variant="primary" type="button" disabled={!isDirty} onClick={handleSubmit(onSubmit)}>
              {i18next.t('Save')}
            </Button>
          </>
        }
      >
        {i18next.t('company-settings-page.title')}
      </PageHeader>

      <div className="flex w-full justify-center">
        <div style={{ maxWidth: '1164px' }} className="mt-5 flex w-full flex-col gap-12">
          {renderGeneral()}
          {renderDomains()}
          {renderViewerDomains()}
        </div>
      </div>
    </div>
  )
}

export default CompanySettingsPage
