import { useTranslation } from 'react-i18next'
import { ModalConfirm, ModalProps } from '@/ui'

interface VerifyUBOModalConfirmDeleteProps extends ModalProps {
  onConfirm: () => void
}

export const VerifyUBOModalConfirmDelete = ({ onConfirm, ...rest }: VerifyUBOModalConfirmDeleteProps) => {
  const { t } = useTranslation()

  const onClickConfirm = () => {
    onConfirm()
    rest.onClose?.()
  }

  return (
    <ModalConfirm
      {...rest}
      message={t('get-started.verify.ubo.delete-modal.title')}
      subMessage={t('get-started.verify.ubo.delete-modal.text')}
      confirmButtonText={t('get-started.verify.ubo.delete-modal.confirm')}
      color="error"
      onConfirm={onClickConfirm}
    />
  )
}
