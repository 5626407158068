import { generatePath, useParams } from 'react-router-dom'
import i18next from 'i18next'
import {
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowSkeleton,
  TableZeroState,
  usePagination,
} from '@/ui'
import { usePlayersQuery } from './api'
import { PlayerTableRow } from './components'
import { GAME_PLAYERS_DETAILS_PATH } from '../../libs/routerPaths'
import { useState } from 'react'
import { useDebounce } from '../../hooks'
import { TableSearch } from '../../components/ui/TableSearch'

const PlayersTable = () => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const { page, query, onPageChange, needShowPagination } = usePagination(20)
  const { data: players = [], isLoading } = usePlayersQuery(companyId, gameId, {
    ...query,
    search_string: debouncedSearch,
  })

  return (
    <div className="flex h-full flex-col">
      <PageHeader>{i18next.t('sidebar.players')}</PageHeader>

      {(players.length || debouncedSearch) && (
        <div className="mb-2.5">
          <TableSearch
            placeholder={i18next.t('search')}
            value={search}
            onChange={e => {
              onPageChange(1)
              setSearch(e.target.value)
            }}
          />
        </div>
      )}

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={5} />
      ) : players.length === 0 ? (
        <TableZeroState
          title={i18next.t(search ? 'players.zero.title.search' : 'players.zero.title')}
          message={i18next.t(search ? 'players.zero.message.search' : 'players.zero.message')}
        />
      ) : (
        <>
          <Table>
            <TableRow variant="header">
              <TableCell width="200%">{i18next.t('players.name')}</TableCell>
              <TableCell width="100%">{i18next.t('players.country')}</TableCell>
              <TableCell width="50%">{i18next.t('players.ext_id')}</TableCell>
              <TableCell width="50%">{i18next.t('players.created_at')}</TableCell>
              <TableCell width="30%">{i18next.t('players.banned')}</TableCell>
            </TableRow>

            {players.map(player => (
              <PlayerTableRow
                key={`player-table-row-${player.id}`}
                to={generatePath(GAME_PLAYERS_DETAILS_PATH, { companyId, gameId, userId: player.id })}
                user={player}
              />
            ))}
          </Table>

          {needShowPagination(isLoading, players) && (
            <Pagination
              hasNext={players.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={players.length}
            />
          )}
        </>
      )}
    </div>
  )
}

export default PlayersTable
