import { CheckoutUser } from '@/api/dashboard'
import { formatNumber } from '@/util'
import { RewardPointOutline } from '@/icons'

export default function RPBalanceView({ user }: { user: CheckoutUser }) {
  return (
    <div
      style={{
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);',
        background: 'linear-gradient(69deg, #FFF 0%, #F1F5F9 100%)',
      }}
      className="relative inline-flex h-[124px] w-[218px] flex-col truncate rounded-xl border border-border-primary p-18"
    >
      <span className="w-full truncate font-semibold text-text-secondary">{user.email}</span>
      <span className="w-full truncate font-semibold text-text-secondary">{user.phone}</span>
      <div className="mt-auto flex items-center gap-3  whitespace-nowrap font-semibold text-text-tertiary">
        <div className="flex h-[25px] min-w-[35px] items-center justify-center rounded border border-border-primary text-text-secondary">
          <RewardPointOutline />
        </div>
        <div className="w-full truncate">{formatNumber(user.balance_rp || 0)}</div>
      </div>
    </div>
  )
}
