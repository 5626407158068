import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DailyRewardMode, DailyRewardRead } from '@/api/dashboard'
import { SettingsSwitch } from '@/icons'
import { Button, FieldGroup, TooltipAlertCircle } from '@/ui'
import { EngagementSummaryCard } from '@/layouts/engagement/component'
import { DailyRewardsSettingsForm } from '@/layouts/engagement/widgets'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'

interface DailyRewardsSettingsProps {
  interactive?: boolean
  settings: DailyRewardRead
}

export const DailyRewardsSettings = ({ interactive = false, settings }: DailyRewardsSettingsProps) => {
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState<boolean>(false)

  return (
    <div>
      {interactive && (
        <DrawerSidebar open={isEdit} width="720px" position="right">
          <DailyRewardsSettingsForm uid={settings.id} settings={settings} onClose={() => setIsEdit(false)} />
        </DrawerSidebar>
      )}

      <EngagementSummaryCard
        title={{ text: t('daily-rewards.summary.title') }}
        action={
          interactive && (
            <Button onClick={() => setIsEdit(true)}>
              <div className="inline-flex items-center gap-2 text-text-primary">
                <SettingsSwitch />
                <span>{t('daily-rewards.summary.edit')}</span>
              </div>
            </Button>
          )
        }
        fields={[
          {
            title: { text: t('daily-rewards.summary.name') },
            content: <FieldGroup size="sm">{settings.name}</FieldGroup>,
          },
          {
            title: { text: t('daily-rewards.summary.description') },
            content: <FieldGroup size="sm">{settings.description}</FieldGroup>,
          },
        ]}
        settings={[
          {
            name: t('daily-rewards.summary.mode'),
            value: (
              <div className="inline-flex justify-center gap-1.5">
                {settings.mode === DailyRewardMode.Soft && (
                  <>
                    <TooltipAlertCircle message={t('daily-rewards.summary.mode.soft.tooltip')} placement="top" />
                    <span>{t('daily-rewards.summary.mode.soft')}</span>
                  </>
                )}
                {settings.mode === DailyRewardMode.Hard && (
                  <>
                    <TooltipAlertCircle message={t('daily-rewards.summary.mode.hard.tooltip')} placement="top" />
                    <span>{t('daily-rewards.summary.mode.hard')}</span>
                  </>
                )}
              </div>
            ),
          },
          {
            name: t('daily-rewards.summary.repeatable'),
            value: settings.repeatable
              ? t('daily-rewards.summary.repeatable.every-period')
              : t('daily-rewards.summary.repeatable.one-time'),
          },
        ]}
      />
    </div>
  )
}
