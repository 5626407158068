import { useTranslation } from 'react-i18next'
import { FormSection } from '../../components'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '@/api/useGameItems'
import { useParams } from 'react-router-dom'
import { ItemRead, ItemType, ResourceState } from '@/api/dashboard'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { Button, ButtonIcon, FieldValidationMessage, ModalProps, TableZeroState, useModal } from '@/ui'
import { SelectSkuModal } from '@/layouts/engagement/component/SelectSkuModal'
import { Plus, Trash } from '@/icons'
import i18next from 'i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { SkuFormData } from '@/layouts/game-items/widgets/SkuForm/SkuForm'
import { ErrorMessage } from '@hookform/error-message'

export const SkuFormBundleItems = (props: {
  children?: React.ReactNode
  onChange: (value: ItemRead[]) => void
  value: ItemRead[]
}) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: items = [], isLoading } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    types: [ItemType.Item, ItemType.Currency],
    state: ResourceState.Active,
  })
  const { value, onChange } = props

  const openPicker = useModal<ModalProps>(({ ...rest }) => (
    <SelectSkuModal
      {...rest}
      isLoading={isLoading}
      title={t('game-item-edit-dialog.bundle.add-items')}
      items={items}
      picked={value}
      onSubmit={items => {
        onChange(items)
        rest?.onClose?.()
      }}
    />
  ))

  const renderItem = (item: ItemRead, idx: number) => {
    return (
      <div key={item.id} className="flex h-[62px] items-center gap-3 border-b border-border-secondary">
        <div className="px-4 text-sm font-medium text-text-secondary">{idx + 1}</div>
        <SkuImageName item={item} />
        <ButtonIcon
          variant="tertiary-destructive"
          onClick={() => onChange(value.filter(it => it.id !== item.id))}
          className="ml-auto mr-3"
        >
          <Trash />
        </ButtonIcon>
      </div>
    )
  }

  return (
    <FormSection
      label={t('sku.item.contain-items')}
      action={
        value.length > 0 && (
          <Button
            variant="text-brand"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              openPicker()
            }}
          >
            {t('game-item-edit-dialog.bundle.add-items')}
          </Button>
        )
      }
    >
      {value.length == 0 ? (
        <TableZeroState
          className="h-[195px]"
          title={t('game-item-edit-dialog.bundle.no-items')}
          buttons={
            <Button
              variant="primary"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                openPicker()
              }}
            >
              <Plus size={14} />
              <span>{i18next.t('game-item-edit-dialog.bundle.add-items')}</span>
            </Button>
          }
        />
      ) : (
        value.map((it, idx) => renderItem(it, idx))
      )}
      {props.children}
    </FormSection>
  )
}

export const SkuFormBundleForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<SkuFormData>()
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name="nested_items_read"
      rules={{
        required: t('validation.required'),
      }}
      render={({ field }) => (
        <SkuFormBundleItems value={field.value || []} onChange={items => field.onChange(items.length ? items : null)}>
          <ErrorMessage
            name="nested_items_read"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </SkuFormBundleItems>
      )}
    />
  )
}
