import i18next from 'i18next'
import { PeriodType } from '../api/dashboard'
import { CSSProperties, useState } from 'react'
import { FieldGroup, Input } from '@/ui'

export const getSecondsByType = (type: PeriodType, v: number) => {
  if (!v) {
    return 0
  }

  switch (type) {
    case PeriodType.Day:
      return v * 60 * 60 * 24
    case PeriodType.Hour:
      return v * 60 * 60
    case PeriodType.Minute:
      return v * 60
    case PeriodType.Second:
      return v
  }
  return 0
}

export default function DurationSelectorDHMS(props: {
  duration: number
  onChange: (value: number) => void
  margin?: boolean
  label?: string
  caption?: string
  size?: 'small' | 'middle'
  style?: CSSProperties
}) {
  const [empty, setEmpty] = useState({
    days: false,
    hours: false,
    minutes: false,
    seconds: false,
  })

  let days = Math.floor(props.duration / (60 * 60 * 24))
  let hours = Math.floor((props.duration % (60 * 60 * 24)) / 60 / 60)
  let minutes = Math.floor((props.duration % (60 * 60)) / 60)
  let seconds = props.duration % 60

  const renderLeftLabel = (lbl: string) => {
    return <div className="flex h-full items-center border-r border-border-secondary pr-3"> {lbl} </div>
  }

  return (
    <div style={props.style}>
      <FieldGroup label={props.label} caption={props.caption}>
        <div className="flex justify-around gap-3">
          <Input
            extraLeft={renderLeftLabel(props.size == 'small' ? 'D' : i18next.t('days'))}
            type={'number'}
            value={empty.days ? '' : days}
            onKeyDown={e => {
              if (e.key == '-' || e.key == '+') {
                e.preventDefault()
              }
            }}
            onChange={e => {
              if (!e.target.value) {
                setEmpty({ ...empty, days: true })
                return
              }
              setEmpty({ ...empty, days: false })
              let v = parseInt(e.target.value)
              if (v >= 0) {
                props.onChange(
                  getSecondsByType(PeriodType.Day, v) +
                    getSecondsByType(PeriodType.Hour, hours) +
                    getSecondsByType(PeriodType.Minute, minutes) +
                    seconds,
                )
              }
            }}
          />

          <Input
            extraLeft={renderLeftLabel(props.size == 'small' ? 'H' : i18next.t('hours'))}
            type={'number'}
            value={empty.hours ? '' : hours}
            onKeyDown={e => {
              if (e.key == '-' || e.key == '+') {
                e.preventDefault()
              }
            }}
            onChange={e => {
              if (!e.target.value) {
                setEmpty({ ...empty, hours: true })
                return
              }
              let v = parseInt(e.target.value)
              setEmpty({ ...empty, hours: false })
              if (v >= 0) {
                props.onChange(
                  getSecondsByType(PeriodType.Day, days) +
                    getSecondsByType(PeriodType.Hour, v) +
                    getSecondsByType(PeriodType.Minute, minutes) +
                    seconds,
                )
              }
            }}
          />

          <Input
            extraLeft={renderLeftLabel(props.size == 'small' ? 'M' : i18next.t('minutes'))}
            type={'number'}
            value={empty.minutes ? '' : minutes}
            onKeyDown={e => {
              if (e.key == '-' || e.key == '+') {
                e.preventDefault()
              }
            }}
            onChange={e => {
              if (!e.target.value) {
                setEmpty({ ...empty, minutes: true })
                return
              }
              let v = parseInt(e.target.value)
              setEmpty({ ...empty, minutes: false })
              if (v >= 0) {
                props.onChange(
                  getSecondsByType(PeriodType.Day, days) +
                    getSecondsByType(PeriodType.Hour, hours) +
                    getSecondsByType(PeriodType.Minute, v) +
                    seconds,
                )
              }
            }}
          />

          <Input
            extraLeft={renderLeftLabel(props.size == 'small' ? 'S' : i18next.t('seconds'))}
            onKeyDown={e => {
              if (e.key == '-' || e.key == '+') {
                e.preventDefault()
              }
            }}
            type={'number'}
            value={empty.seconds ? '' : seconds}
            onChange={e => {
              if (!e.target.value) {
                setEmpty({ ...empty, seconds: true })
                return
              }
              let v = parseInt(e.target.value)
              setEmpty({ ...empty, seconds: false })
              if (v >= 0) {
                props.onChange(
                  getSecondsByType(PeriodType.Day, days) +
                    getSecondsByType(PeriodType.Hour, hours) +
                    getSecondsByType(PeriodType.Minute, minutes) +
                    v,
                )
              }
            }}
          />
        </div>
      </FieldGroup>
    </div>
  )
}
