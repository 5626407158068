import { forwardRef } from 'react'
import { InputSearch, InputSearchProps } from '@/components/ui'

export type MenuSearchProps = InputSearchProps

export const MenuSearch = forwardRef<HTMLInputElement, MenuSearchProps>(function ({ ...rest }, ref) {
  return (
    <div className="relative mb-2.5 w-full border-b border-border-secondary px-3 pb-3">
      <InputSearch {...rest} ref={ref} aria-keyshortcuts="alt+s" autoFocus />
    </div>
  )
})
