import { MAIN_MARGIN } from '../../Settings'
import { Box, Button, Chip, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import i18next from 'i18next'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import CenteredProgress from '../../components/CenteredProgress'
import PayMethodView from './components/PayMethodView'
import { PaymentMethod, PaymentMethodFee } from '../../api/dashboard'
import { dashboardClient } from '../../api'
import { formatMoney, uuid4 } from '../../util'
import { AuthContext, IAuthContext } from '../../Context'
import { isAdmin } from '../../security'
import { SelectRegion } from './components/SelectRegion'
import { COUNTRY_BY_REGIONS } from './components/data'
import { KeyValue } from '../../types'
import { ConfirmDialog, PageHeader, TableZeroState, Toggle } from '@/ui'
import styled from '@emotion/styled'
import { PAYPAL_HACK } from './paypal'
import { usePaymentMethods } from '../../api/usePaymentMethods'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { getCountries } from '@/translates'
import { ChevronDown, ChevronRight } from '@/icons'

const MAX_COUNTRIES = 18

const HIDDEN_METHODS = ['paypal']

const ALWAYS_ENABLED = [
  'apple_checkout',
  'google_checkout',
  'paypal',
  'checkout',
  'PayPal : micro-pricing',
  'PayPal : regular',
]

const UponRequestText = styled.div`
  text-transform: uppercase;
  color: ${p => p.theme.palette.text.disabled};
  font-size: 12px;
`

export default function PaymentSettingsTable() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const context = useContext(AuthContext) as IAuthContext
  const theme = useTheme()
  const [methods, setMethods] = useState<PaymentMethod[]>([])

  const [expandedIds, setExpandedIds] = useState<string[]>([])

  const [disablingPaymentMethod, setDisablingPaymentMethod] = useState<PaymentMethod | null>(null)
  const [enablingPaymentMethod, setEnablingPaymentMethod] = useState<PaymentMethod | null>(null)
  const [regionFilterValue, setRegionFilterValue] = useState<string | null>(null)
  const { data, error, isLoading, refetch } = usePaymentMethods(companyId, gameId)
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)
  const countries = getCountries()

  const rowHeight = theme.spacing(7.75)

  useEffect(() => {
    if (data && !isLoading) {
      const filtered_data = data.filter(it => {
        if (it.dashboard_show === false) {
          return false
        }

        if (HIDDEN_METHODS.includes(it.name)) {
          return false
        }

        return true
      })

      setMethods(
        [...filtered_data, ...PAYPAL_HACK].sort((a, b) =>
          a.settings.enabled == b.settings.enabled ? 0 : a.settings.enabled ? -1 : 1,
        ),
      )
    }
  }, [isLoading, data])

  const onPaymentEnableChanged = async (m: PaymentMethod) => {
    if (m.settings.enabled) {
      setDisablingPaymentMethod(m)
    } else {
      setEnablingPaymentMethod(m)
    }
    return false
  }

  const onUpdateConfirm = async () => {
    let m = disablingPaymentMethod || (enablingPaymentMethod as PaymentMethod)
    try {
      await dashboardClient.v1.updatePaymentMethods(m.id, companyId, gameId, {
        enabled: !m.settings.enabled,
      })
      refetch()
    } catch (e) {
      console.log(e)
    } finally {
      setDisablingPaymentMethod(null)
      setEnablingPaymentMethod(null)
    }
  }

  const isVerified = () => {
    return !!gameSettings?.onboarding_progress?.account_verified
  }

  const getFeePrc = (fee: PaymentMethodFee | null | undefined): React.ReactNode => {
    if (!fee || fee.fee_fix < 0) {
      return <></>
    }

    let str = '+' + formatMoney(fee.fee_fix, fee.currency || 'USD')

    if (typeof fee.fee_fix == 'string') {
      str = fee.fee_fix
    }

    return (
      <Chip
        sx={{
          minWidth: theme.spacing(8.25),
          backgroundColor: theme.palette.background.header,
          color: theme.palette.text.input,
          px: theme.spacing(0.2),
        }}
        size="small"
        label={str}
      />
    )
  }

  const getFeeFix = (fee: PaymentMethodFee | null | undefined): React.ReactNode => {
    if (!fee) {
      return <></>
    }

    if (typeof fee.fee_prc == 'string') {
      return fee.fee_prc
    }

    return fee.fee_prc + '%'
  }

  const getCountry = (m: PaymentMethod, showAll = false) => {
    if (!m.supported_countries?.length) {
      return i18next.t('game-settings.ps-table.global')
    }

    let res = []

    for (let i = 0; i < m.supported_countries.length && (showAll || i < MAX_COUNTRIES); i++) {
      res.push(countries[m.supported_countries[i]])
    }

    return (
      <Box width="100%" sx={{ whiteSpace: 'normal' }}>
        {res.join(', ')}
      </Box>
    )
  }

  const hasMoreInfo = (m: PaymentMethod) => {
    return m.fee != undefined && m.fee.length > 1
  }

  const getState = (m: PaymentMethod) => {
    if (ALWAYS_ENABLED.includes(m.name) || ALWAYS_ENABLED.includes(m.id)) {
      return
    }

    if (!isVerified()) {
      return
    }

    if (m.settings.global_enabled === false) {
      return <UponRequestText> {i18next.t('game-settings.ps-table.request')}</UponRequestText>
    }

    return (
      <Box display="flex" justifyContent="flex-end">
        <Toggle
          disabled={!isAdmin(context.customer)}
          checked={m.settings.enabled}
          onChange={() => onPaymentEnableChanged(m)}
        />
      </Box>
    )
  }

  const renderSubCell = (node: React.ReactNode | string) => {
    return (
      <Box height={rowHeight} display="flex" alignItems="center" key={uuid4()} gap={theme.spacing(2)}>
        {node}
      </Box>
    )
  }

  const renderFilterButtons = () => {
    return <SelectRegion value={regionFilterValue} onChange={value => setRegionFilterValue(value as string)} />
  }

  const renderRow = (m: PaymentMethod, idx: number) => {
    if (!hasMoreInfo(m)) {
      return (
        <TableRow key={m.id + idx}>
          <TableCell>
            <PayMethodView paymentMethodName={m.caption || m.name} logoUrl={m.logo_url} />
          </TableCell>

          <TableCell>{getCountry(m)}</TableCell>

          <TableCell align="right">{isVerified() && getFeeFix(m.fee ? m.fee[0] : null)}</TableCell>
          <TableCell align="left">{isVerified() && getFeePrc(m.fee ? m.fee[0] : null)}</TableCell>

          <TableCell align="right">{isVerified() && getState(m)}</TableCell>
        </TableRow>
      )
    }

    const isExpanded = expandedIds.includes(m.id)
    let boxCountries: React.ReactNode[] = []
    let boxFeesFix: React.ReactNode[] = []
    let boxFeesPrc: React.ReactNode[] = []
    let firstBox: string[] = []

    if (isExpanded) {
      m.fee?.forEach(fee => {
        if (fee.country_code && countries[`${fee.country_code}`]) {
          boxCountries.push(countries[`${fee.country_code}`] as string)
        } else {
          boxCountries.push(i18next.t(`${fee.country_code}`))
        }

        boxFeesPrc.push(getFeePrc(fee))
        boxFeesFix.push(getFeeFix(fee))

        let firstCol = (fee as unknown as KeyValue)['first_column'] as string
        if (firstCol) {
          firstBox.push(firstCol)
        }
      })
    }

    let height = isExpanded ? m.fee!.length * parseInt(rowHeight) + 'px' : '0px'

    let sxSubBox = {
      height: height,
      transition: 'height 0.8s',
      overflow: 'hidden',
      marginTop: isExpanded ? '12px' : '',
    }

    return (
      <TableRow
        key={m.id}
        sx={{
          backgroundColor: isExpanded ? theme.palette.background.fgSecondary : '',
          transition: 'background-color 1s',
        }}
      >
        <TableCell>
          <PayMethodView paymentMethodName={m.caption || m.name} logoUrl={m.logo_url} />
          {firstBox.length > 0 ? (
            <div style={sxSubBox}>{firstBox.map(it => renderSubCell(<div className="ml-[42px]">{it}</div>))}</div>
          ) : (
            <div style={sxSubBox} />
          )}
        </TableCell>

        <TableCell>
          <Box height="34px" display="flex" alignItems="center">
            {getCountry(m)}
          </Box>
          <Box style={sxSubBox}>{boxCountries.map(it => renderSubCell(it))}</Box>
        </TableCell>
        {isVerified() ? (
          <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                setExpandedIds(isExpanded ? expandedIds.filter(it => it != m.id) : [...expandedIds, m.id])
              }}
              variant="text"
              size="small"
              disableRipple
              sx={{ gap: theme.spacing(1), width: '156px' }}
            >
              {isExpanded ? <ChevronDown style={{ width: '16px', height: '17px' }} /> : <ChevronRight />}
              {i18next.t(isExpanded ? 'game-settings.ps-table.less-info' : 'game-settings.ps-table.more-info')}
            </Button>
            <div style={sxSubBox}>
              {boxFeesPrc.map((it, idx) => {
                return (
                  <div className="flex justify-center" key={idx}>
                    <div style={{ width: '100px' }} className="flex justify-end pr-6">
                      {renderSubCell(boxFeesFix[idx])}
                    </div>
                    <div style={{ width: '100px' }} className="flex justify-start">
                      {renderSubCell(it)}
                    </div>
                  </div>
                )
              })}
            </div>
          </TableCell>
        ) : (
          <TableCell colSpan={2} sx={{ textAlign: 'center' }} />
        )}
        <TableCell align="right">
          {isVerified() && getState(m)}
          <Box style={sxSubBox} />
        </TableCell>
      </TableRow>
    )
  }

  const renderNotReadyRow = (m: PaymentMethod, idx: number) => {
    return (
      <TableRow
        key={m.id + idx}
        sx={{
          '& .MuiTableCell-root': {
            verticalAlign: 'top',
          },
        }}
      >
        <TableCell>
          <PayMethodView paymentMethodName={m.caption || m.name} logoUrl={m.logo_url} />
        </TableCell>

        <TableCell>{getCountry(m, true)}</TableCell>
        <TableCell align="center" colSpan={2} />
        <TableCell align="right">
          {isVerified() && (
            <UponRequestText style={{ lineHeight: '35px' }}>
              {i18next.t('game-settings.ps-table.request')}
            </UponRequestText>
          )}
        </TableCell>
      </TableRow>
    )
  }

  const filter = (list: PaymentMethod[]) => {
    if (!regionFilterValue) {
      return list
    }

    return list.filter(it => {
      if (regionFilterValue == 'Global') {
        return it.supported_countries.length == 0
      }
      let regionCountries = (COUNTRY_BY_REGIONS as KeyValue)[regionFilterValue] as Set<string>
      if (!regionCountries) {
        return true
      }
      return it.supported_countries.find(c => regionCountries.has(c))
    })
  }

  const readyMethods = useMemo(() => {
    let list = methods.filter(it => it.settings.global_enabled === true)

    let v = ['apple_checkout', 'google_checkout', 'paypal', 'checkout', 'PayPal']
    let viewMethods = list.filter(it => !v.includes(it.name))
    let firstMethods = list.filter(it => v.includes(it.name))

    return filter([...firstMethods, ...viewMethods.sort((a, b) => (a.caption || '').localeCompare(b.caption || ''))])
  }, [gameId, companyId, methods, regionFilterValue])

  const notReadyMethods = useMemo(() => {
    let list = methods.filter(it => it.settings.global_enabled === false)

    return filter(list.sort((a, b) => (a.caption || '').localeCompare(b.caption || '')))
  }, [regionFilterValue, methods])

  if (error) {
    return (
      <TableZeroState
        className="h-[400px]"
        title={i18next.t('service-unavailable')}
        message={i18next.t('SomethingWentWrong')}
      />
    )
  }

  return (
    <div>
      <PageHeader extra={renderFilterButtons()}>{i18next.t('sidebar.payment-methods')}</PageHeader>

      <Table
        aria-label="simple table"
        sx={{
          whiteSpace: 'nowrap',
          marginTop: MAIN_MARGIN,
          borderRadius: theme.spacing(0.5),
          borderCollapse: 'separate',
          borderTop: `1px solid ${theme.palette.grey['100']}`,
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2" fontWeight={600}>
                {i18next.t('game-settings.ps-table.name')}
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '40%' }}>
              <Typography variant="body2" fontWeight={600}>
                {i18next.t('game-settings.ps-table.countries')}
              </Typography>
            </TableCell>
            <TableCell sx={{ fontWight: '600' }} align="right">
              {isVerified() && (
                <Typography variant="body2" fontWeight={600}>
                  {i18next.t('game-settings.ps-table.fee')}
                </Typography>
              )}
            </TableCell>
            <TableCell />
            <TableCell align="right">
              <Typography variant="body2" fontWeight={600}>
                {isVerified() && i18next.t('game-settings.ps-table.on-off')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && readyMethods.map((m, idx) => renderRow(m, idx))}
          {!isLoading && notReadyMethods.map((m, idx) => renderNotReadyRow(m, idx))}
        </TableBody>
      </Table>
      {isLoading && <CenteredProgress sx={{ mt: theme.spacing(10) }} />}

      {disablingPaymentMethod && (
        <ConfirmDialog
          color={'error'}
          cancelButtonText={i18next.t('game-settings.ps-table.confirm.no')}
          confirmButtonText={i18next.t('game-settings.ps-table.confirm.yes-turn-off')}
          subMessage={i18next.t('game-settings.ps-table.confirm-disable.sub-message', {
            name: disablingPaymentMethod.caption,
          })}
          message={i18next.t('game-settings.ps-table.confirm-disable')}
          onCancel={() => setDisablingPaymentMethod(null)}
          onConfirm={onUpdateConfirm}
        />
      )}

      {enablingPaymentMethod && (
        <ConfirmDialog
          color="primary"
          cancelButtonText={i18next.t('game-settings.ps-table.confirm.no')}
          confirmButtonText={i18next.t('game-settings.ps-table.confirm.yes-turn-on')}
          subMessage={i18next.t('game-settings.ps-table.confirm-enable.sub-message', {
            name: enablingPaymentMethod.caption,
          })}
          message={i18next.t('game-settings.ps-table.confirm-enable')}
          onCancel={() => setEnablingPaymentMethod(null)}
          onConfirm={onUpdateConfirm}
        />
      )}
    </div>
  )
}
