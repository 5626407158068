import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditorState, LexicalEditor } from 'lexical'
import { $generateHtmlFromNodes } from '@lexical/html'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin'
import { CodeNode } from '@lexical/code'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
import { cn } from '@/libs'
import { DraggableBlockPlugin } from './plugins/DraggableBlockPlugin'
import { ComponentPickerMenuPlugin } from './plugins/ComponentPickerPlugin'
import { InlineImageNode } from './plugins/ImagePlugin/ImageNode'
import { FloatingLinkEditorPlugin } from './plugins/FloatingLinkEditorPlugin'
import { FloatingToolbarPlugin } from './plugins/FloatingToolbarPlugin'
import { HtmlValuePlugin } from './plugins/HtmlValuePlugin'
import { InlineImagePlugin } from './plugins/ImagePlugin'
import { ToolbarPlugin } from './plugins/ToolbarPlugin'
import { TableCellMenuPlugin, TableCellResizerPlugin, TableContext } from './plugins/TablePlugin'
import { YouTubeNode, YouTubePlugin } from './plugins/YouTubePlugin'
import { urlMatcher } from './libs'
import './lexical.css'

export interface LexicalProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  autofocus?: boolean
  readonly?: boolean
  value: string
  onChange: (value: string) => void
}

const nodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  LinkNode,
  AutoLinkNode,
  HorizontalRuleNode,
  CodeNode,
  QuoteNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  InlineImageNode,
  YouTubeNode,
]

export const lexicalProseClassnames = cn(
  'prose-table:w-full prose-table:!border-collapse',
  '[&_table]:!w-full',
  '[&_td]:!align-top',
  '[&_th]:!align-top',
  '[&_td]:!border [&_td]:!border-border-primary [&_td]:!p-2 [&_td_*]:!m-0 [&_td_img]:!mx-auto',
  '[&_th]:!border [&_th]:!border-border-primary [&_th]:!p-2 [&_th_*]:!m-0 [&_th_img]:!mx-auto',
)

export const Lexical = memo(({ value, onChange, autofocus = false, readonly = false, ...rest }: LexicalProps) => {
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false)

  const initialConfig: InitialConfigType = {
    namespace: 'MyEditor',
    editable: !readonly,
    nodes,
    theme: {
      text: {
        underline: 'underline',
      },
    },
    onError: err => {
      console.error(err)
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 50)
  }, [setMounted])

  const onRef = useCallback(
    (_floatingAnchorElem: HTMLDivElement) => {
      if (_floatingAnchorElem !== null) {
        setFloatingAnchorElem(_floatingAnchorElem)
      }
    },
    [setFloatingAnchorElem],
  )

  const onChangeContent = useCallback(
    (_: EditorState, editor: LexicalEditor) => {
      editor.update(() => {
        const html = $generateHtmlFromNodes(editor)
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        const tables = doc.querySelectorAll('table')

        tables.forEach(table => {
          const wrapper = document.createElement('div')
          table.parentNode?.replaceChild(wrapper, table)
          wrapper.style.overflowX = 'auto'
          wrapper.appendChild(table)
        })

        onChange(doc.body.innerHTML)
      })
    },
    [onChange],
  )

  return (
    <div
      {...rest}
      className={cn(
        'relative w-full shrink-0 overflow-y-auto rounded-md border border-border-primary shadow-xs',
        readonly && 'pointer-events-none',
        rest.className,
      )}
    >
      <LexicalComposer initialConfig={initialConfig}>
        <TableContext>
          {floatingAnchorElem && (
            <>
              <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
              <FloatingToolbarPlugin anchorElem={floatingAnchorElem} setIsLinkEditMode={setIsLinkEditMode} />
              <FloatingLinkEditorPlugin
                anchorElem={floatingAnchorElem}
                isLinkEditMode={isLinkEditMode}
                setIsLinkEditMode={setIsLinkEditMode}
              />
            </>
          )}

          <ToolbarPlugin />

          {mounted && (
            <div className="relative">
              <RichTextPlugin
                contentEditable={
                  <div ref={onRef} className="flex h-full shrink-0">
                    <ContentEditable
                      className={cn('prose size-full max-w-none px-8 py-4 pb-32 outline-none', lexicalProseClassnames)}
                    />
                  </div>
                }
                placeholder={
                  !readonly ? (
                    <div className="prose pointer-events-none absolute left-0 top-0 px-8 py-4 text-text-quarterary">
                      {t('lexical.placeholder')}
                    </div>
                  ) : null
                }
                ErrorBoundary={err => <div>{err.children}</div>}
              />
            </div>
          )}

          <TablePlugin hasCellMerge={true} hasCellBackgroundColor={false} />
          <TableCellResizerPlugin />
          <TableCellMenuPlugin />

          <HtmlValuePlugin value={value} />
          <OnChangePlugin onChange={onChangeContent} />
          <ComponentPickerMenuPlugin />
          <LinkPlugin />
          <ListPlugin />
          <InlineImagePlugin />
          <AutoLinkPlugin matchers={urlMatcher} />
          <YouTubePlugin />
          <MarkdownShortcutPlugin />
          <HistoryPlugin />
          {autofocus && <AutoFocusPlugin />}
        </TableContext>
      </LexicalComposer>
    </div>
  )
})
