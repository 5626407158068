import DashboardCard from '../../../../components/shared/DashboardCard'
import i18next from 'i18next'
import { LoaderContainer } from '../Loader/LoaderContainer'
import Loader from '../Loader/Loader'
import { Fragment, HTMLAttributes, useContext } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { formatMoney } from '../../../../util'
import { useGameItemsQuery } from '../../../../api/useGameItems'
import { AnalyticsDataContext, IAnalyticsDataContext } from '../../Context'
import ZeroState from '../ZeroState/ZeroState'
import { SkuImageName } from '../../../game-items/components/SkuImageName'
import { SKU_ITEMS_DETAILS_PATH } from '../../../../libs/routerPaths'
import { ResourceState } from '../../../../api/dashboard'

import { AuthContext, IAuthContext } from '@/Context'
import { canEdit } from '@/security'

const MoneyText = styled.span`
  font-weight: 400;
  color: #000;
  font-size: 12px;
  margin-left: 12px;
`

const CountText = styled.span`
  font-weight: 400;
  color: ${p => p.theme.palette.text.tertiary};
  font-size: 12px;
`

const Separator = styled.div`
  width: calc(100% - 38px - 12px);
  height: 1px;
  background-color: ${p => p.theme.palette.background.fgskeleton};
  margin-left: auto;
`

interface TopSkuItemsProps extends HTMLAttributes<HTMLDivElement> {}

export default function TopSkuItems(props: TopSkuItemsProps) {
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const authContext = useContext(AuthContext) as IAuthContext

  const loading = !context.data.top_items
  const data = context.data.top_items || []

  const total = data?.length ? data.reduce((acc, item) => acc + (item.values['sales'] as number), 0) : 0

  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    ids: data.map(it => it.category).join(','),
    state: ResourceState.Active,
  })

  const getItemCells = (id: string) => {
    const item = items.find(it => it.id === id)
    if (!item) {
      return <></>
    }

    if (!canEdit(authContext.customer)) {
      return (
        <div className="flex w-full items-center gap-3">
          <SkuImageName item={item} />
        </div>
      )
    }

    return (
      <Link
        className="flex w-full items-center gap-3"
        to={generatePath(SKU_ITEMS_DETAILS_PATH, { companyId, gameId, itemId: item.id || '' })}
      >
        <SkuImageName item={item} />
      </Link>
    )
  }

  return (
    <DashboardCard
      title={i18next.t('dashboard.sales.top-5-items')}
      tooltip={i18next.t('dashboard.sales.top-5-items.tooltip')}
      data-testid="dashboard/chart/top5-sku"
      style={{
        ...props.style,
        gap: '8px',
      }}
    >
      {loading ? (
        <LoaderContainer style={{ height: '460px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div
            data-testid="dashboard/chart/top5-sku/total"
            className="flex h-[52px] items-center text-title-t3 font-semibold text-text-secondary"
          >
            {formatMoney(total)}
          </div>
          {data.length > 0 ? (
            <div style={{ maxHeight: '332px' }}>
              {data.map((item, index) => (
                <Fragment key={item.category}>
                  <div className="flex h-[66px] items-center">
                    <div className="w-full truncate">{getItemCells(item.category)}</div>
                    <div className="text-right">
                      <CountText data-testid={`dashboard/chart/top5-sku/${item.category}/count`}>
                        {item.values['count_sales']}
                      </CountText>
                      <MoneyText data-testid={`dashboard/chart/top5-sku/${item.category}/sales`}>
                        {formatMoney(item.values['sales'])}
                      </MoneyText>
                    </div>
                  </div>
                  {index < data.length - 1 && <Separator />}
                </Fragment>
              ))}
            </div>
          ) : (
            <ZeroState style={{ height: '332px' }} />
          )}
        </>
      )}
    </DashboardCard>
  )
}
