import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { dashboardClient } from '../../../api'
import { WebhookRedacted } from '../../../api/dashboard'
import { Badge, Skeleton } from '@/ui'
import { WebhookBarColumn, WebhookInput } from '../components'
import { LogQuery } from '@/layouts/webhooks/api/types'
import { WebhookStatChart } from '@/layouts/webhooks/widgets/WebhookStatChart'

interface WebhookBarProps {
  webhook: WebhookRedacted
  query: LogQuery
}

export const WebhookBar = ({ webhook, query }: WebhookBarProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const onClickCopySecret = async () => {
    try {
      const { data: revealWebhook } = await dashboardClient.v1.revealWebhookSecret(companyId, gameId, webhook.id)
      await navigator.clipboard.writeText(revealWebhook.secret)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="flex items-center gap-6 rounded-xl border border-border-primary bg-fg-primary px-3 py-2 shadow-xs">
      <WebhookBarColumn label={t('webhook.listen-to')}>
        <Badge variant="gray-secondary" className="text-nowrap">
          {webhook.events.length > 1
            ? t('webhook.event-count.value', { count: webhook.events.length })
            : webhook.events.at(0) || '–'}
        </Badge>
      </WebhookBarColumn>

      <WebhookBarColumn label={t('webhook.secret')} className="w-full max-w-[298px]">
        <WebhookInput
          value={webhook.secret_redacted.replaceAll('.', '﹡')}
          message={t('webhook.sercet.copy-message')}
          onClickCopy={onClickCopySecret}
        />
      </WebhookBarColumn>

      <div className="ml-auto">
        <WebhookStatChart query={query} />
      </div>
    </div>
  )
}

export const WebhookBarSkeleton = () => (
  <div className="flex items-start gap-6 rounded-xl border border-border-primary bg-fg-primary p-6 shadow-xs">
    <WebhookBarColumn label={<Skeleton className="h-[18px]" />}>
      <Skeleton className="h-6 w-[70px]" />
    </WebhookBarColumn>

    <WebhookBarColumn label={<Skeleton className="h-[18px] w-[87px]" />}>
      <Skeleton className="h-6 w-16" />
    </WebhookBarColumn>

    <WebhookBarColumn label={<Skeleton className="h-[18px]" />} className="w-full max-w-[298px]">
      <Skeleton className="h-[34px] w-[272px]" />
    </WebhookBarColumn>

    <WebhookBarColumn label={<Skeleton className="h-[18px]" />} className="w-full max-w-[298px]">
      <Skeleton className="h-[34px] w-[272px]" />
    </WebhookBarColumn>
  </div>
)
