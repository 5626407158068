import ImageCellNoText from '@/components/ImageCellNoText'

interface LoyaltyRankBadgeProps {
  image_url?: string
  rank: string
  points: number
  name: string
}

export const LoyaltyRankBadge = ({ image_url, rank, points, name }: LoyaltyRankBadgeProps) => (
  <div className="inline-flex items-center gap-3">
    <div className="inline-flex h-6 items-center rounded-md bg-fg-primary-alt">
      <ImageCellNoText
        image_url={image_url}
        image_size="1.5rem"
        style={{
          objectPosition: 'center',
          objectFit: 'cover',
        }}
      />
      <div className="flex items-center gap-1.5 px-2 py-1">
        <span className="text-caption-md font-semibold text-text-secondary">{rank}</span>
        <span className="text-caption-md font-medium text-text-tertiary">·</span>
        <span className="text-caption-md font-medium text-text-tertiary">
          {Intl.NumberFormat(navigator.language).format(points)}
        </span>
      </div>
    </div>
    <span className="text-caption-md font-medium text-text-tertiary">{name}</span>
  </div>
)
