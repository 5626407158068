import { Badge } from '@/ui'
import { Check, XClose } from '@/icons'
import { useTranslation } from 'react-i18next'

interface PlayerStatusProps {
  isBanned: boolean
}

export const PlayerStatus = ({ isBanned }: PlayerStatusProps) => {
  const { t } = useTranslation()
  return (
    <Badge variant={isBanned ? 'gray-primary' : 'gray-secondary'} className="whitespace-nowrap">
      <span>{isBanned ? t('players.banned') : t('no')}</span>
      {isBanned ? <XClose size={16} /> : <Check size={16} />}
    </Badge>
  )
}
