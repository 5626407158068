import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import i18next from 'i18next'
import TableBar from '../../components/TableBar'
import CenteredProgress from '../../components/CenteredProgress'
import PageBlock from '../game-items/components/PageBlock'
import UserTransactionsTable from '../transaction/UserTransactionsTable'
import TableProperty, { PropColumn } from '../../components/TableProperty'
import { AttrType, CustomAttribute, PersistentPlayerAttributes } from '../../api/dashboard'
import PlayerCard from './PlayerCard'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../Settings'
import { formatMoney } from '../../util'
import UserCouponsTable from './UserCouponsTable'
import { useFullPlayerDetailQuery, usePlayerDetailQuery } from './api'
import EmptyTableMessage from '../../components/EmptyTableMessage'
import AnimatedDots from '../../components/animated-dots/AnimatedDots'
// import { UserStoreView } from './widgets/UserStoreView'
import OfferTable from '../campaigns/widgets/Offers/OfferTable'
import { Input, Select, Tab, Tabs, ToastSeverity, useToast } from '@/ui'
import { useContext, useEffect, useState } from 'react'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { dashboardClient, getErrorText } from '@/api'
import { EMPTY_VALUE, TokenValue } from '@/layouts/campaigns/expr/types'
import StyledDatePicker from '@/components/StyledDatePicker'
import { KeyValue } from '@/types'
import { AuthContext } from '@/Context'
import { canEdit } from '@/security'
import { Edit03, Save } from '@/icons'
import UserClaimedItemsTable from '@/layouts/player/UserClaimedItemsTable'
import { DateTimeValue } from '@/components/ui/DateTimeValue'

const PADDING = 2.25

enum TabAttrType {
  embedded,
  custom,
  calculated,
}

export default function PlayerDetails() {
  const theme = useTheme()
  const navigate = useNavigate()
  const { companyId, gameId, userId } = useParams() as {
    companyId: string
    gameId: string
    userId: string
  }
  const { data: settings } = useGameSettingsQuery(companyId, gameId)
  const context = useContext(AuthContext)
  const canUserEdit = canEdit(context?.customer)
  const showToast = useToast()

  const { data: user, isLoading, isError } = usePlayerDetailQuery(companyId, gameId, userId)
  const { data: fullUser, isLoading: isFullLoading } = useFullPlayerDetailQuery(companyId, gameId, userId)
  const [attrType, setAttrType] = useState<TabAttrType>(TabAttrType.embedded)
  const [editMode, setEditMode] = useState(false)
  const [customAttr, setCustomAttr] = useState<Record<string, TokenValue>>({})

  useEffect(() => {
    const cattr = (fullUser?.custom_attributes || {}) as Record<string, string | number | undefined | boolean>
    setCustomAttr(cattr)
  }, [fullUser])

  const renderPrevPurchases = () => {
    if (!user) {
      return <></>
    }

    return (
      <PageBlock
        title={i18next.t('players.other-transactions')}
        style={{ overflowX: 'auto', width: '100%' }}
        padding={PADDING}
      >
        <UserTransactionsTable userId={user.id} />
      </PageBlock>
    )
  }

  const renderCoupons = () => {
    if (!user) {
      return <></>
    }

    return (
      <PageBlock className="ph-no-capture" title={i18next.t('players.coupons')} padding={PADDING}>
        <UserCouponsTable userId={user.id} />
      </PageBlock>
    )
  }

  const renderOffers = () => {
    if (!user) {
      return <></>
    }

    return (
      <PageBlock className="ph-no-capture" title={i18next.t('players.offers')} padding={PADDING}>
        <OfferTable userId={user.id} />
      </PageBlock>
    )
  }

  const renderClaimedItems = () => {
    if (!user) {
      return <></>
    }

    return (
      <PageBlock className="ph-no-capture" title={i18next.t('players.claimed-items')} padding={PADDING}>
        <UserClaimedItemsTable userId={user.id} />
      </PageBlock>
    )
  }

  const renderEditor = (type: AttrType, value: TokenValue, onChanged: (value: TokenValue) => void) => {
    switch (type) {
      case AttrType.Boolean:
        return (
          <Select
            value={value == undefined ? EMPTY_VALUE : value == true ? 'True' : 'False'}
            onChange={v => onChanged(v == EMPTY_VALUE ? undefined : v == 'True')}
            options={[
              { children: 'None', value: EMPTY_VALUE },
              { children: 'True', value: 'True' },
              { children: 'False', value: 'False' },
            ]}
          />
        )
      case AttrType.Date:
        return (
          <StyledDatePicker
            value={value ? dayjs((value as number) * 1000).format('YYYY-MM-DD') : ''}
            onChange={(value: string) => {
              onChanged(dayjs(value).valueOf() / 1000)
            }}
          />
        )
      case AttrType.String:
      case AttrType.Number:
      default:
        return (
          <Input
            type={type == AttrType.Number ? 'number' : 'text'}
            value={value as string}
            onChange={e => {
              if (type == AttrType.Number) {
                if (e.target.value) {
                  let v = parseInt(e.target.value)
                  if (e.target.value && isNaN(v)) {
                    return false
                  }
                  onChanged(v)
                } else {
                  onChanged(undefined)
                }
              } else {
                onChanged(e.target.value)
              }
            }}
          />
        )
    }
  }

  const renderValue = (attr: CustomAttribute) => {
    if (!fullUser) {
      return <AnimatedDots />
    }

    let attrValue = (customAttr as KeyValue)[attr.name] as TokenValue
    if (!attr) {
      return 'Attribute not found'
    }

    if (!editMode) {
      if (attr.type == AttrType.Date && attrValue) {
        return dayjs((attrValue as number) * 1000).format(DATE_FORMAT)
      }

      if (attr.type == AttrType.Boolean) {
        return attrValue == undefined ? '' : attrValue ? 'True' : 'False'
      }

      return attrValue || ''
    }

    return renderEditor(attr.type, attrValue, value => {
      setCustomAttr({
        ...customAttr,
        [attr.name]: value,
      } as Record<string, string>)
    })
  }

  const onDiscardCustomAttrClick = () => {
    const cattr = (fullUser?.custom_attributes || {}) as Record<string, string | number | undefined | boolean>
    setCustomAttr(cattr)
    setEditMode(false)
  }

  useEffect(() => {
    onDiscardCustomAttrClick()
  }, [attrType])

  const onSaveCustomAttrClick = async () => {
    try {
      await dashboardClient.v1.updateUser(companyId, gameId, userId, {
        custom_attributes: customAttr,
      })
      if (fullUser) {
        fullUser.custom_attributes = customAttr
      }
      setEditMode(false)
      showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  }

  const renderEmbeddedAttributes = () => {
    let attr = user?.attributes || ({} as PersistentPlayerAttributes)

    let tableProps = [
      // {
      //   label: i18next.t('players.attr.saved_ach'),
      //   value: attr.saved_ach == undefined ? '' : attr.saved_ach + '',
      // },
      // {
      //   label: i18next.t('players.attr.saved_card'),
      //   value: attr.saved_card == undefined ? '' : attr.saved_card + '',
      // },

      {
        label: i18next.t('players.attr.soft_currency_amount'),
        value: attr.soft_currency_amount || 'N/A',
      },
      {
        label: i18next.t('players.attr.hard_currency_amount'),
        value: attr.hard_currency_amount || 'N/A',
      },

      { label: i18next.t('players.attr.level'), value: attr.level },
      { label: i18next.t('players.attr.appsflyer_id'), value: attr.appsflyer_id },

      { label: i18next.t('players.attr.appsflyer_app_id'), value: attr.appsflyer_app_id },
      { label: i18next.t(''), value: '' },
    ] as PropColumn[]

    return <TableProperty rows={tableProps} />
  }

  const renderDateValue = (value: number | undefined, isValueLoading: boolean) => {
    if (isValueLoading) {
      return <AnimatedDots />
    }

    if (value) {
      return (
        <div className="flex gap-1">
          <DateTimeValue value={value} timeClassName="text-text-secondary" />
        </div>
      )
    }
  }

  const renderCalcAttributes = () => {
    let attr = fullUser?.calc_attributes

    let tableProps = [
      {
        label: i18next.t('players.calc-attr.purchases_count'),
        value: attr ? attr.purchases_count : <AnimatedDots />,
      },
      {
        label: i18next.t('players.calc-attr.active_offers_count'),
        value: attr ? attr.active_offers_count : <AnimatedDots />,
      },

      {
        label: i18next.t('players.calc-attr.last_email_date'),
        value: renderDateValue(attr?.last_email_date, isFullLoading),
      },
      {
        label: i18next.t('players.calc-attr.last_app_popup_date'),
        value: renderDateValue(attr?.last_app_popup_date, isFullLoading),
      },
      {
        label: i18next.t('players.calc-attr.last_app_push_date'),
        value: renderDateValue(attr?.last_app_push_date, isFullLoading),
      },
      {
        label: i18next.t('players.calc-attr.last_hub_push_date'),
        value: renderDateValue(attr?.last_app_push_date, isFullLoading),
      },

      {
        label: i18next.t('players.calc-attr.last_hub_popup_date'),
        value: renderDateValue(attr?.last_hub_popup_date, isFullLoading),
      },
      {
        label: i18next.t('players.calc-attr.last_communication_date'),
        value: renderDateValue(attr?.last_communication_date, isFullLoading),
      },
      {
        label: i18next.t('players.calc-attr.last_hub_visit'),
        value: renderDateValue(attr?.last_hub_visit, isFullLoading),
      },
      {
        label: i18next.t('players.calc-attr.game_hub_first_login'),
        value: renderDateValue(attr?.game_hub_first_login, isFullLoading),
      },
      {
        label: i18next.t('players.calc-attr.ltv'),
        value: attr ? formatMoney(attr.ltv) : <AnimatedDots />,
      },
    ] as PropColumn[]

    return <TableProperty rows={tableProps} />
  }

  const renderCustomAttributes = () => {
    let allAttr = settings?.player_attributes || []

    let tableProps = allAttr.map(attr => ({
      label: attr.name,
      value: renderValue(attr),
    }))

    return <TableProperty rows={tableProps} />
  }

  const renderAttrTabs = () => {
    return (
      <PageBlock
        className="ph-no-capture"
        title={i18next.t('players.attributes')}
        btnIcon={editMode ? <Save /> : <Edit03 />}
        style={{ border: `1px solid ${theme.palette.grey.borderPrimary}` }}
        btnText={editMode ? i18next.t('Save') : i18next.t('Edit')}
        cancelClick={editMode ? onDiscardCustomAttrClick : undefined}
        cancelBtnText={i18next.t('Discard')}
        editClick={
          canUserEdit && attrType == TabAttrType.custom
            ? () => {
                if (editMode) {
                  onSaveCustomAttrClick()
                } else {
                  setEditMode(true)
                }
              }
            : undefined
        }
        padding={PADDING}
      >
        <Tabs>
          <Tab
            data-testid="tab-item-base"
            isActive={attrType == TabAttrType.embedded}
            onClick={() => setAttrType(TabAttrType.embedded)}
          >
            {i18next.t('player-details.attr-tab-type.embedded')}
          </Tab>
          {settings?.player_attributes && settings.player_attributes.length > 0 && (
            <Tab
              data-testid="tab-item-store"
              isActive={attrType == TabAttrType.custom}
              onClick={() => setAttrType(TabAttrType.custom)}
            >
              {i18next.t('player-details.attr-tab-type.custom')}
            </Tab>
          )}
          <Tab
            data-testid="tab-item-store"
            isActive={attrType == TabAttrType.calculated}
            onClick={() => setAttrType(TabAttrType.calculated)}
          >
            {i18next.t('player-details.attr-tab-type.calculated')}
          </Tab>
        </Tabs>
        {attrType == TabAttrType.embedded && renderEmbeddedAttributes()}
        {attrType == TabAttrType.custom && renderCustomAttributes()}
        {attrType == TabAttrType.calculated && renderCalcAttributes()}
      </PageBlock>
    )
  }

  if (isError) {
    return (
      <EmptyTableMessage
        title={i18next.t('service-unavailable')}
        subtitle={i18next.t('SomethingWentWrong')}
        sx={{
          height: '100%',
          border: 'none',
          backgroundColor: theme.palette.background.content,
        }}
      />
    )
  }

  return (
    <div>
      <TableBar
        title={i18next.t('players-details.title')}
        backButtonText={i18next.t('players-details.back')}
        onBackClick={() => navigate(-1)}
        sticky
      />

      {isLoading || !user ? (
        <CenteredProgress />
      ) : (
        <div className="flex flex-col gap-5">
          <>
            <PlayerCard user={fullUser || user} calc_attributes={fullUser?.calc_attributes} />
            {renderAttrTabs()}
            {renderPrevPurchases()}
            {renderCoupons()}
            {renderOffers()}
            {renderClaimedItems()}
            {/*<UserStoreView userId={user.id} />*/}
          </>

          <div className="h-[100px]" />
        </div>
      )}
    </div>
  )
}
