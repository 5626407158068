import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import { cn } from '@/libs'
import { getErrorText } from '@/api'
import { DayRewardRead, Item } from '@/api/dashboard'
import { useGameItems } from '@/api/useGameItems'
import { PlusContained } from '@/icons'
import { Badge, ToastSeverity, useToast } from '@/ui'
import { DailyRewardsDayFrame, EngagementAddButton } from '@/layouts/engagement/component'
import { SkuItemsField } from '@/layouts/engagement/widgets'
import { useDailyRewardsUpdate } from '@/layouts/engagement/hooks'

type Reward = Omit<DayRewardRead, 'item_ids'>

interface RewardsSettings {
  daily: Reward[]
  extra: Item[]
}

export interface DailyRewardsDaysFormInput {
  settingsID: string
  program: RewardsSettings
}

interface DailyRewardsDaysFormProps {
  id: string
  preview: boolean
  togglePreview: () => void
}

export const DailyRewardsDaysForm = ({ id, preview, togglePreview }: DailyRewardsDaysFormProps) => {
  const { t } = useTranslation()
  const { isLoading: isItemsLoading, items } = useGameItems()
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    trigger,
    clearErrors,
    formState: { isSubmitting, isSubmitted },
  } = useFormContext<DailyRewardsDaysFormInput>()
  const {
    settingsID,
    program: { daily, extra },
  } = getValues()

  const showToast = useToast()
  const { mutateAsync: updateDailyRewardsAsync } = useDailyRewardsUpdate(settingsID)

  const onSubmit = handleSubmit(async data => {
    try {
      await updateDailyRewardsAsync({
        rewards: {
          daily_rewards: data.program.daily.map(reward => ({
            day_number: reward.day_number,
            item_ids: reward.items.map(item => item.id),
          })),
          extra_reward_item_ids: data.program.extra.map(item => item.id),
        },
      })
      showToast({ message: t('saved'), severity: ToastSeverity.success })
      togglePreview()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  })

  const removeDay = async (index: number) => {
    setValue(
      'program.daily',
      daily.filter((_, i) => i !== index).map((reward, i) => ({ ...reward, day_number: i + 1 })),
      { shouldDirty: true },
    )
    if (isSubmitted) {
      clearErrors()
      await trigger('program.daily')
    }
  }

  const addReward = async (day: number) => {
    const reward: Reward = { day_number: day, items: [] }
    setValue(
      'program.daily',
      [
        ...daily.map(reward => (reward.day_number >= day ? { ...reward, day_number: reward.day_number + 1 } : reward)),
        reward,
      ].sort((a, b) => a.day_number - b.day_number),
      { shouldDirty: true },
    )
    if (isSubmitted) {
      clearErrors()
      await trigger('program.daily')
    }
  }

  return (
    <form id={id} onSubmit={onSubmit}>
      <div className="flex flex-col gap-18">
        {daily.map((reward, index) => (
          <div key={`${index}-${reward.items.map(item => item.id).join('-')}`} className="flex flex-col gap-18">
            <div
              className={cn(
                'inline-flex min-h-[5.5rem] w-full gap-1.5 border p-1.5',
                preview ? 'border-transparent' : 'rounded-2xl border-border-primary bg-fg-secondary',
              )}
            >
              <DailyRewardsDayFrame
                disabled={isSubmitting}
                day={reward.day_number}
                extra={
                  index === daily.length - 1 ? (
                    <Badge>{t('daily-rewards.reward.day.last')}</Badge>
                  ) : (
                    <span className="text-caption-md text-text-quarterary-hover">{t('intl.day_one')}</span>
                  )
                }
                state={preview ? 'preview' : 'editing'}
                onDelete={async () => await removeDay(index)}
              />
              <div className="min-w-0 grow">
                <SkuItemsField
                  {...register(`program.daily.${index}.items`, {
                    required: t('daily-rewards.reward.item.required'),
                  })}
                  preview={preview}
                  loading={isItemsLoading}
                  delText={t('daily-rewards.reward.item.remove')}
                  addText={t('daily-rewards.reward.item.add')}
                  pickerTitle={t('daily-rewards.reward.item.picker.title')}
                  addLimit={1}
                  items={items}
                />
              </div>
            </div>
            {!preview && (
              <EngagementAddButton
                size="lg"
                disabled={isSubmitting}
                icon={PlusContained}
                onClick={() => addReward(reward.day_number + 1)}
              >
                {t('daily-rewards.reward.day.add')}
              </EngagementAddButton>
            )}
          </div>
        ))}
        {!preview && daily.length === 0 && (
          <EngagementAddButton
            size="lg"
            disabled={isSubmitting}
            icon={PlusContained}
            onClick={async () => await addReward(1)}
          >
            {t('daily-rewards.reward.day.add')}
          </EngagementAddButton>
        )}
        {(!preview || extra.length !== 0) && (
          <div
            className={cn(
              'inline-flex min-h-[5.5rem] w-full gap-1.5 rounded-2xl border bg-fg-brand-tertiary p-1.5',
              preview ? 'border-transparent' : 'border-border-primary',
            )}
          >
            <DailyRewardsDayFrame
              variant="primary"
              disabled={isSubmitting}
              day={daily.length}
              extra={<Badge variant="brand-blue-secondary">{t('daily-rewards.reward.bonus')}</Badge>}
            />
            <div className="min-w-0 grow">
              <SkuItemsField
                {...register(`program.extra`)}
                preview={preview}
                loading={isItemsLoading}
                delText={t('daily-rewards.reward.item.remove')}
                addText={t('daily-rewards.reward.item.add')}
                pickerTitle={t('daily-rewards.reward.item.picker.title')}
                addLimit={1}
                items={items}
              />
            </div>
          </div>
        )}
      </div>
    </form>
  )
}
