import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoyaltySettings as Settings } from '@/api/dashboard'
import { LoyaltySettingsForm } from '@/layouts/engagement/widgets'
import { Button } from '@/ui'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'
import { SettingsSwitch } from '@/icons'
import { EngagementSummaryCard } from '@/layouts/engagement/component'

interface LoyaltySettingsProps {
  interactive?: boolean
  settings: Settings
}

export const LoyaltySettings = ({ interactive = false, settings }: LoyaltySettingsProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <div>
      {interactive && (
        <DrawerSidebar open={isEdit} width="720px" position="right">
          <LoyaltySettingsForm uid={settings.id} settings={settings} onClose={() => setIsEdit(false)} />
        </DrawerSidebar>
      )}

      <EngagementSummaryCard
        title={{ text: t('loyalty.summary.title') }}
        action={
          interactive && (
            <Button onClick={() => setIsEdit(true)}>
              <div className="inline-flex items-center gap-2 text-text-primary">
                <SettingsSwitch />
                <span>{t('loyalty.summary.edit')}</span>
              </div>
            </Button>
          )
        }
        fields={[
          {
            title: { text: t('loyalty.summary.name') },
            content: settings.name,
          },
          {
            title: { text: t('loyalty.summary.description') },
            content: settings.description,
          },
        ]}
        settings={[
          {
            name: t('loyalty.summary.period'),
            value: `${settings.period} ${t('intl.day', { count: settings.period })}`,
          },
          {
            name: t('loyalty.summary.repeatable'),
            value: settings.repeatable
              ? t('loyalty.summary.repeatable.every-period')
              : t('loyalty.summary.repeatable.one-time'),
          },
        ]}
      />
    </div>
  )
}
