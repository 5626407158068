import { Floating, MenuItem, Menu_v2, ToolbarFilterItem, ToolbarFilterMenuProps, ToolbarFilterProps } from '@/ui'

const LabelFilterMenu = ({ items, onChange, ...rest }: ToolbarFilterMenuProps) => {
  const onClickMenuItem = (item: ToolbarFilterItem) => {
    onChange(item.value)
    rest.onClose?.()
  }

  return (
    <Menu_v2>
      {items.map(item => (
        <MenuItem key={`menu-item-${item.value}`} onClick={() => onClickMenuItem(item)}>
          {item.label}
        </MenuItem>
      ))}
    </Menu_v2>
  )
}

interface LabelFilterProps extends Omit<ToolbarFilterProps, 'label'> {
  label?: string
}

export const LabelFilter = ({ items, value, onChange }: LabelFilterProps) => {
  return (
    <Floating menu={<LabelFilterMenu items={items} onChange={onChange} />} placement="bottom-start">
      <span className="cursor-pointer border-b border-dashed border-text-secondary">
        {items.find(item => item.value === value)?.label}
      </span>
    </Floating>
  )
}

export default LabelFilter
