import { Link, useNavigate, useParams } from 'react-router-dom'
import i18next from 'i18next'
import {
  Button,
  Page,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableZeroState,
  generatePathWithPage,
  usePagination,
} from '@/ui'
import { useCouponQuery } from './api'
import { PlayerTableRowSkeleton } from '../player/components'
import { CouponRead, CouponType } from '../../api/dashboard'
import CouponBaseSettings from './components/CouponBaseSettings'
import { canEdit } from '../../security'
import { SKU_CODES_DETAILS_PATH, SKU_CODES_PATH } from '../../libs/routerPaths'

import { Plus } from '@/icons'
import { useContext } from 'react'
import { AuthContext, IAuthContext } from '@/Context'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'

export default function CouponTable() {
  const navigate = useNavigate()
  const { companyId, gameId, codeId } = useParams() as { companyId: string; gameId: string; codeId: string }
  const { page, query, onPageChange } = usePagination(10)
  const { data: coupons = [], isLoading } = useCouponQuery(companyId, gameId, query)

  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)

  const getBenefits = (it: CouponRead) => {
    switch (it.type) {
      case CouponType.FreeItem:
        return it.item?.name
      case CouponType.Discount:
        return it.discount_percent + '%'
      case CouponType.Bonus:
        return it.bonus_percent + '%'
    }
    return ''
  }

  const onCloseSettings = () => {
    navigate(generatePathWithPage(SKU_CODES_PATH, { companyId, gameId }, page))
  }

  return (
    <Page>
      <PageHeader
        extra={
          canUserEdit &&
          !!coupons.length && (
            <Button
              variant="primary"
              type="button"
              onClick={() =>
                navigate(generatePathWithPage(SKU_CODES_DETAILS_PATH, { companyId, gameId, codeId: 'new' }, page))
              }
            >
              {i18next.t('coupon.add')}
            </Button>
          )
        }
      >
        {i18next.t('coupon.codes')}
      </PageHeader>

      <DrawerSidebar open={!!codeId} width="864px" position="right">
        <CouponBaseSettings
          onClose={onCloseSettings}
          coupon={coupons.find(it => it.id == codeId) || ({} as CouponRead)}
        />
      </DrawerSidebar>

      {isLoading ? (
        new Array(query.limit).fill('').map((_, index) => <PlayerTableRowSkeleton key={`skeleton-${index}`} />)
      ) : coupons.length === 0 ? (
        <TableZeroState
          title={i18next.t('coupon.zero.title')}
          message={i18next.t('coupon.zero.message')}
          buttons={
            canUserEdit && (
              <Link to={generatePathWithPage(SKU_CODES_DETAILS_PATH, { companyId, gameId, codeId: 'new' }, page)}>
                <Button variant="primary">
                  <Plus size={14} />
                  <span>{i18next.t('coupon.zero.button-add')}</span>
                </Button>
              </Link>
            )
          }
        />
      ) : (
        <>
          <Table>
            <TableRow variant="header">
              <TableCell>{i18next.t('coupon.name')}</TableCell>
              <TableCell>{i18next.t('coupon.code')}</TableCell>
              <TableCell>{i18next.t('coupon.type')}</TableCell>
              <TableCell>{i18next.t('coupon.benefits')}</TableCell>
            </TableRow>
            {coupons.map((it, idx) => (
              <TableRow
                key={idx}
                to={
                  canUserEdit
                    ? generatePathWithPage(SKU_CODES_DETAILS_PATH, { companyId, gameId, codeId: it.id }, page)
                    : undefined
                }
              >
                <TableCell>{it.name}</TableCell>
                <TableCell>{it.code}</TableCell>
                <TableCell>{i18next.t(`coupon.type.${it.type}`)}</TableCell>
                <TableCell>{getBenefits(it)}</TableCell>
              </TableRow>
            ))}
          </Table>
          {!isLoading && (
            <Pagination
              hasNext={coupons.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={coupons.length}
            />
          )}
        </>
      )}
    </Page>
  )
}
