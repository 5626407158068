import { useEffect, useState } from 'react'

import { useLocation, useParams } from 'react-router-dom'
import i18next from 'i18next'

import EditInviteDialog from './EditInviteDialog'
import EditMemberDialog from './EditMemberDialog'
import { useMembersQuery } from './api/useMembersQuery'
import { useInviteQuery } from './api/useInviteQuery'
import { CustomerCompanyRead, InviteRead, Role } from '../../api/dashboard'
import {
  Badge,
  Button,
  ConfirmDialog,
  ModalProps,
  PageHeader,
  Table,
  TableCell,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  ToastSeverity,
  useModal,
  useToast,
} from '@/ui'
import { dashboardClient } from '../../api'
import UserAvName from './components/UserAvName'
import { Email, Trash } from '@/icons'
import { EditIcon } from '../../icons/Icons'
import AnimatedDots from '../../components/animated-dots/AnimatedDots'
import { useCompaniesQuery } from '../../api/useCompaniesQuery'
import { useAuth0 } from '@auth0/auth0-react'
import { DateTimeValue } from '@/components/ui/DateTimeValue'

export default function MembersTable() {
  const { companyId } = useParams() as { companyId: string }
  const location = useLocation()
  const { state } = location
  const { user } = useAuth0()
  const { data: dataCompanies } = useCompaniesQuery()
  const company = dataCompanies?.data?.find(it => it.id === companyId)
  const {
    data: members = [],
    isLoading: isLoadingMembers,
    refetch: refetchMembers,
  } = useMembersQuery(companyId, { limit: 100 })
  const { data: invites = [], isLoading: isLoadingInvites, refetch: refetchInvites } = useInviteQuery(companyId)
  const showToast = useToast()
  const [editUser, setEditUser] = useState<CustomerCompanyRead | null>(null)
  const [deleteConfirmInvite, setDeleteConfirmInvite] = useState<InviteRead | undefined>()
  const [deleteConfirmMember, setDeleteConfirmMember] = useState<CustomerCompanyRead | undefined>()
  const [invitingId, setInvitingId] = useState<string | undefined>()

  useEffect(() => {
    if (state?.openInviteDlg) {
      state.openInviteDlg = null
      openInviteModal({ item: { role: Role.Viewer } as InviteRead })
    }
  }, [state])

  const onRemoveUser = async (member: CustomerCompanyRead) => {
    setDeleteConfirmMember(undefined)
    await dashboardClient.v1.removeMemberFromCompany(member.customer.id, companyId)
    refetchMembers()
  }

  const onRemoveInvite = async (invite: InviteRead) => {
    setDeleteConfirmInvite(undefined)
    await dashboardClient.v1.removeInvite(companyId, invite.id)
    refetchInvites()
  }

  const onResendInvite = async (invite: InviteRead) => {
    setInvitingId(invite.id)
    await dashboardClient.v1.resendInvite(companyId, invite.id)
    refetchInvites()
    showToast({ message: i18next.t('users.resend.message.desc'), severity: ToastSeverity.success })
    setInvitingId(undefined)
  }

  const openInviteModal = useModal<{ item: InviteRead } & ModalProps>(props => (
    <EditInviteDialog
      item={props.item}
      onClose={() => {
        props.onClose?.()
        refetchInvites()
      }}
    />
  ))

  function renderMemberRow(member: CustomerCompanyRead) {
    return (
      <TableRow key={member.customer.id}>
        <TableCell width="300%">
          <UserAvName
            name={member.customer.name || member.customer.email}
            email={member.customer.email}
            avatarUrl={member.customer.avatar_url}
            isMe={user?.email == member.customer.email}
          />
        </TableCell>
        <TableCell>{i18next.t(`users.role.${member.role}`)}</TableCell>

        <TableCell width="100%">
          {member.customer.created_at ? <DateTimeValue value={member.customer.created_at} /> : ''}
        </TableCell>
        <TableCell width="100%">
          {member.customer.last_active_at || member.customer.last_login_at ? (
            <DateTimeValue value={(member.customer.last_active_at || member.customer.last_login_at)!} />
          ) : (
            ''
          )}
        </TableCell>
        <TableCell>
          <div className="text-right">
            {user?.email != member.customer.email && (
              <TableRowEditButton
                onChange={v => {
                  switch (v) {
                    case 'edit':
                      setEditUser(member)
                      break
                    case 'remove':
                      setDeleteConfirmMember(member)
                      break
                  }
                }}
                options={[
                  {
                    icon: <EditIcon />,
                    children: i18next.t('users.role.edit'),
                    value: 'edit',
                  },
                  {
                    icon: (
                      <span className="text-text-error-primary">
                        <Trash />
                      </span>
                    ),
                    children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                    value: 'remove',
                  },
                ]}
              />
            )}
          </div>
        </TableCell>
      </TableRow>
    )
  }

  function renderInviteRow(member: InviteRead) {
    return (
      <TableRow key={member.id}>
        <TableCell width="300%">
          <UserAvName name={member.name || ''} email={member.email} />
        </TableCell>
        <TableCell>{i18next.t(`users.role.${member.role}`)}</TableCell>

        <TableCell width="100%">
          {member.role != Role.Viewer && (
            <Badge variant="gray-secondary">
              {invitingId == member.id ? (
                <>
                  {i18next.t('users.inviting')} <AnimatedDots />
                </>
              ) : (
                i18next.t('users.invite-pending')
              )}
            </Badge>
          )}
        </TableCell>
        <TableCell width="100%" />

        <TableCell>
          <div className="text-right">
            <TableRowEditButton
              onChange={v => {
                switch (v) {
                  case 'resend':
                    onResendInvite(member)
                    break
                  case 'remove':
                    setDeleteConfirmInvite(member)
                    break
                }
              }}
              options={[
                member.role != Role.Viewer && {
                  icon: <Email />,
                  children: i18next.t('users.invite.resend'),
                  value: 'resend',
                },
                {
                  icon: (
                    <span className="text-text-error-primary">
                      <Trash />
                    </span>
                  ),
                  children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                  value: 'remove',
                },
              ]}
            />
          </div>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <div>
      <PageHeader
        extra={
          <Button
            variant="primary"
            type="button"
            onClick={() => openInviteModal({ item: { role: Role.User } as InviteRead })}
          >
            {i18next.t('users.invite')}
          </Button>
        }
      >
        {i18next.t('users.team')}
      </PageHeader>

      {deleteConfirmInvite && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          message={i18next.t('users.confirm-delete.invite.title')}
          onCancel={() => setDeleteConfirmInvite(undefined)}
          onConfirm={() => onRemoveInvite(deleteConfirmInvite)}
        >
          <div
            className="text-text-secondary"
            dangerouslySetInnerHTML={{
              __html: i18next.t('users.confirm-delete-invite', {
                email: deleteConfirmInvite.email,
                company: company?.name,
              }),
            }}
          />
        </ConfirmDialog>
      )}

      {deleteConfirmMember && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          message={i18next.t('users.confirm-delete.member.title')}
          onCancel={() => setDeleteConfirmMember(undefined)}
          onConfirm={() => onRemoveUser(deleteConfirmMember)}
        >
          <div
            className="text-text-secondary"
            dangerouslySetInnerHTML={{
              __html: i18next.t('users.confirm-delete-member', {
                email: deleteConfirmMember.customer.email,
                company: company?.name,
              }),
            }}
          />
        </ConfirmDialog>
      )}

      <Table>
        <TableRow variant="header">
          <TableCell width="300%">{i18next.t('users.member')}</TableCell>
          <TableCell>{i18next.t('users.role')}</TableCell>
          <TableCell>{i18next.t('users.joined')}</TableCell>
          <TableCell>{i18next.t('users.last-active')}</TableCell>

          <TableCell />
        </TableRow>

        {isLoadingInvites || isLoadingMembers ? (
          <TableRowSkeleton rowCount={5} columnCount={4} />
        ) : (
          <>
            {members.map(it => renderMemberRow(it))}
            {invites.map(it => renderInviteRow(it))}
          </>
        )}
      </Table>

      {editUser && (
        <EditMemberDialog
          onClose={() => {
            setEditUser(null)
            refetchMembers()
          }}
          item={editUser}
        />
      )}
    </div>
  )
}
