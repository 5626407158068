import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { KeyValue } from '@/types'

export async function initI18() {
  return i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
      react: {
        useSuspense: true,
      },
      fallbackLng: 'en',
      supportedLngs: ['en', 'zh'],
      cleanCode: true,
      interpolation: {
        escapeValue: false,
      },
      debug: false,
      ns: ['translation', 'transaction-error-codes', 'countries'],
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        reloadInterval: false,
      },
    })
}

export function getCountries(): KeyValue {
  return (i18n.getResourceBundle(i18n.language, 'countries') || {}) as KeyValue
}
