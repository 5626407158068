import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'es-toolkit'
import { ObjectType, State } from '@/api/dashboard'
import { InputSearch, PageToolbar, ToolbarFilter } from '@/ui'
import { LocaleSearchFilter } from '../hooks'

interface L10nLocaleToolbarProps {
  filter: LocaleSearchFilter
  onChange: (filter: LocaleSearchFilter) => void
}

export const L10nLocaleToolbar = ({ filter, onChange }: L10nLocaleToolbarProps) => {
  const { t } = useTranslation()

  const debouncedOnChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...filter, q: e.target.value })
  }, 250)

  return (
    <PageToolbar>
      <div className="w-full">
        <InputSearch
          data-testid="l10n/toolbar/filter-search"
          defaultValue={filter.q}
          placeholder={t('search')}
          onChange={debouncedOnChange}
        />
      </div>
      <div className="flex gap-2">
        <ToolbarFilter
          data-testid="l10n/toolbar/filter-type"
          data-testvalue={`l10n/toolbar/filter-type/${filter.type}`}
          label={t('localization.locale.filter.type')}
          items={Object.values(ObjectType)
            .sort((a, b) => a.localeCompare(b))
            .map(type => ({
              label: t(`localization.translation.type.${type.toLowerCase()}`, { count: Infinity }),
              value: type,
            }))}
          value={filter.type || null}
          onChange={value => onChange({ ...filter, type: value as ObjectType })}
        />
        <ToolbarFilter
          data-testid="l10n/toolbar/filter-status"
          data-testvalue={`l10n/toolbar/filter-status/${filter.state}`}
          label={t('localization.locale.filter.status')}
          items={Object.values(State).map(state => ({
            label: t(`localization.translation.status.${state}`),
            value: state,
          }))}
          value={filter.state || null}
          onChange={value => onChange({ ...filter, state: value as State })}
        />
      </div>
    </PageToolbar>
  )
}
