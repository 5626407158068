import i18next from 'i18next'
import { ItemCategoryRead, StoreGraph } from '../../api/dashboard'
import { Settings } from '../../components/ui/Settings'
import { CheckBoxContainer } from '../../components/ui/CheckBoxContainer'

import { ReactSortable } from 'react-sortablejs'
import Divider from '../../components/Divider'
import { MenuOption } from '@/ui'
import { SixDots } from '@/icons'

export default function StoreGraphSettings(props: {
  categories: ItemCategoryRead[]
  setCategories: (c: ItemCategoryRead[]) => void
  storeGraph: StoreGraph
  setStoreGraph: (c: StoreGraph) => void
}) {
  return (
    <div>
      <Settings text={i18next.t('store.settings.published')}>
        <CheckBoxContainer
          checked={props.storeGraph.enabled}
          onChange={v => {
            props.setStoreGraph({
              ...props.storeGraph,
              enabled: v.target.checked,
            })
          }}
          label={i18next.t('store.settings.published')}
          caption={i18next.t('store.settings.published.subtitle')}
        />
      </Settings>

      <Divider />

      {props.categories.length > 0 && (
        <Settings text={i18next.t('store.settings.categories')}>
          <CheckBoxContainer
            checked={props.storeGraph.group_by_categories}
            onChange={v => {
              props.setStoreGraph({
                ...props.storeGraph,
                group_by_categories: v.target.checked,
              })
            }}
            label={i18next.t('store.settings.group_by_categories')}
            caption={i18next.t('store.settings.group_by_categories.subtitle')}
          />
          {props.storeGraph.group_by_categories && (
            <div className="mt-3 rounded-md border border-border-primary p-3">
              <ReactSortable
                animation={200}
                delay={2}
                filter=".non-drag"
                list={props.categories}
                setList={props.setCategories}
                className="flex flex-col flex-wrap justify-start"
              >
                {props.categories.map(category => (
                  <MenuOption key={category.id} icon={<SixDots />} className="cursor-move">
                    {category.name}
                  </MenuOption>
                ))}
              </ReactSortable>
            </div>
          )}
        </Settings>
      )}
    </div>
  )
}
