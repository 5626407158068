import { useState } from 'react'
import i18next from 'i18next'
import { useParams } from 'react-router-dom'
import DialogHeader from '../../components/ui/Dialog/DialogHeader'
import { Button, FieldGroup, FieldValidationMessage, Input } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { ResponseError, getErrorText } from '../../api'
import { ErrorMessage } from '@hookform/error-message'
import { PagesSlugCaption } from './components'
import { PageRead } from '../../api/dashboard'
import { useWebsitesQuery } from '../../api/useWebsitesQuery'
import { usePageUpdateMutate } from './api/usePageUpdateMutate'
import { usePageCreateMutate } from './api/usePageCreateMutate'
import slugify from '../../libs/slugify'
import Modal from '../../components/ui/Dialog/Modal'
import { getNow } from '../../util'

const EditCollectionDialog = (props: { onClose: (createdPage?: PageRead) => void; item: PageRead }) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const [helperText, setHelperText] = useState('')
  const website = websites[0]
  const {
    watch,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm<PageRead>({
    defaultValues: {
      ...props.item,
    },
  })

  const { mutateAsync: updateMutateAsync } = usePageUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = usePageCreateMutate(companyId, gameId)

  const saveClick = async (data: PageRead) => {
    setHelperText('')
    try {
      if (props.item?.id) {
        await updateMutateAsync({
          pageId: data.id,
          update: data,
        })
        props.onClose()
      } else {
        let { data: created } = await createMutateAsync({
          create: {
            ...data,
            published_at: getNow(),
          },
        })
        props.onClose(created)
      }

      return true
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  }

  const slug = watch('slug')

  return (
    <Modal onClose={props.onClose}>
      <DialogHeader title={i18next.t(props.item.id ? 'pages.edit.edit-collection' : 'pages.collections-add.title')} />

      <div className="mt-10">
        <FieldGroup label={i18next.t('pages.collection.name')}>
          <Controller
            control={control}
            name="title"
            rules={{ required: i18next.t('validation.required') }}
            render={({ field }) => (
              <Input
                autoFocus
                color={errors.title && 'error'}
                value={field.value}
                onChange={e => {
                  let slug = slugify(e.target.value)
                  setValue('slug', slug)
                  field.onChange(e.target.value)
                }}
              />
            )}
          />
          <ErrorMessage
            name="title"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup
          tooltip={i18next.t('sku.sku-desc')}
          label={i18next.t('pages.edit.slug')}
          caption={<PagesSlugCaption base={website?.url} slug={slug} />}
        >
          <Controller
            control={control}
            name="slug"
            rules={{ required: i18next.t('validation.required') }}
            render={({ field }) => (
              <Input
                color={errors.slug && 'error'}
                value={field.value}
                onChange={e => {
                  let str = slugify(e.target.value)
                  field.onChange(str)
                }}
              />
            )}
          />
          <ErrorMessage
            name="slug"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>
      </div>

      {helperText && <FieldValidationMessage>{helperText}</FieldValidationMessage>}

      <div className="mt-10 flex justify-end gap-4">
        <Button size="lg" onClick={() => props.onClose()}>
          {i18next.t('Cancel')}
        </Button>
        <Button size="lg" variant="primary" onClick={handleSubmit(saveClick)}>
          {i18next.t(props.item.id ? 'Save2' : 'pages.collections-add.submit')}
        </Button>
      </div>
    </Modal>
  )
}

export default EditCollectionDialog
