import i18next from 'i18next'
import Divider from '../../../components/Divider'
import { Settings } from '@/components/ui/Settings'
import {
  Checkbox,
  FieldGroup,
  FieldValidationMessage,
  Input,
  InputExtraMark,
  PropertyCheckboxControl,
  Select,
} from '@/ui'
import { CreateVirtualSKUNode, Node, ResourceState, StoreCardType } from '@/api/dashboard'
import { SelectSkuItem } from '../components/SelectSkuItem/SelectSkuItem'
import { BlockError } from '../types'
import SettingsHeader from '../SettingsHeader'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { Controller, useForm } from 'react-hook-form'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '@/api/useGameItems'
import { useParams } from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'
import { SelectPaymentMethod } from '@/layouts/campaigns/components/SelectPaymentMethod/SelectPaymentMethod'
import { ItemTimelimit } from '@/layouts/store/components/ItemTimelimit'
import { clearStoreItemEmptyFields } from '@/layouts/store/util'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { CONTEXT_ITEM, getContextItemText } from '@/layouts/campaigns/editors/common'
import { useContext, useEffect } from 'react'
import { CampaignContext, ICampaignContext } from '@/layouts/campaigns/Context'

interface VirtualSKUSettingsProps {
  onClose: () => void
  setNode: (node: Node) => void
  node: CreateVirtualSKUNode
  error?: BlockError | null
}

export default function VirtualSKUEditor(props: VirtualSKUSettingsProps) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })
  const context = useContext(CampaignContext) as ICampaignContext
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<CreateVirtualSKUNode>({
    mode: 'onChange',
    values: { ...props.node },
  })

  const onSaveClick = (data: CreateVirtualSKUNode) => {
    clearStoreItemEmptyFields(data, !!isStackable)

    props.setNode(data as Node)
    props.onClose()
  }

  useEffect(() => {
    if (props.error?.errors?.length) {
      let er = props.error.errors[0]
      setError(er.field as 'item_id', { message: er.message })
    }
  }, [props.error])

  const itemId = watch('item_id')
  const min_amount = watch('min_amount')
  const payment_method_ids = watch('payment_method_ids')
  const max_reward_point_percent = watch('max_reward_points_percent')
  const max_purchases = watch('max_purchases')
  const start_at = watch('start_at')
  const end_at = watch('end_at')
  const duration = watch('duration')
  const is_free_item = watch('is_free_item')
  const [use_event_item, card_type] = watch(['use_event_item', 'card_type'])
  const isStackable = use_event_item || items.find(it => it.id == itemId)?.is_stackable

  const renderGeneral = () => {
    return (
      <div className="flex gap-4">
        <div className="w-[421px]">
          <SelectSkuItem
            error={errors.item_id?.message as unknown as string}
            contextItemText={getContextItemText(context.campaign) as string}
            showLinkToCreateNewSKU={true}
            label={i18next.t('campaign.virtual-sku-editor.SKU')}
            caption={i18next.t('campaign.virtual-sku-editor.select-sku')}
            onChange={v => {
              clearErrors('item_id')
              if (v == CONTEXT_ITEM) {
                setValue('use_event_item', true)
                setValue('item_id', null as unknown as string)
              } else {
                setValue('item_id', v as string)
                setValue('use_event_item', false)
              }
            }}
            value={use_event_item ? CONTEXT_ITEM : itemId}
          />
        </div>
        {isStackable != undefined && (
          <div className="w-[335px]">
            <FieldGroup
              caption={i18next.t('campaign.virtual-sku-editor.sku-type-desc')}
              label={i18next.t('campaign.virtual-sku-editor.sku-type')}
            >
              <Input
                disabled
                value={i18next.t(
                  isStackable ? 'campaign.virtual-sku-editor.stackable' : 'campaign.virtual-sku-editor.non-stackable',
                )}
              />
            </FieldGroup>
          </div>
        )}
      </div>
    )
  }

  const renderGeneralLimitations = () => {
    return (
      <Settings text={i18next.t('campaign.virtual-sku-editor.general-limitations')}>
        <CheckBoxContainer
          disabled={true}
          checked={!!payment_method_ids}
          onChange={v => {
            setValue('payment_method_ids', v.target.checked ? [] : undefined)
          }}
          label={`${i18next.t('coupon.payment-bound')} (${i18next.t('coming-soon.title')})`}
          caption={i18next.t('coupon.payment-bound-desc')}
        >
          <SelectPaymentMethod
            value={(payment_method_ids || []) as string[]}
            onChange={v => {
              setValue('payment_method_ids', v as string[])
            }}
          />
        </CheckBoxContainer>

        <Divider />

        <Controller
          name="min_amount"
          rules={{ required: min_amount == null ? false : i18next.t('validation.required') }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <CheckBoxContainer
              disabled={is_free_item}
              checked={value != null}
              onChange={v => {
                onChange(v.target.checked ? 100 : null)
              }}
              label={i18next.t('campaign.virtual-sku-editor.min_amount')}
            >
              <Input
                disabled={is_free_item}
                type="number"
                value={isNaN(value!) ? '' : value! / 100}
                extraLeft={<InputExtraMark>$</InputExtraMark>}
                onChange={e => {
                  let v = Math.abs(parseFloat(e.target.value))
                  onChange(Math.max(1, v * 100))
                }}
              />
              <ErrorMessage
                name="min_amount"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </CheckBoxContainer>
          )}
        />
      </Settings>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <ItemTimelimit
        setValue={(prop, value) => setValue(prop, value)}
        start_at={start_at}
        end_at={end_at}
        duration={duration}
      />
    )
  }

  const renderBenefitsCompatibility = () => {
    return (
      <Settings text={i18next.t('campaign.virtual-sku-editor.benefits-compatibility')}>
        <CheckBoxContainer
          disabled
          checked={max_reward_point_percent != undefined}
          onChange={v => {
            setValue('max_reward_points_percent', v.target.checked ? 0 : undefined)
          }}
          label={`${i18next.t('campaign.virtual-sku-editor.stackable-with-cashback')} (${i18next.t('coming-soon.title')})`}
        >
          <FieldGroup label={i18next.t('campaign.virtual-sku-editor.max_reward_point_percent')}>
            <Input type="number" {...register('max_reward_points_percent')} />
          </FieldGroup>
        </CheckBoxContainer>
      </Settings>
    )
  }

  const renderQuantityLimitations = () => {
    return (
      <Settings text={i18next.t('campaign.virtual-sku-editor.quantity-limitations')}>
        <CheckBoxContainer
          checked={max_purchases != undefined}
          onChange={v => {
            setValue('max_purchases', v.target.checked ? 1 : undefined)
          }}
          label={i18next.t('campaign.virtual-sku-editor.limit-quantity')}
        >
          <FieldGroup
            label={i18next.t('campaign.virtual-sku-editor.max_purchases')}
            caption={i18next.t('campaign.virtual-sku-editor.max_purchases.desc')}
          >
            <Input
              type="number"
              {...register('max_purchases', { min: 1 })}
              placeholder={i18next.t('campaign.virtual-sku-editor.max_purchases.ph')}
            />
          </FieldGroup>
        </CheckBoxContainer>
      </Settings>
    )
  }

  const renderBadgeSettings = () => {
    return (
      <>
        <Settings text={i18next.t('store.item.display_settings')}>
          <FieldGroup label={i18next.t('store.item.custom_badge_text')}>
            <Input {...register('custom_badge')} maxLength={30} />
            <ErrorMessage
              name="custom_badge"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('store.item.featured_type')}>
            <Controller
              control={control}
              name="card_type"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value || StoreCardType.Default}
                  options={Object.values(StoreCardType).map(value => ({
                    children: i18next.t(`store.item.featured_type.${value}`),
                    value: value,
                  }))}
                />
              )}
            />
          </FieldGroup>

          <Checkbox {...register('show_first_on_category_list')} disabled={card_type == StoreCardType.Default}>
            {i18next.t('store.item.show_first_on_category_list')}
          </Checkbox>
        </Settings>
      </>
    )
  }

  const renderBenefits = () => {
    return (
      <Settings text={i18next.t('campaign.block.ItemDiscountOfferActionNode.Benefits')}>
        <div className="mb-3">
          <div className="mb-3 flex gap-3">
            <PropertyCheckboxControl
              size="md"
              className="w-1/2"
              value={!is_free_item}
              type="radio"
              label={i18next.t('store.item.paid_item')}
              desc={i18next.t('store.item.paid_item.desc')}
              onChange={() => {
                setValue('is_free_item', false, { shouldDirty: true })
              }}
            />

            <PropertyCheckboxControl
              size="md"
              type="radio"
              className="w-1/2"
              value={!!is_free_item}
              label={i18next.t('store.item.free_item')}
              desc={i18next.t('store.item.free_item.desc')}
              onChange={() => {
                setValue('is_free_item', true, { shouldDirty: true })
                if (!max_purchases) {
                  setValue('max_purchases', 1)
                }
              }}
            />
          </div>
        </div>

        {!is_free_item && (
          <FieldGroup label={i18next.t('campaign.virtual-sku-editor.discount')}>
            <Input
              type="number"
              {...register('discount_percent')}
              disabled={is_free_item}
              extraLeft={<InputExtraMark>%</InputExtraMark>}
            />
          </FieldGroup>
        )}

        {isStackable && (
          <FieldGroup label={i18next.t('campaign.virtual-sku-editor.bonus')}>
            <Input type="number" {...register('bonus_percent')} extraLeft={<InputExtraMark>%</InputExtraMark>} />
          </FieldGroup>
        )}

        {gameSettings?.enable_reward_points && !is_free_item && (
          <FieldGroup label={i18next.t('campaign.virtual-sku-editor.reward_point_percent')}>
            <Input
              type="number"
              {...register('reward_points_percent', {
                min: { value: 1, message: i18next.t('campaign.virtual-sku-editor.reward_point_percent.validation') },
                max: { value: 100, message: i18next.t('campaign.virtual-sku-editor.reward_point_percent.validation') },
              })}
              extraLeft={<InputExtraMark>%</InputExtraMark>}
            />
            <ErrorMessage
              name="reward_points_percent"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        )}
      </Settings>
    )
  }

  return (
    <div>
      <SettingsHeader
        onSave={handleSubmit(onSaveClick)}
        onClose={props.onClose}
        text={i18next.t('campaign.virtual-sku-editor.title')}
      />

      <div className="p-6">
        {renderGeneral()}

        {(itemId || use_event_item) && (
          <>
            <div className="font-bold">
              {i18next.t(
                isStackable
                  ? 'campaign.virtual-sku-editor.stackable.desc'
                  : 'campaign.virtual-sku-editor.non-stackable.desc',
              )}
            </div>

            <Divider />

            {renderBenefits()}

            <Divider />

            {renderGeneralLimitations()}
            <Divider />

            {renderTimeLimitations()}
            <Divider />

            {renderQuantityLimitations()}
            <Divider />

            {gameSettings?.enable_reward_points && (
              <>
                {renderBenefitsCompatibility()}
                <Divider />
              </>
            )}

            {renderBadgeSettings()}
          </>
        )}
      </div>
    </div>
  )
}
