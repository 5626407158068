import { Fragment, useContext, useEffect, useState } from 'react'
import { Link, generatePath, useLocation, useParams } from 'react-router-dom'
import i18next from 'i18next'
import { Campaign } from '../../../../api/dashboard'
import { dashboardClient } from '../../../../api'
import { AuthContext, IAuthContext } from '../../../../Context'
import { canEdit } from '../../../../security'
import { CampaignRow } from './CampaignRow'
import styled from '@emotion/styled'
import getCampaignName from './getCampaignName'
import { LIVEOPS_DETAILS_PATH, LIVEOPS_SETTINGS_PATH } from '../../../../libs/routerPaths'
import { Button, ConfirmDialog, ModalProps, PageHeader, Skeleton, TableZeroState, useModal } from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { campaignsQuery } from '../../api'
import { Plus } from '@/icons'
import CreateCampaignModal from '@/layouts/campaigns/widgets/CreateCampaignModal'

const Table = styled.div`
  display: flex;
  gap: ${p => p.theme.spacing(1.75)};
  flex-direction: column;
`

export default function CampaignsTable() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
    eventId?: string
  }
  const location = useLocation()
  const { state } = location
  const context = useContext(AuthContext) as IAuthContext
  const isReadOnly = !canEdit(context.customer)

  const { data: items = [], refetch, isLoading } = useQuery(campaignsQuery(companyId, gameId))
  const [deletingCampaign, setDeletingCampaign] = useState<Campaign | null>(null)

  const onConfirmDeleteClick = async () => {
    if (!deletingCampaign) {
      return
    }
    await dashboardClient.v1.deleteCampaign(companyId, gameId, deletingCampaign.id)
    setDeletingCampaign(null)
    refetch()
  }

  const onEnableChange = async (item: Campaign, value: boolean) => {
    await dashboardClient.v1.updateCampaign(companyId, gameId, item.id, {
      enabled: value,
    })
    refetch()
  }

  const renderSkeleton = () => {
    return new Array(4).fill('').map((_, idx) => <Skeleton className="h-[134px]" key={idx} />)
  }

  const openCreateModal = useModal<ModalProps>(props => <CreateCampaignModal {...props} />)

  useEffect(() => {
    if (state?.openCreateCampaign) {
      state.openCreateCampaign = null
      setTimeout(() => openCreateModal(), 300)
    }
  }, [state])

  return (
    <div className="flex h-full flex-col">
      {deletingCampaign && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          subMessage={i18next.t('campaign.confirm.text', { name: getCampaignName(deletingCampaign.type) })}
          onCancel={() => setDeletingCampaign(null)}
          onConfirm={onConfirmDeleteClick}
        />
      )}

      <PageHeader
        extra={
          !isReadOnly && (
            <>
              <Link to={generatePath(LIVEOPS_SETTINGS_PATH, { companyId, gameId })}>
                <Button variant="outline">{i18next.t('campaign.settings')}</Button>
              </Link>
              {!!items.length && (
                <Button variant="primary" type="button" onClick={() => openCreateModal()}>
                  {i18next.t('campaigns.add')}
                </Button>
              )}
            </>
          )
        }
      >
        {i18next.t('sidebar.campaigns')}
      </PageHeader>

      {isLoading ? (
        <div className="flex flex-col gap-3">{renderSkeleton()}</div>
      ) : items.length === 0 ? (
        <TableZeroState
          title={i18next.t(isReadOnly ? 'campaigns.empty-table.title.read-only' : 'campaigns.empty-table.title')}
          message={i18next.t(isReadOnly ? 'campaigns.empty-table.read-only' : 'campaigns.empty-table')}
          buttons={
            <>
              {!isReadOnly && (
                <Button variant="primary" onClick={() => openCreateModal()}>
                  <Plus size={14} />
                  <span>{i18next.t('campaigns.new')}</span>
                </Button>
              )}
            </>
          }
        />
      ) : (
        <Table>
          {items.map(it => (
            <Fragment key={it.id}>
              <CampaignRow
                to={generatePath(LIVEOPS_DETAILS_PATH, { companyId, gameId, eventId: it.id })}
                campaign={it}
                onDeleteClick={isReadOnly ? undefined : () => setDeletingCampaign(it)}
                onClickToggle={isReadOnly ? undefined : () => onEnableChange(it, !it.enabled)}
              />
              <div className="border-b border-border-secondary" />
            </Fragment>
          ))}
        </Table>
      )}
    </div>
  )
}
