import { Skeleton, TableCell, TableRow } from '@/ui'

interface SkuItemRowSkeletonProps {
  canEdit?: boolean
}

export const SkuItemRowSkeleton = ({ canEdit }: SkuItemRowSkeletonProps) => {
  return (
    <TableRow>
      {canEdit && (
        <TableCell width={44}>
          <Skeleton className="size-[20px]" />
        </TableCell>
      )}
      <TableCell width="100%" className="flex items-center gap-3">
        <Skeleton className="size-[38px] shrink-0" />
        <div className="mr-auto">
          <Skeleton className="mb-0.5 h-5 w-64" />
          <Skeleton className="mr-auto h-3 w-16" />
        </div>
        <Skeleton className="h-[24px] w-24" />
      </TableCell>

      <TableCell width="20%">
        <Skeleton className="h-[24px]" />
      </TableCell>
      <TableCell width="20%">
        <Skeleton className="h-[24px]" />
      </TableCell>
      <TableCell width="20%">
        <Skeleton className="h-[24px]" />
      </TableCell>
      <TableCell width={62}>{canEdit && <Skeleton className="h-[24px]" />}</TableCell>
    </TableRow>
  )
}
