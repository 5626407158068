import { ComponentProps, Ref, forwardRef, useEffect, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getErrorText } from '@/api'
import { DailyRewardCreate, DailyRewardMode, DailyRewardUpdate } from '@/api/dashboard'
import { CloseOutline } from '@/icons'
import {
  Button,
  ButtonIcon,
  FieldGroup,
  Header,
  Input,
  PropertyCheckboxControl,
  Textarea,
  ToastSeverity,
  useToast,
} from '@/ui'
import { useDailyRewardsCreate, useDailyRewardsUpdate } from '@/layouts/engagement/hooks'

const MAX_NAME_LENGTH = 255
const MAX_DESC_LENGTH = 512

interface PropertyControlSwitchProps<V> {
  left: Omit<ComponentProps<typeof PropertyCheckboxControl>, 'value' | 'onChange'> & { value: V }
  right: Omit<ComponentProps<typeof PropertyCheckboxControl>, 'value' | 'onChange'> & { value: V }
  value: V
  onChange: (value: V) => void
}

const PropertyControlSwitch = forwardRef(function <V>(
  { left, right, value, onChange }: PropertyControlSwitchProps<V>,
  ref: Ref<HTMLButtonElement>,
) {
  const [selected, setSelected] = useState<typeof value>(value)

  return (
    <div className="flex w-full gap-3">
      <PropertyCheckboxControl
        ref={ref}
        className="w-1/2"
        size="md"
        label={left.label}
        desc={left.desc}
        value={left.value === selected}
        onChange={() => {
          onChange(left.value)
          setSelected(left.value)
        }}
      />
      <PropertyCheckboxControl
        ref={ref}
        className="w-1/2"
        size="md"
        label={right.label}
        desc={right.desc}
        value={right.value === selected}
        onChange={() => {
          onChange(right.value)
          setSelected(right.value)
        }}
      />
    </div>
  )
})

type DailyRewardsSettingsFormInput = Omit<DailyRewardUpdate, 'enabled'>

interface DailyRewardsSettingsFormProps {
  uid?: string
  settings: DailyRewardUpdate
  onClose: () => void
}

export const DailyRewardsSettingsForm = ({ uid, settings, onClose }: DailyRewardsSettingsFormProps) => {
  const { t } = useTranslation()
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting, errors },
  } = useForm<DailyRewardsSettingsFormInput>({
    defaultValues: {
      ...settings,
      mode: settings?.mode || DailyRewardMode.Soft,
    },
  })
  useEffect(() => reset(settings), [reset, settings])

  const { field: mode } = useController({ name: 'mode', control })

  const showToast = useToast()
  const { mutateAsync } = uid ? useDailyRewardsUpdate(uid) : useDailyRewardsCreate()
  const onSubmit = handleSubmit(async data => {
    const payload = { ...data, repeatable: false }
    if (!uid) {
      payload.rewards = {
        daily_rewards: [],
      }
    }
    try {
      await mutateAsync(payload as DailyRewardUpdate & DailyRewardCreate)
      onClose()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  })

  return (
    <form onSubmit={onSubmit}>
      <Header
        title={
          <div className="font-nohemi text-lg font-semibold tracking-wider">
            {t('daily-rewards.settings.edit.title')}
          </div>
        }
        className="p-4"
        minWidth="auto"
      >
        <div className="flex gap-4">
          <Button variant="primary" disabled={!isDirty} isLoading={isSubmitting} type="submit">
            {t('daily-rewards.settings.edit.save')}
          </Button>
          <ButtonIcon
            onClick={() => {
              onClose()
              reset()
            }}
            type="button"
            size="sm"
            variant="secondary-gray"
          >
            <CloseOutline />
          </ButtonIcon>
        </div>
      </Header>

      <div className="flex size-full flex-col gap-4 p-4">
        <div className="flex flex-col">
          <FieldGroup
            size="sm"
            label={
              <span className="text-caption-sm font-normal text-text-tertiary">{t('daily-rewards.settings.name')}</span>
            }
          >
            <Input
              size="sm"
              disabled={isSubmitting}
              errors={errors}
              {...register('name', {
                required: t('validation.required'),
                validate: value => (value && value.trim().length > 0) || t('validation.required'),
                maxLength: {
                  value: MAX_NAME_LENGTH,
                  message: t('daily-rewards.settings.name.validation', { max: MAX_NAME_LENGTH }),
                },
              })}
            />
          </FieldGroup>

          <FieldGroup
            size="sm"
            label={
              <span className="text-caption-sm font-normal text-text-tertiary">
                {t('daily-rewards.settings.description')}
              </span>
            }
          >
            <Textarea
              size="sm"
              rows={4}
              disabled={isSubmitting}
              errors={errors}
              {...register('description', {
                maxLength: {
                  value: MAX_DESC_LENGTH,
                  message: t('daily-rewards.settings.description.validation', { max: MAX_DESC_LENGTH }),
                },
              })}
            />
          </FieldGroup>
        </div>

        <div className="flex flex-col gap-18">
          <span className="text-title-t6 text-text-secondary">{t('daily-rewards.settings.mode')}</span>
          <PropertyControlSwitch
            left={{
              label: t('daily-rewards.settings.mode.soft'),
              desc: t('daily-rewards.settings.mode.soft.description'),
              value: DailyRewardMode.Soft,
            }}
            right={{
              label: t('daily-rewards.settings.mode.hard'),
              desc: t('daily-rewards.settings.mode.hard.description'),
              value: DailyRewardMode.Hard,
            }}
            {...mode}
          />
        </div>
      </div>
    </form>
  )
}
