import { createContext, useEffect, useState } from 'react'
import { Route, Routes, generatePath, useLocation, useNavigate } from 'react-router-dom'
import { focusGroupKeyUX, hotkeyKeyUX, jumpKeyUX, pressKeyUX, startKeyUX } from 'keyux'
import { AuthContext, IAppState } from './Context'
import CssBaseline from '@mui/material/CssBaseline'
import { Alert, Snackbar, ThemeProvider } from '@mui/material'
import { buildTheme } from './theme/Theme'
import { HubApiError } from './types'
import { getLastCompanyId, getLastGameId } from './Settings'
import AppSkeleton from './skeleton/AppSkeleton'
import { CompanyGame, CustomerCompany, ItemType } from './api/dashboard'
import {
  COMPANY_PAYMENT_METHODS,
  COMPANY_SETTINGS_PATH,
  DOCUMENT_PATH,
  ENGAGEMENT_PATH,
  FEATURES_PATH,
  GAME_PATH,
  GAME_SETTINGS,
  GAME_WEBHOOKS_DETAIL_PATH,
  GAME_WEBHOOKS_EDIT_PATH,
  GAME_WEBHOOKS_NEW_PATH,
  GAME_WEBHOOKS_PATH,
  GET_STARTED_PATH,
  GET_STARTED_VERIFY_ACCOUNT_PATH,
  HOME_PATH,
  HUB_PATH,
  LIVEOPS_PATH,
  LOCALIZATION_LOCALE_PATH,
  LOCALIZATION_PATH,
  LOCALIZATION_TRANSLATION_PATH,
  PAYOUTS_PATH,
  SKU,
  TEAM_PATH,
  TRANSACTIONS_PATH,
} from '@/libs'
import PlayersTable from './layouts/player/PlayersTable'
import GameAttributeTable from './layouts/player/GameAttributeTable'
import SegmentTable from './layouts/segment/SegmentTable'
import { MainOutlet } from './layouts/MainOutlet'
import TransactionsPage from './layouts/transaction/TransactionsPage'
import ComingSoon from './components/ComingSoon'
import HubThemePage from './hub-editor/HubThemePage'
import { HubIndexPage } from './layouts/hub-settings/HubIndexPage'
import NewsPage from './layouts/news/NewsPage'
import AchievementTable from './layouts/achievement/AchievementTable'
import LeaderBoardTable from './layouts/leader-board/LeaderBoardTable'
import ErrorView from './components/ErrorView'
import AcceptForm from './layouts/accept-form/AcceptForm'
import OnBoardingPage from './layouts/onboarding/OnBoardingPage'
import Dashboard from './layouts/dashboard/Dashboard'
import CompanySettingsPage from './layouts/company/CompanySettingsPage'
import { SkuListPage } from './layouts/game-items/pages/SkuListPage'
import { SkuEditPage } from './layouts/game-items/pages/SkuEditPage'
import CouponTable from './layouts/coupon/CouponTable'
import MembersTable from './layouts/users/MembersTable'
import TransactionDetails from './layouts/transaction/TransactionDetails'
import CampaignsTable from './layouts/campaigns/pages/CampaignTable/CampaignsTable'
import { CampaignSettingsPage } from './layouts/campaigns/pages/CampaignSettingsPage'
import CampaignEditPage from './layouts/campaigns/CampaignEditPage'
import HubEditor from './hub-editor/HubEditor'
import PlayerDetails from './layouts/player/PlayerDetails'
import { PagesIndexPage } from './layouts/pages/PagesIndexPage'
import SegmentGroupGraphEditor from './layouts/segment/SegmentGroupGraphEditor'
import PagesPage from './layouts/pages/PagesPage'
import FeatureSettings from './layouts/game-settings/widgets/FeatureSettings'
import { loadCompanyAndGame, useCompaniesQuery } from './api/useCompaniesQuery'
import { useQueryClient } from '@tanstack/react-query'
import { ResponseError } from './api'
import { identifyPosthogUser } from './api/postLogin'
import { L10nLocalePage, L10nOutlet, L10nTranslationPage } from '@/layouts/localization'
import { SkuCreatePage } from './layouts/game-items/pages/SkuCreatePage'
import StorePage from './layouts/store/StorePage'
import StoreEditor from './layouts/store/StoreEditor'
import StoreGraphPage from './layouts/store/StoreGraphPage'
import ItemCategoryPage from './layouts/item-category/ItemCategoryPage'
import { WebhookDetailPage, WebhookEditPage, WebhookNewPage, WebhookTable } from './layouts/webhooks'
import { useAuthToken } from './auth/useAuthToken'
import PaymentSettingsTable from './layouts/payment-settings/PaymentSettingsTable'
import { GameSettingsPage } from './layouts/game-settings/GameSettingsPage'
import { HubSettingsPage } from './layouts/hub-settings/HubSettingsPage'
import { GetStartedPage, GetStartedVerifyPage } from './layouts/get-started'
import PayoutsPage from './layouts/finance-report/PayoutsPage'
import { hasOnlyDemoCompany } from '@/util'
// import { loadProfileInitLang } from '@/translates'
import { DailyRewardsPage, LoyaltyPage } from '@/layouts/engagement'

export const lightTheme = buildTheme('light')

export const ThemeContext = createContext({
  theme: {},
})

startKeyUX(window, [hotkeyKeyUX([]), focusGroupKeyUX(), pressKeyUX('is-pressed'), jumpKeyUX()])

export default function App() {
  const [customer, setCustomer] = useState<CustomerCompany | null>(null)
  const [currentCompanyId, setCurrentCompanyId] = useState<string | null>(null)
  const [theme] = useState(lightTheme)
  const [error, setError] = useState<HubApiError | null>(null)
  const { pathname } = useLocation()
  const [appState, setAppState] = useState<IAppState>({} as IAppState)
  const [acceptForm, setAcceptForm] = useState<boolean>(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data: dataCompanies = null, refetch } = useCompaniesQuery()

  const { isLoading, authDone } = useAuthToken()

  const companies = dataCompanies?.data
  const errorGetCompany = dataCompanies?.error as ResponseError

  const openDefaultCompany = async (companies: CompanyGame[], companyWithGames: CompanyGame) => {
    let params = {
      companyId: companyWithGames.id,
      gameId: companyWithGames.games ? companyWithGames.games[0].id : '',
    }

    let lastGameId = getLastGameId()
    let lastCompanyId = getLastCompanyId()

    if (lastGameId && lastCompanyId) {
      let c = companies.find(c => c.id == lastCompanyId)
      if (!c) {
        let game = await loadCompanyAndGame(queryClient, lastCompanyId, lastGameId)

        if (game) {
          params = { companyId: lastCompanyId, gameId: lastGameId }
        }
      } else {
        params = { companyId: lastCompanyId, gameId: lastGameId }
      }
    }

    navigate(generatePath(HOME_PATH, params))
  }

  const loadCompanies = async () => {
    if (errorGetCompany || error) {
      return
    }

    if (!companies) {
      return
    }

    let companyWithGames = companies.find(c => c.games && c.games.length > 0)

    if (hasOnlyDemoCompany(companies) || !companies.length || !companyWithGames) {
      navigate('/onboarding')
    } else {
      if (pathname.startsWith('/company')) {
        //useParams can't be use outside of Router
        let arr = pathname.split('/')
        let companyId = arr[2]
        let gameId = arr[3]
        let company = companies.find(it => it.id == companyId)

        if (!company) {
          let game = await loadCompanyAndGame(queryClient, companyId, gameId)
          if (!game) {
            console.log("Can't load game, open default company...")
            openDefaultCompany(companies, companyWithGames)
          }
        } else {
          let games = company?.games
          if (!games || !games.find(g => g.id === gameId)) {
            openDefaultCompany(companies, companyWithGames)
          }
        }
      } else if (!pathname.startsWith('/onboarding')) {
        openDefaultCompany(companies, companyWithGames)
      }
    }
  }

  useEffect(() => {
    if (authDone) {
      refetch()
      identifyPosthogUser()
    }
  }, [authDone])

  useEffect(() => {
    if (companies != null) {
      loadCompanies()
    }
  }, [companies])

  useEffect(() => {
    if (errorGetCompany) {
      if (errorGetCompany.status == 403) {
        navigate('/403')
      }

      if (errorGetCompany.status == 451) {
        setAcceptForm(true)
      }
    }
  }, [errorGetCompany])

  if (isLoading) {
    return <AppSkeleton />
  }

  if (error) {
    return (
      <ThemeContext.Provider value={{ theme: theme }}>
        <ThemeProvider theme={theme}>{<ErrorView msg={error.detail} />}</ThemeProvider>
      </ThemeContext.Provider>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        customer: customer!,
        setCustomer: setCustomer,
        currentCompanyId: currentCompanyId,
        setCurrentCompanyId: setCurrentCompanyId,
        state: appState,
        setState: setAppState,
      }}
    >
      <ThemeContext.Provider value={{ theme: theme }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {acceptForm ? (
            <AcceptForm
              onClose={() => {
                refetch()
                setAcceptForm(false)
              }}
            />
          ) : (
            <Routes>
              <Route element={<MainOutlet />}>
                <Route path="/onboarding" element={<OnBoardingPage />} />
                <Route path="/company/:companyId/:gameId/*" element={<ComingSoon />} />
                <Route path={HOME_PATH} element={<Dashboard />} />

                <Route path={LIVEOPS_PATH}>
                  <Route path="table" element={<CampaignsTable />} />
                  <Route path="settings" element={<CampaignSettingsPage />} />

                  <Route path="segments" element={<SegmentTable />} />
                  <Route path="segments/groups" element={<SegmentTable segmentGroupVisible={true} />} />
                  <Route path="segments/groups/:groupId" element={<SegmentGroupGraphEditor />} />

                  <Route path=":eventId" element={<CampaignEditPage />} />
                  <Route path=":eventId/:userCampaignId" element={<CampaignEditPage />} />
                </Route>

                <Route path={ENGAGEMENT_PATH}>
                  <Route path="loyalty" element={<LoyaltyPage />} />
                  <Route path="daily-rewards" element={<DailyRewardsPage />} />
                </Route>

                <Route path={SKU}>
                  <Route path="store">
                    <Route index={true} element={<StorePage />} />
                    <Route path="rules" element={<StoreGraphPage />} />
                    <Route path=":storeId" element={<StoreEditor />} />
                  </Route>
                  <Route path="category">
                    <Route index={true} element={<ItemCategoryPage />} />
                  </Route>
                  <Route path="items">
                    <Route index={true} element={<SkuListPage types={[ItemType.Item, ItemType.Currency]} />} />
                    <Route path=":itemId" element={<SkuEditPage type={ItemType.Item} />} />
                    <Route path="new" element={<SkuCreatePage type={ItemType.Item} />} />
                  </Route>

                  <Route path="bundles" element={<SkuListPage types={[ItemType.Bundle]} />} />
                  <Route path="bundles/:itemId" element={<SkuEditPage type={ItemType.Bundle} />} />
                  <Route path="bundles/new" element={<SkuCreatePage type={ItemType.Bundle} />} />

                  <Route path="codes">
                    <Route index={true} element={<CouponTable />} />
                    <Route path=":codeId" element={<CouponTable />} />
                  </Route>
                </Route>

                <Route path={LOCALIZATION_PATH} element={<L10nOutlet />}>
                  <Route path={LOCALIZATION_LOCALE_PATH} element={<L10nLocalePage />}>
                    <Route path={LOCALIZATION_TRANSLATION_PATH} element={<L10nTranslationPage />} />
                  </Route>
                </Route>

                <Route path={TRANSACTIONS_PATH}>
                  <Route index={true} element={<TransactionsPage />} />
                  <Route path=":paymentNumber" element={<TransactionDetails />} />
                </Route>

                <Route path={GAME_PATH}>
                  <Route path="players">
                    <Route index={true} element={<PlayersTable />} />
                    <Route path=":userId" element={<PlayerDetails />} />
                  </Route>
                  <Route path="attributes" element={<GameAttributeTable />} />

                  <Route path={GAME_WEBHOOKS_PATH}>
                    <Route index={true} element={<WebhookTable />} />
                    <Route path={GAME_WEBHOOKS_NEW_PATH} element={<WebhookNewPage />} />
                    <Route path={GAME_WEBHOOKS_DETAIL_PATH} element={<WebhookDetailPage />}>
                      <Route path={GAME_WEBHOOKS_EDIT_PATH} element={<WebhookEditPage />} />
                    </Route>
                  </Route>
                </Route>

                <Route path={HUB_PATH}>
                  <Route index={true} element={<HubIndexPage />} />
                  <Route path="themes">
                    <Route index={true} element={<HubThemePage />} />
                    <Route path=":wbId" element={<HubEditor />} />
                  </Route>
                  <Route path="settings" element={<HubSettingsPage />} />
                  <Route path="news">
                    <Route path=":group?" element={<NewsPage />} />
                    <Route path=":group/:newsId" element={<NewsPage />} />
                  </Route>
                  <Route path="leaderboard">
                    <Route index={true} element={<LeaderBoardTable />} />
                  </Route>
                  <Route path="achievements">
                    <Route index={true} element={<AchievementTable />} />
                  </Route>
                  <Route path="pages">
                    <Route index={true} element={<PagesIndexPage />} />
                    <Route path=":parent?" element={<PagesPage />} />
                    <Route path=":parent/:pageId" element={<PagesPage />} />
                  </Route>
                </Route>

                <Route path={GET_STARTED_PATH}>
                  <Route path={GET_STARTED_PATH} element={<GetStartedPage />} />
                  <Route path={GET_STARTED_VERIFY_ACCOUNT_PATH} element={<GetStartedVerifyPage />} />
                </Route>

                <Route path={DOCUMENT_PATH} element={<ComingSoon />} />
                <Route path={PAYOUTS_PATH} element={<PayoutsPage />} />
                <Route path={TEAM_PATH} element={<MembersTable />} />
                <Route path={COMPANY_SETTINGS_PATH} element={<CompanySettingsPage />} />
                <Route path={COMPANY_PAYMENT_METHODS} element={<PaymentSettingsTable />} />
                <Route path={GAME_SETTINGS} element={<GameSettingsPage />} />
                <Route path={FEATURES_PATH} element={<FeatureSettings />} />
              </Route>
            </Routes>
          )}

          {error && (
            <Snackbar open={true} autoHideDuration={60000} onClose={() => setError(null)}>
              <Alert onClose={() => setError(null)} severity="error" variant="filled">
                {(error as HubApiError).detail}
              </Alert>
            </Snackbar>
          )}
        </ThemeProvider>
      </ThemeContext.Provider>
    </AuthContext.Provider>
  )
}
