import { HTMLAttributes, MouseEvent, ReactNode, useId } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Floating, FloatingMenuProps, MenuItem, Menu_v2 } from '@/components/ui'
import { ChevronDown, FilterLines, XClose } from '@/icons'
import { cn, getMotionProps } from '@/libs'
import { ToolbarFilterInnerButton } from './ToolbarFilterInnerButton'

export type ToolbarFilterValue = string | number | null

export interface ToolbarFilterItem {
  label: ReactNode
  value: ToolbarFilterValue
}

export interface ToolbarFilterProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  label: ReactNode
  items: ToolbarFilterItem[]
  value: string | number | null
  onChange: (value: ToolbarFilterValue) => void
}

const valueMotionProps = getMotionProps({
  initial: { x: -1, width: 0, opacity: 0 },
  animate: { x: 0, width: 'auto', opacity: 1 },
  exit: { x: 0, width: 0, opacity: 1 },
})

export interface ToolbarFilterMenuProps extends FloatingMenuProps, Pick<ToolbarFilterProps, 'items' | 'onChange'> {}

const ToolbarFilterMenu = ({ items, onChange, ...rest }: ToolbarFilterMenuProps) => {
  const onClickMenuItem = (item: ToolbarFilterItem) => {
    onChange(item.value)
    rest.onClose?.()
  }

  return (
    <Menu_v2>
      {items.map(item => (
        <MenuItem key={`menu-item-${item.value}`} onClick={() => onClickMenuItem(item)}>
          {item.label}
        </MenuItem>
      ))}
    </Menu_v2>
  )
}

export const ToolbarFilter = ({ label, items, value, onChange, ...rest }: ToolbarFilterProps) => {
  const id = useId()

  const onClickLabel = (e: MouseEvent) => {
    if (value) {
      e.stopPropagation()
      onChange(null)
    }
  }

  return (
    <Floating menu={<ToolbarFilterMenu items={items} onChange={onChange} />} placement="bottom-end">
      <div
        {...rest}
        className={cn(
          'inline-flex items-stretch rounded-md border border-dashed border-border-primary bg-fg-primary transition-all',
          'pointer-events-none',
          value
            ? 'border-solid shadow-xs'
            : [
                'text-text-tertiary',
                'hover:border-border-primary-hover hover:bg-fg-primary-hover hover:text-text-secondary',
              ],
          rest.className,
        )}
      >
        <div className="flex h-full items-center truncate p-[2px]">
          <ToolbarFilterInnerButton variant={value ? 'clear' : 'empty'} onClick={onClickLabel}>
            {value ? <XClose className="shrink-0" size={16} /> : <FilterLines className="shrink-0" size={16} />}
            <span>{label}</span>
          </ToolbarFilterInnerButton>
        </div>

        <AnimatePresence initial={false}>
          {value && (
            <motion.div {...valueMotionProps} className="overflow-hidden" key={`value-${id}-${value}`}>
              <div className="flex h-full items-center truncate border-l border-border-secondary p-[2px]">
                <ToolbarFilterInnerButton variant="value">
                  <span>{items.find(item => item.value === value)?.label}</span>
                  <ChevronDown className="shrink-0" strokeWidth={2.5} size={16} />
                </ToolbarFilterInnerButton>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Floating>
  )
}
