import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { FieldGroup, FieldValidationMessage, ImageUploader } from '@/ui'
import { S3Bucket } from '../../../../types'
import { FormSection } from '../../components'
import { SkuFormData } from './SkuForm'
import { ItemType } from '@/api/dashboard'

interface SkuFormMediaSectionProps {
  type: ItemType
}

export const SkuFormMediaSection = ({ type }: SkuFormMediaSectionProps) => {
  const { t } = useTranslation()
  const {
    formState: { errors },
    control,
  } = useFormContext<SkuFormData>()

  return (
    <FormSection>
      <div className="-m-6 mb-6 rounded-t-2xl1 bg-fg-primary-hover p-6">
        <div className="mb-3 text-t4">{t('sku.item.media-content')}</div>
        <div className="text-text-secondary">{t('sku.item.media-content.desc')}</div>
      </div>

      <FieldGroup label={t('sku.item.media-content.upload')}>
        <Controller
          name="image_url"
          control={control}
          rules={{
            // for bundles we don't need to require an image
            ...(type !== ItemType.Bundle && { required: t('validation.required') }),
          }}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
              }}
              bucket={S3Bucket.sku}
            />
          )}
        />
        <ErrorMessage
          name="image_url"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>

      <FieldGroup label={t('sku.item.media-content.upload-featured')}>
        <Controller
          name="image_url_featured"
          control={control}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
              }}
              bucket={S3Bucket.sku}
            />
          )}
        />
        <ErrorMessage
          name="image_url_featured"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>
      <FieldGroup label={t('sku.item.media-content.upload-background')}>
        <Controller
          name="background_image_url"
          control={control}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
              }}
              bucket={S3Bucket.sku}
            />
          )}
        />
        <ErrorMessage
          name="background_image_url"
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      </FieldGroup>
    </FormSection>
  )
}
