import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import { useOffersQuery } from './useOffersQuery'
import {
  Badge,
  Button,
  ModalConfirm,
  Pagination,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  Tooltip,
  useModal,
  usePagination,
} from '@/ui'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '../../../../Settings'
import { useBulkSelect } from '../../../../libs/hooks/useBulkSelect'
import { ItemRead, OfferUserRead } from '../../../../api/dashboard'
import { useCallback, useContext } from 'react'
import { dashboardClient } from '../../../../api'
import { Trash } from '@/icons'
import { canEdit } from '../../../../security'
import SingleValue from '../../../dashboard/components/SingleValue/SingleValue'
import { useOfferReportQuery } from './useOfferReportQuery'
import { KeyValue } from '../../../../types'
import { SkuImageName } from '../../../game-items/components/SkuImageName'
import { cn } from '../../../../libs/cn'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { AuthContext, IAuthContext } from '@/Context'

export default function OfferTable(props: { userId?: string; campaignId?: string; className?: string }) {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const {
    data: offers = [],
    isLoading,
    refetch,
  } = useOffersQuery(companyId, gameId, {
    ...query,
    user_id: props.userId,
    campaign_id: props.campaignId,
  })
  const { data: report, refetch: refetchReport } = useOfferReportQuery(companyId, gameId, {
    user_id: props.userId,
    campaign_id: props.campaignId,
  })
  const { selected, onSelect, onReset } = useBulkSelect()
  const context = useContext(AuthContext) as IAuthContext
  const canUserEdit = canEdit(context.customer)

  const getItemName = (item: OfferUserRead) => {
    return item.item_name['en']
  }

  const availableOffers = offers.filter(it => !it.redeemed_at)

  const openBulkDeleteModal = useModal<{ subMessage: string }>(props => (
    <ModalConfirm
      color={'error'}
      confirmButtonText={i18next.t('remove')}
      message={i18next.t('campaign.offers.remove-n-title')}
      onConfirm={() => onConfirmDeleteItems()}
      {...props}
    />
  ))

  const openDeleteAllModal = useModal<{ subMessage: string }>(props => (
    <ModalConfirm
      color={'error'}
      confirmButtonText={i18next.t('remove')}
      message={i18next.t('campaign.offers.remove-n-title')}
      onConfirm={() => onConfirmDeleteAll()}
      {...props}
    />
  ))

  const openDeleteModal = useModal<{ item: OfferUserRead }>(props => (
    <ModalConfirm
      color={'error'}
      subMessage={i18next.t('confirm.text', { name: getItemName(props.item) })}
      confirmButtonText={i18next.t('remove')}
      onConfirm={() => onDeleteGameItemClick(props.item)}
      {...props}
    />
  ))

  const onClickBulkDelete = useCallback(() => {
    openBulkDeleteModal({ subMessage: i18next.t('campaign.offers.remove-n-items', { N: selected.size }) })
  }, [offers, selected, openBulkDeleteModal])

  const onClickAllDelete = useCallback(() => {
    if (report) {
      openDeleteAllModal({
        subMessage: i18next.t('campaign.offers.remove-active-items', { N: report.all_count - report.purchase_count }),
      })
    }
  }, [offers, selected, openDeleteAllModal])

  const onDeleteGameItemClick = async (item: OfferUserRead) => {
    await dashboardClient.v1.deleteOffer(item.id, companyId, gameId)
    refetch()
    refetchReport()
  }

  const onConfirmDeleteItems = async () => {
    if (props.campaignId) {
      await dashboardClient.v1.bulkDeleteCampaignOffers(
        companyId,
        gameId,
        props.campaignId,
        Array.from(selected).map(id => ({ id })),
      )
    } else if (props.userId) {
      await dashboardClient.v1.bulkDeleteUserOffers(
        companyId,
        gameId,
        props.userId,
        Array.from(selected).map(id => ({ id })),
      )
    }

    onReset([])

    refetch()
    refetchReport()
  }

  const onConfirmDeleteAll = async () => {
    if (props.campaignId) {
      await dashboardClient.v1.deleteAllCampaignOffers(companyId, gameId, props.campaignId)
    } else if (props.userId) {
      await dashboardClient.v1.deleteAllUserOffers(companyId, gameId, props.userId)
    }

    onReset([])

    refetch()
    refetchReport()
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        {offers.length > 0 && canUserEdit && (
          <TableCellBulkCheckbox
            selected={selected.size > 0 && selected.size == availableOffers.length}
            disabled={availableOffers.length === 0}
            onChange={() => onReset(selected.size == availableOffers.length ? [] : availableOffers.map(i => i.id))}
          />
        )}
        <TableCell width="50%">{i18next.t('campaign.offers.item')}</TableCell>
        {!props.userId && <TableCell width="50%">{i18next.t('campaign.offers.player')}</TableCell>}
        <TableCell width="40%">{i18next.t('campaign.offers.expires_at')}</TableCell>
        <TableCell width="25%">{i18next.t('campaign.offers.benefits')}</TableCell>
        <TableCell width="35%">{i18next.t('campaign.offers.created_at')}</TableCell>
        {canUserEdit && <TableCell width="12%" />}
      </TableRow>
    )
  }

  const renderBulkSelectionHeader = () => (
    <TableRow variant="header">
      <TableCellBulkCheckbox
        selected={selected.size > 0 && selected.size == availableOffers.length}
        disabled={availableOffers.length === 0}
        onChange={() => onReset(selected.size == availableOffers.length ? [] : availableOffers.map(i => i.id))}
      />
      <TableCellBulkToolbar selected={selected} onReset={() => onReset([])}>
        <Button variant="tertiary-destructive" onClick={onClickBulkDelete}>
          <Trash />
          <span>{i18next.t('remove')}</span>
        </Button>
        {report && (
          <Button variant="tertiary-destructive" onClick={onClickAllDelete}>
            <Trash />
            <span>{i18next.t('campaign.offers.remove-n-button', { N: report.all_count - report.purchase_count })}</span>
          </Button>
        )}
      </TableCellBulkToolbar>
    </TableRow>
  )

  if (isLoading) {
    return <TableRowSkeleton rowCount={10} columnCount={4} />
  }

  if (!offers.length) {
    return (
      <div className="h-[147px] flex-1">
        <TableZeroState
          title={i18next.t('campaign.offers.zero.title')}
          message={i18next.t(props.userId ? 'player.offers.zero.message' : 'campaign.offers.zero.message')}
        />
        {needShowPagination(isLoading, offers) && (
          <Pagination
            hasNext={offers.length >= query.limit}
            page={page}
            onPageChange={onPageChange}
            pageItems={offers.length}
          />
        )}
      </div>
    )
  }

  const getBenefits = (offer: OfferUserRead) => {
    let data = offer.data as KeyValue
    if (data['discount_percent']) {
      return i18next.t('campaign.offers.discount', { value: data['discount_percent'] })
    }

    if (data['bonus_percent']) {
      return i18next.t('campaign.offers.bonus', { value: data['bonus_percent'] })
    }

    return ''
  }

  return (
    <div className={cn('w-full', props.className)}>
      <div
        className="flex w-full items-center justify-start rounded-xl border border-border-secondary p-5"
        style={{
          boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
        }}
      >
        <SingleValue
          width="20%"
          value={
            report
              ? {
                  value: report.all_count,
                }
              : undefined
          }
          label={i18next.t('campaign.offers.all_count')}
        />
        <div style={{ width: '1px', height: '78px' }} className="mr-5 bg-bg-primary" />
        <SingleValue
          width="20%"
          value={
            report
              ? {
                  value: report.active_count,
                }
              : undefined
          }
          label={i18next.t('campaign.offers.active_count')}
        />
        <div style={{ width: '1px', height: '78px' }} className="mr-5 bg-bg-primary" />
        <SingleValue
          width="20%"
          value={
            report
              ? {
                  value: report.purchase_count,
                }
              : undefined
          }
          label={i18next.t('campaign.offers.count.purchases')}
        />
      </div>

      <Table>
        {selected.size == 0 ? renderDefaultHeader() : renderBulkSelectionHeader()}
        {offers.map((it, idx) => (
          <TableRow key={idx}>
            {canUserEdit && (
              <TableCellBulkCheckbox
                selected={selected.has(it.id)}
                disabled={!!it.redeemed_at}
                onChange={() => onSelect(it.id)}
              />
            )}
            <TableCell className="flex items-center gap-3 truncate" width="50%">
              <SkuImageName
                item={
                  {
                    image_url: it.item_image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM,
                    name: getItemName(it),
                    sku: it.item_sku,
                    is_stackable: it.item_is_stackable,
                    quantity: it.item_quantity,
                  } as unknown as ItemRead
                }
              />
            </TableCell>
            {!props.userId && (
              <TableCell width="50%" className="truncate">
                {it.player_name || it.player_id}
              </TableCell>
            )}
            <TableCell width="40%">
              {it.expires_at ? <DateTimeValue value={it.expires_at} /> : i18next.t('campaign.offers.expires_at.no')}
            </TableCell>
            <TableCell width="25%">{getBenefits(it)}</TableCell>
            <TableCell width="35%">
              <DateTimeValue value={it.created_at} />
            </TableCell>
            <TableCell width="12%">
              <div className="text-right">
                {canUserEdit &&
                  (it.redeemed_at ? (
                    <Tooltip message={<DateTimeValue value={it.redeemed_at} />}>
                      <div>
                        <Badge variant="green-primary" className="whitespace-nowrap">
                          {i18next.t('campaign.offers.used')}
                        </Badge>
                      </div>
                    </Tooltip>
                  ) : (
                    <TableRowEditButton
                      onChange={v => {
                        switch (v) {
                          case 'remove':
                            openDeleteModal({ item: it })
                            break
                        }
                      }}
                      options={[
                        {
                          icon: <Trash className="text-text-error-primary" />,
                          children: <span className="text-text-error-primary"> {i18next.t('grid.remove')} </span>,
                          value: 'remove',
                        },
                      ]}
                    />
                  ))}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {needShowPagination(isLoading, offers) && (
        <Pagination
          hasNext={offers.length >= query.limit}
          page={page}
          onPageChange={onPageChange}
          pageItems={offers.length}
        />
      )}
    </div>
  )
}
